import i18next from 'i18next';

export function validatePassword(value: string) {
  if (value && value.length < 8) {
    return i18next.t('messages:Password must contain minimum 8 characters');
  }
  if (value && !value.match(/[a-z]/)) {
    return i18next.t('messages:Password must contain at least one lowercase letter');
  }
  if (value && !value.match(/[A-Z]/)) {
    return i18next.t('messages:Password must contain at least one uppercase letter');
  }
  if (value && !value.match(/[0-9]/)) {
    return i18next.t('messages:Password must contain at least one number');
  }
  if (value && !value.match(/[@$!%*?&]/)) {
    return i18next.t('messages:Password must contain at least one special character');
  }
  return '';
}
