import { IEntityCreateFunctionProps, IEntityCreateFunctionResult, IEntityGetFunction } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';
import { UserStatus } from '~/utils/constants/roles.constants';
import { IUploadFileFn } from '~/utils/types/shops.types';

export const blockCustomerItem = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.put(
    url,
    { ...data, status: UserStatus.Blocked },
    {
      headers: { authorization: token },
    },
  );

  return {
    data: result.data,
  };
};

export const unBlockCustomerItem = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.put(
    url,
    { ...data, status: UserStatus.Active },
    {
      headers: { authorization: token },
    },
  );

  return {
    data: result.data,
  };
};

export const replenishDeposit = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(url, data, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};

export const uploadFileFn: IUploadFileFn = async ({ url, file, token }) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data', authorization: token },
  });

  return response.data.data;
};

export const deleteItemFn: IEntityGetFunction = async ({ url, token }) => {
  const result = await axios.delete(url, {
    headers: { authorization: token },
  });

  return result.data;
};
