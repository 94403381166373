import React, { useEffect } from 'react';
import { ENTITY_ERRORS, IEntityField, Page } from 'icerockdev-admin-toolkit';
import { action, computed, observable, toJS } from 'mobx';
import { Provider, observer } from 'mobx-react';
import { ProfileViewer } from '../ProfileViewer';
import { PROFILE_FIELDS } from '../../fields';
import { getUserProfile, updateUserProfile } from '../../api';
import { API_ROUTES } from '~/utils/constants/api.constants';
import i18n from 'i18next';
import { ProfileHead } from '../ProfileHead';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { UserRole } from '~/utils/constants/roles.constants';

class ProfileEntity extends Page {
  fields = PROFILE_FIELDS;
  @observable isProfileLoading: boolean = false;
  @observable profileData: Record<string, any> = {};
  @observable isEditProfile: boolean = false;

  @observable error?: string | null;
  @observable editorFieldErrors: Record<string, string> = {};

  @observable isDetailProfileLoading: boolean = false;
  @observable detailProfileData: Record<string, any> = {};

  @action
  onUnmount = () => {
    this.isEditProfile = false;
  };

  @action
  onEditProfile = () => {
    this.isEditProfile = !this.isEditProfile;
    this.editorFieldErrors = {};
    this.fetchProfile();
  };

  @action
  setEditorData = (data: Record<string, any>) => {
    this.profileData = data;
  };

  @action
  resetFieldError = (field: string) => {
    delete this.editorFieldErrors[field];
  };

  isValidField = (field: IEntityField, value: any) =>
    (!field.required || field.type === 'boolean' || value === 0 || !!value) && !field.validator;

  @action
  validateSubmitFields = (data: Record<string, any>, isCreating = false): boolean => {
    this.editorFieldErrors = this.fields
      .filter(field => (isCreating && !field.hideInCreate) || (!isCreating && !field.hideInEdit))
      .reduce(
        (obj, field) =>
          this.isValidField(field, data[field.name])
            ? obj
            : {
                ...obj,
                [field.name]: field.validator || i18n.t(ENTITY_ERRORS.FIELD_IS_REQUIRED),
              },
        {},
      );

    return Object.keys(this.editorFieldErrors).length === 0;
  };

  @action
  updateItem = async () => {
    if (!this.parent?.auth?.withToken) return;

    this.isProfileLoading = true;
    this.error = '';

    try {
      const data = toJS(this.profileData);

      if (!this.validateSubmitFields(data, false)) {
        throw new Error(i18n.t(ENTITY_ERRORS.INCORRECT_INPUT));
      }

      const result = await this.parent.auth.withToken(updateUserProfile, {
        url: API_ROUTES.users,
        id: this.parent?.auth?.user.id,
        data: {
          userData: {
            firstName: this.profileData.firstName,
            lastName: this.profileData.lastName,
            phone: this.profileData.phone,
            email: this.profileData.email,
            country: this.profileData.country,
            city: this.profileData.city,
            seiFurigana: this.profileData.seiFurigana,
            meiFurigana: this.profileData.meiFurigana,
            prefectures: this.profileData.prefectures,
            municipalities: this.profileData.municipalities,
            streetAddress: this.profileData.streetAddress,
            buildingName: this.profileData.buildingName,
            companyName: this.profileData.companyName,
            postCode: this.profileData.postCode,
          },
          bankDetails: {
            accountName: this.profileData.accountName,
            accountNumber: this.profileData.accountNumber,
            bankCode: this.profileData.bankCode,
            bankName: this.profileData.bankName,
            branchCode: this.profileData.branchCode,
            branchName: this.profileData.branchName,
          },
        },
      });

      this.profileData = result?.data;
      this.isProfileLoading = false;
      this.isEditProfile = false;
    } catch (e) {
      const err = e as Error;

      this.parent?.notifications.showError(err?.message);
      this.isProfileLoading = false;
    }
  };

  @action
  fetchDetailProfile = async (id: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isDetailProfileLoading = true;

    const result = await this.parent.auth.withToken(getUserProfile, {
      url: API_ROUTES.users,
      id,
    });

    this.detailProfileData = result?.data;
    this.isDetailProfileLoading = false;
  };

  @action
  fetchProfile = async () => {
    if (!this.parent?.auth?.withToken) return;

    this.isProfileLoading = true;

    const result = await this.parent.auth.withToken(getUserProfile, {
      url: API_ROUTES.users,
      id: this.parent?.auth?.user.id,
    });

    this.profileData = result?.data;
    this.isProfileLoading = false;
  };

  @computed
  get Profile() {
    return observer(({ id }: { id?: number }) => {
      useEffect(() => {
        if (id !== undefined) {
          this.fetchDetailProfile(id);
        } else {
          this.fetchProfile();
        }
      }, [id]);

      const isSelf = id === undefined;
      const isEditing = isSelf && this.isEditProfile;
      const isLoading = isSelf ? this.isProfileLoading : this.isDetailProfileLoading;
      const data = isSelf ? this.profileData : this.detailProfileData;

      return (
        <>
          <ProfileHead
            title={this.title}
            onEdit={this.onEditProfile}
            onSave={this.updateItem}
            isLoading={isLoading}
            isEditProfile={isEditing}
            isSelf={isSelf}
          />
          <ProfileViewer
            fields={this.fields}
            url={this.menu.url}
            errors={this.editorFieldErrors}
            onResetFieldError={this.resetFieldError}
            isEditing={isEditing}
            isLoading={isLoading}
            setEditorData={this.setEditorData}
            data={data}
            withToken={this.parent?.auth?.withToken}
          />
        </>
      );
    });
  }

  @computed
  get output() {
    return observer(() => (
      <Provider entity={this}>
        <Switch>
          <Route
            path={`${this.menu.url}/:id/`}
            component={({
              match: {
                params: { id },
              },
            }: RouteComponentProps<{ id: string }>) => <this.Profile id={Number(id)} />}
          />
          {this.parent?.auth?.userRole === UserRole.Business && (
            <Route path={this.menu.url} component={() => <this.Profile />} />
          )}
        </Switch>
      </Provider>
    ));
  }
}

export { ProfileEntity };
