import { createItemsFn, updateItemsFn } from '~/utils/api';
import { SHOP_FIELDS } from './fields';
import { ShopRole, UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';
import { ShopEntity } from '~/pages/shops/components/Entity';
import { getItemsFn } from '~/pages/shops/api';

export default new ShopEntity({
  title: 'Shops',
  viewable: true,
  editable: true,
  creatable: true,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.shop, method: 'get' },
    get: { url: API_ROUTES.shop, method: 'get' },
    getProduct: { url: API_ROUTES.product, method: 'get' },
    getCommitment: { url: API_ROUTES.commitment, method: 'get' },
    create: { url: API_ROUTES.users, method: 'post' },
    update: { url: API_ROUTES.users, method: 'put' },
    uploadFile: { url: API_ROUTES.uploadFile, method: 'post' },
  },
  menu: {
    label: 'Shops',
    url: '/shops',
    enabled: true,
  },
  fields: SHOP_FIELDS,
  getItemsFn,
  updateItemsFn,
  createItemsFn,
  roles: [
    UserRole.Admin,
    UserRole.SuperAdmin,
    ShopRole.Site_Planner_A,
    ShopRole.Site_Planner_B,
    ShopRole.Business_Owner,
    ShopRole.Sales_Planner,
    ShopRole.Affiliate_Planner,
  ],
  permissions: {
    list: [
      UserRole.Admin,
      UserRole.SuperAdmin,
      ShopRole.Site_Planner_A,
      ShopRole.Site_Planner_B,
      ShopRole.Business_Owner,
      ShopRole.Sales_Planner,
      ShopRole.Affiliate_Planner,
    ],
    create: [UserRole.Admin, UserRole.SuperAdmin],
    update: [
      UserRole.Admin,
      UserRole.SuperAdmin,
      ShopRole.Site_Planner_A,
      ShopRole.Site_Planner_B,
      ShopRole.Business_Owner,
    ],
    view: [
      UserRole.Admin,
      UserRole.SuperAdmin,
      ShopRole.Site_Planner_A,
      ShopRole.Site_Planner_B,
      ShopRole.Business_Owner,
      ShopRole.Sales_Planner,
      ShopRole.Affiliate_Planner,
    ],
  },
});
