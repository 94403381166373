export enum BankTransferStatus {
  New = 'NEW',
  Done = 'DONE',
  Cancelled = 'CANCELLED',
}

export const BANK_TRANSFERS_STATUS_NAME = {
  [BankTransferStatus.New]: 'New',
  [BankTransferStatus.Done]: 'Done',
  [BankTransferStatus.Cancelled]: 'Cancelled',
};

export const BANK_TRANSFERS_STATUS_NAME_COLORS = {
  [BankTransferStatus.New]: '#DC9B2D',
  [BankTransferStatus.Done]: '#1E6AB2',
  [BankTransferStatus.Cancelled]: '#B8481F',
};
