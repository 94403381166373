import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '~/components/icons/CloseIcon';
import { TransferRequestEntity } from '../TransferRequestEntity';
import { TransferRequestStatus } from '~/utils/constants/requests.constants';

interface IProps {
  isLoading: boolean;
  entity: TransferRequestEntity;
  canView: boolean;
}

const HeadButtons: FC<IProps> = observer(({ entity, isLoading, canView }) => {
  const { t } = useTranslation();
  const selectedRequests = entity.selected;

  const [rejectionReasonModal, setRejectionReasonModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const onChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleModal = (): void => {
    setRejectionReasonModal(prevState => !prevState);
  };

  const handleApprove = useCallback(() => {
    if (!window.confirm(t('messages:Do you really want the transfer request to be approved?'))) return;
    entity.updateRequest(selectedRequests, TransferRequestStatus.ApprovedByAdmin);
  }, [entity, selectedRequests, t]);

  const handleRejectionReason = useCallback(() => {
    if (!comment) return;
    entity.updateRequest(selectedRequests, TransferRequestStatus.Rejected, comment);
    setComment('');
    setRejectionReasonModal(false);
  }, [comment, entity, selectedRequests]);

  if (!canView) {
    return null;
  }

  return (
    <>
      <div className={styles.wrap}>
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          disableElevation
          onClick={handleApprove}
          disabled={isLoading || !selectedRequests.length}
        >
          {t('buttons:Approve')}
        </Button>
        <Button
          variant="outlined"
          color="default"
          className={styles.button}
          onClick={handleModal}
          disabled={isLoading || !selectedRequests.length}
        >
          {t('buttons:Reject')}
        </Button>
      </div>

      <Dialog open={rejectionReasonModal} onClose={handleModal} aria-labelledby="form-dialog-title">
        <div className={styles.modal__wrap}>
          <div className={styles.modal__head}>
            <h2>{t('common:Rejection reason')}</h2>
            <Button onClick={handleModal} className={styles.button__close}>
              <CloseIcon />
            </Button>
          </div>
          <TextField
            label={t('common:Comment')}
            variant="outlined"
            value={comment}
            onChange={onChangeComment}
            rows={3}
            multiline
            autoFocus
            fullWidth
          />
          <div className={styles.modal__foot}>
            <Button onClick={handleRejectionReason} variant="contained" color="primary">
              {t('buttons:Send')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
});

export { HeadButtons };
