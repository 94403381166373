import React, { useState, FC, memo } from 'react';
import styles from './styles.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { Icon } from '@material-ui/core';
import UploadSvg from '~/components/icons/UploadSvg';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

interface IProps {
  id: string;
  error?: string;
  onUploadImage: any;
  onSaveBase64: (id: string, image: string) => void;
  deleteImage: (id: string) => void;
  imageData: string;
  imagePxSize: number;
  location: string;
  isViewPage?: boolean;
}

const ImageUploader: FC<IProps> = observer(
  ({ onSaveBase64, onUploadImage, deleteImage, id, imageData, imagePxSize, location, isViewPage, error }) => {
    const { t } = useTranslation();
    const [showDeleteButton, setShowDeleteButton] = useState(false);

    const handleMouseEnter = () => {
      setShowDeleteButton(true);
    };

    const handleMouseLeave = () => {
      setShowDeleteButton(false);
    };

    const handleDeleteClick = () => {
      deleteImage(id);
    };

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      await onUploadImage(id, file);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        onSaveBase64(id, reader.result as string);

        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = imagePxSize;
          canvas.height = imagePxSize;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, imagePxSize, imagePxSize);
        };
      };
    };

    return (
      <div
        className={styles.imageUploader}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ minWidth: `${imagePxSize}px`, height: `${imagePxSize}px` }}
      >
        {!imageData && (
          <>
            <label htmlFor={location}>
              <UploadSvg />
            </label>
            <input id={location} disabled={isViewPage} type="file" accept="image/*" onChange={handleImageUpload} />
            {error && <span className={styles.error}>{t(error)}</span>}
          </>
        )}
        {imageData && (
          <div style={{ width: imagePxSize, height: imagePxSize, position: 'relative', background: 'white' }}>
            <img draggable="false" className={styles.image} src={imageData} alt="Preview" />
          </div>
        )}
        {showDeleteButton && imageData && !isViewPage && (
          <div className={styles.deleteButton} onClick={handleDeleteClick}>
            <Icon color="primary" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <DeleteIcon />
            </Icon>
          </div>
        )}
      </div>
    );
  },
);

export default memo(ImageUploader);
