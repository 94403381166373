import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { TransactionStatus } from '~/utils/constants/transactions.constants';
import localizeAmount from '~/utils/localization/amount';

type IProps = IEntityFieldProps;

const TransactionAmount: FC<IProps> = observer(({ data, value }) => {
  const type = data?.type;
  const typePoints =
    type === TransactionStatus.Deposit
      ? data?.toWallet?.currency
      : type === TransactionStatus.Transfer
      ? 'CP'
      : type === TransactionStatus.Exchange
      ? 'SP'
      : type === TransactionStatus.Withdraw
      ? data?.fromWallet?.currency
      : '';

  return <>{`${localizeAmount(value)} ${typePoints}`}</>;
});

export { TransactionAmount };
