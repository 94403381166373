import React, { FC } from 'react';
import styles from './styles.module.scss';
import { IReportData } from '~/utils/types/reports.types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

interface IProps {
  data: IReportData;
}

const SelectedReportBlock: FC<IProps> = observer(({ data }) => {
  const { t } = useTranslation();
  const { countOfTransactions, amountOfCP } = data;

  return (
    <div className={styles.searchContainer}>
      <div className={styles.row}>
        <p className={styles.description}>{t('fields:Transactions')}:</p>
        <p className={styles.value}>{countOfTransactions}</p>
      </div>
      <div className={styles.row}>
        <p className={styles.description}>{t('fields:Earned concierge points')}:</p>
        <p className={styles.value}>{amountOfCP}</p>
      </div>
    </div>
  );
});

export default SelectedReportBlock;
