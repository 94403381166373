import { createItemsFn, fetchItemsFn, getItemsFn, updateItemsFn } from '~/utils/api';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';
import { CATEGORY_FIELDS } from '~/pages/category/fields';
import { CategoryEntity } from '~/pages/category/components/Entity';

export default new CategoryEntity({
  title: 'Category',
  viewable: true,
  editable: true,
  creatable: true,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.category, method: 'get' },
    get: { url: API_ROUTES.category, method: 'get' },
    create: { url: API_ROUTES.category, method: 'post' },
    update: { url: API_ROUTES.category, method: 'put' },
    delete: { url: API_ROUTES.category, method: 'delete' },
  },
  menu: {
    label: 'Category',
    url: '/category',
    enabled: true,
  },
  fields: CATEGORY_FIELDS,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
  createItemsFn,
  roles: [UserRole.Admin, UserRole.SuperAdmin],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin],
  },
});
