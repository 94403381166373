import React, { useEffect } from 'react';
import { Page } from 'icerockdev-admin-toolkit';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ConfigurationViewer } from '../ConfigurationViewer';
import { getConfiguration, putConfiguration, updateConfigurationById } from '../../api';
import { API_ROUTES } from '~/utils/constants/api.constants';
import { ConfigurationHead } from '../ConfigurationHead';

import {
  ConfigurationFieldKey,
  ConfigurationFieldNameKey,
  ConfigurationGroupKey,
} from '~/utils/constants/configuration.constants';

export interface ConfigurationField {
  id: ConfigurationFieldKey;
  name: ConfigurationFieldNameKey;
  value: string;
}

export interface ConfigurationGroup {
  category: ConfigurationGroupKey;
  settings: ConfigurationField[];
}

class ConfigurationEntity extends Page {
  @observable configurationData: ConfigurationGroup[] = [];
  @observable isLoading: boolean = false;
  @observable isEditing: boolean = false;

  @observable creditCardSwitcher: boolean = false;
  @observable btcPaySwitcher: boolean = false;
  @observable bankTransferSwitcher: boolean = false;
  @observable isSwitcherLoading: boolean = false;

  @action
  onUnmount = () => {
    this.isEditing = false;
  };

  @action
  onEditConfiguration = () => {
    this.isEditing = !this.isEditing;
    this.fetchConfiguration();
  };

  @action
  setEditorData = (data: ConfigurationGroup[]) => {
    this.configurationData = data;
  };

  @action
  fetchConfiguration = async () => {
    if (!this.parent?.auth?.withToken) return;

    try {
      this.isLoading = true;

      const result = await this.parent.auth.withToken(getConfiguration, {
        url: API_ROUTES.configurationByCategory,
        id: this.parent?.auth?.user.id,
      });

      const data: ConfigurationGroup[] = result?.data;

      const getSwitcherValue = (groupkey: ConfigurationGroupKey, fieldKey: ConfigurationFieldKey) => {
        const switcherValue = data
          .find(item => item.category === groupkey)
          ?.settings.find(field => field.id === fieldKey)?.value;
        return switcherValue === 'true' ? true : false;
      };

      this.configurationData = result?.data;

      this.creditCardSwitcher = getSwitcherValue(
        ConfigurationGroupKey.CREDIT_CARD,
        ConfigurationFieldKey.CREDIT_CARD_SWITCHER,
      );
      this.btcPaySwitcher = getSwitcherValue(ConfigurationGroupKey.BTC_PAY, ConfigurationFieldKey.BTC_PAY_SWITCHER);
      this.bankTransferSwitcher = getSwitcherValue(
        ConfigurationGroupKey.BANK_TRANSFER,
        ConfigurationFieldKey.BANK_TRANSFER_SWITCHER,
      );

      this.isLoading = false;
    } catch (e) {
      const err = e as Error;

      this.parent?.notifications.showError(err?.message);
      this.isLoading = false;
    }
  };

  @action
  updateConfiguration = async () => {
    if (!this.parent?.auth?.withToken) return;

    try {
      this.isLoading = true;

      const result = await this.parent.auth.withToken(putConfiguration, {
        url: API_ROUTES.configuration,
        id: this.parent?.auth?.user.id,
        data: { updateSettings: this.configurationData },
      });

      this.configurationData = result?.data;
      this.isLoading = false;
      this.isEditing = false;
    } catch (e) {
      const err = e as Error;

      this.parent?.notifications.showError(err?.message);
      this.isLoading = false;
    }
  };

  @action
  updateSwitcherItem = async (id: string, value: string) => {
    if (!this.parent?.auth?.withToken) return;

    try {
      this.isSwitcherLoading = true;

      const result = await this.parent.auth.withToken(updateConfigurationById, {
        url: API_ROUTES.configuration,
        id,
        data: {
          value: value,
        },
      });

      switch (id) {
        case ConfigurationFieldKey.CREDIT_CARD_SWITCHER: {
          this.creditCardSwitcher = result?.data?.value === 'true' ? true : false;
          break;
        }
        case ConfigurationFieldKey.BTC_PAY_SWITCHER: {
          this.btcPaySwitcher = result?.data?.value === 'true' ? true : false;
          break;
        }
        case ConfigurationFieldKey.BANK_TRANSFER_SWITCHER: {
          this.bankTransferSwitcher = result?.data?.value === 'true' ? true : false;
          break;
        }
      }

      this.isSwitcherLoading = false;
    } catch (e) {
      const err = e as Error;

      this.parent?.notifications.showError(err?.message);
      this.isSwitcherLoading = false;
    }
  };

  @computed
  get Configuration() {
    return observer(() => {
      useEffect(() => {
        this.fetchConfiguration();
      }, []);

      return (
        <>
          <ConfigurationHead
            title={this.title}
            onEdit={this.onEditConfiguration}
            onSave={this.updateConfiguration}
            isLoading={this.isLoading}
            isSwitcherLoading={this.isSwitcherLoading}
            isEditing={this.isEditing}
          />
          <ConfigurationViewer
            url={this.menu.url}
            isEditing={this.isEditing}
            isLoading={this.isLoading}
            setEditorData={this.setEditorData}
            data={this.configurationData}
            withToken={this.parent?.auth?.withToken}
            onUpdateSwitcher={this.updateSwitcherItem}
            creditCardSwitcher={this.creditCardSwitcher}
            btcPaySwitcher={this.btcPaySwitcher}
            bankTransferSwitcher={this.bankTransferSwitcher}
            isSwitcherLoading={this.isSwitcherLoading}
          />
        </>
      );
    });
  }

  @computed
  get output() {
    return observer(() => <this.Configuration />);
  }
}

export { ConfigurationEntity };
