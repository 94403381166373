import * as React from 'react';

interface PropTypes {
  size?: number;
}

const Component: React.FC<PropTypes> = ({ size = 20, ...props }: PropTypes) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.428 7.809h-.269c-.743-2.676-3.27-4.578-6.162-4.578-2.875 0-5.4 1.902-6.154 4.579h-.271C2.705 7.81 2 8.494 2 9.336v1.716c0 .842.705 1.527 1.572 1.527h.195c.252 1.924 1.947 3.415 3.993 3.415h.376c.199.455.663.774 1.202.774h1.324c.72 0 1.306-.57 1.306-1.27 0-.7-.586-1.269-1.306-1.269H9.338c-.54 0-1.003.32-1.202.774H7.76c-1.656 0-3.004-1.31-3.004-2.92V8.36c.528-2.4 2.724-4.138 5.241-4.138 2.534 0 4.733 1.737 5.247 4.136v3.724c0 .274.229.496.51.496h.674c.867 0 1.572-.685 1.572-1.527V9.336c0-.842-.705-1.527-1.572-1.527zm-7.09 7.413h1.324c.157 0 .285.125.285.278a.282.282 0 01-.285.277H9.338a.282.282 0 01-.285-.277c0-.153.128-.278.285-.278zm-6.317-4.17V9.338c0-.295.247-.536.55-.536h.164v2.787h-.163a.544.544 0 01-.551-.535zm13.958 0a.544.544 0 01-.55.535h-.164V8.801h.163c.304 0 .551.24.551.535v1.716z"
      fill="#1F1B16"
    />
  </svg>
);

export default Component;
