import { axios } from '~/utils/axios';
import { IEntityGetFunction } from 'icerockdev-admin-toolkit';

export const deleteItemFn: IEntityGetFunction = async ({ url, token }) => {
  const result = await axios.delete(`${url}`, {
    headers: { authorization: token },
  });

  return result.data;
};
