import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { computed, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { CustomEntityHead } from '~/components/entity/CustomEntityHead';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import { ListHeadButtons } from '../ListHeadButtons';
import { fetchItemsFn } from '~/utils/api';
import { getErrorMessage } from '~/utils/axios';

class TransactionsEntity extends Entity {
  @observable isLoading: boolean = false;
  @observable data: Record<string, string>[] = [];

  @action
  onSearchTransactions = async (query?: string) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    try {
      const result = await this.parent.auth.withToken(fetchItemsFn, {
        url: this.api?.list.url,
        filter: [{ name: 'query', value: query }],
      });

      this.data = result?.data?.list;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isLoading = false;
  };

  @computed
  get ListHeadButtons() {
    return observer(() => {
      return <ListHeadButtons entity={this} data={this.data} isLoading={this.isLoading} />;
    });
  }

  @computed
  get ListHead() {
    return observer(() => (
      <CustomEntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListBody() {
    return observer(() => {
      return (
        <CustomEntityList
          fields={this.fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable && this.canView}
          canEdit={this.editable && this.canEdit}
          canSelect={this.selectable}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }
}

export { TransactionsEntity };
