import { axios } from '~/utils/axios';
import { API_ROUTES } from '~/utils/constants/api.constants';
import { UserRole } from '~/utils/constants/roles.constants';
import i18n from 'i18next';
import { IJWTAuthRequestFn, IJWTTokenRefreshFn, UNAUTHORIZED } from 'icerockdev-admin-toolkit';

export const authRequestFn: IJWTAuthRequestFn = async (
  login: string,
  password: string,
): Promise<{
  user: {
    id?: number | undefined;
    email?: string | undefined;
    username?: string | undefined;
    role?: string | undefined;
    token?: string | undefined;
  };
  tokens: {
    access: string;
    refresh: string;
  };
  error: string;
}> => {
  const response = await axios.post(API_ROUTES.auth.signin, { login: login.trim(), password });
  const jwt = response.data.data.accessToken;
  const [, payload] = jwt.split('.');
  const decodedToken = JSON.parse(atob(payload));
  const userData = JSON.parse(decodedToken.user);
  const acceptRoles = [UserRole.SuperAdmin, UserRole.Admin, UserRole.Concierge, UserRole.Business];

  return {
    user: {
      id: userData.userId,
      username: login,
      role: decodedToken.role,
    },
    tokens: {
      access: response.data.data.accessToken,
      refresh: response.data.data.refreshToken,
    },
    error: acceptRoles.includes(decodedToken.role) ? '' : i18n.t('messages:Access denied'),
  };
};

export const tokenRefreshFn: IJWTTokenRefreshFn = async refreshToken => {
  try {
    const result = await axios.post(API_ROUTES.auth.refresh, { refreshToken });

    if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
      throw new Error(UNAUTHORIZED);
    }

    return {
      access: result.data.data.accessToken,
      refresh: result.data.data.refreshToken,
    };
  } catch (e) {
    return {
      access: '',
      refresh: '',
    };
  }
};
