import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { format, isValid, parseISO } from 'date-fns';

type FormattedJsonDateProps = {
  value: string;
  withTime?: boolean;
};

type DateObject = {
  date: string;
  isNotFixed: boolean;
};

const FormattedJsonDate: FC<FormattedJsonDateProps> = ({ value, withTime }: FormattedJsonDateProps) => {
  const { t } = useTranslation();
  let dateObject: DateObject | undefined;

  try {
    dateObject = JSON.parse(value);
  } catch (_) {
    console.error('Failed to parse JSON date object');
  }

  if (!dateObject) return null;

  const parsedDate = parseISO(dateObject.date);
  const isValidDate = isValid(parsedDate);
  const date = isValidDate ? format(parsedDate, withTime ? 'yyyy.MM.dd HH:mm' : 'yyyy.MM.dd') : value;
  const message = dateObject.isNotFixed ? `(${t('fields:Date is not fixed yet')})` : null;

  return (
    <div>
      {date} {message}
    </div>
  );
};
export { FormattedJsonDate };
