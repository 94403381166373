import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import Download from '@material-ui/icons/CloudDownloadOutlined';
import { QrCodesEntity } from '../QrCodesEntity';
import { useTranslation } from 'react-i18next';

interface IProps extends IEntityFieldProps {
  entity: QrCodesEntity;
}

const QrDownloadButton: FC<IProps> = ({ data }) => {
  const entity = useEntity<QrCodesEntity>();
  const { t } = useTranslation();

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      if (!data) return;
      entity.downloadItem(data.id, data.name);
    },
    [data, entity],
  );

  return (
    <Button onClick={onClick}>
      <div className={styles.body__wrap}>
        <Download style={{ color: '#DC9B2D' }} />
        <span>{t('buttons:Download')}</span>
      </div>
    </Button>
  );
};

export { QrDownloadButton };
