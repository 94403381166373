import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

interface IProps {
  children?: ReactNode;
  headers: { width: string; title: string; id: number }[];
}

const Table: FC<IProps> = ({ children, headers }) => {
  const { t } = useTranslation();
  return (
    <table className={styles.table}>
      <thead className={styles.tHead}>
        <tr className={styles.trBlock}>
          {headers.map(header => {
            return (
              <th key={header.id} style={{ width: header.width }}>
                {t(header.title)}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export { Table };
