import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { action, computed, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { CustomEntityHead } from '~/components/entity/CustomEntityHead';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import { WithdrawRequestStatus } from '~/utils/constants/requests.constants';
import { deleteQrCode, downloadQrCode } from '../../api';
import { saveAs } from 'file-saver';
import { UserRole } from '~/utils/constants/roles.constants';

class QrCodesEntity extends Entity {
  deletable = true;
  rolesForVisibleBusinessIdField: UserRole[] = [UserRole.SuperAdmin, UserRole.Admin];

  @action
  onMount = () => {
    this.getFiltersFromHash();
    reaction(() => [this.filters, this.sortBy, this.sortDir, this.page, this.items], this.setFiltersWindowHash);
    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilter);
    reaction(() => this.page, this.fetchItems);
    this.fetchItems();

    if (this.rolesForVisibleBusinessIdField.includes(this.parent?.auth?.user.role as UserRole)) {
      this.fields = this.fields.map(field => {
        if (field.label === 'Business Id') {
          field.hideInCreate = false;
          field.hideInList = false;
          field.hideInEdit = false;
          field.hideInView = false;
        }
        return field;
      });
    }
  };

  @action
  deleteItem = async (id: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    await this.parent.auth.withToken(deleteQrCode, { url: `${this?.api?.delete.url}/${id}` });
    this.fetchItems();

    this.isLoading = false;
  };

  @action
  downloadItem = async (id: number, name: string) => {
    if (!this.parent?.auth?.withToken) return;

    const result = await this.parent.auth.withToken(downloadQrCode, { url: `${this?.api?.create.url}/${id}` });

    const blob = new Blob([result.data], { type: 'image/png' });
    saveAs(blob, `${name}.png`);
  };

  @computed
  get ListHead() {
    return observer(() => (
      <CustomEntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListBody() {
    return observer(() => {
      return (
        <CustomEntityList
          fields={this.fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          disabledIfFieldName={'status'}
          disabledIfKeysName={[WithdrawRequestStatus.Approved, WithdrawRequestStatus.Declined]}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable && this.canView}
          canEdit={this.editable && this.canEdit}
          canDelete={this.deletable}
          canSelect={this.selectable}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          onDelete={this.deleteItem}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }
}

export { QrCodesEntity };
