import { IEntityCreateFunctionResult } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const updateReviewFn = async ({
  url,
  token,
  value,
}: {
  url: string;
  token: string;
  value: boolean;
}): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.put(
    `${url}`,
    {
      approved: value,
    },
    {
      headers: { authorization: token },
    },
  );

  return result.data;
};
