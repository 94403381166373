import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { CATEGORY_ICONS_BY_ID } from '../../constants';
import styles from './styles.module.scss';

type IProps = IEntityFieldProps;

const Category: FC<IProps> = observer(({ label, value, handler, isEditing, error }) => {
  const icon = CATEGORY_ICONS_BY_ID[value?.id - 1]?.icon;
  const color = CATEGORY_ICONS_BY_ID[value?.id - 1]?.color;

  return (
    <div className={styles.wrap}>
      <div className={styles.badge} style={{ backgroundColor: color }}>
        {icon}
        {value?.name}
      </div>
    </div>
  );
});

export { Category };
