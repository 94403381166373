import React, { FC, ReactNode, useCallback } from 'react';
import { createPortal } from 'react-dom';
import styles from './styles.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

interface IProps {
  onCloseButtonClickFn: () => void;
  children: ReactNode;
}

const Modal: FC<IProps> = ({ children, onCloseButtonClickFn }) => {
  const modalRoot = document.getElementById('modal-root') as Element;

  const handleCloseClick = useCallback(
    (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
      e.stopPropagation();
      onCloseButtonClickFn();
    },
    [onCloseButtonClickFn],
  );

  const handleContentClick = useCallback((e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  return createPortal(
    <div className={styles.modalOverlay} onClick={handleCloseClick}>
      <div className={styles.modal} onClick={handleContentClick}>
        <div className={styles.modalContent} onClick={handleContentClick}>
          <IconButton
            className={styles.closeButton}
            onClick={handleCloseClick}
            style={{ width: '30px', height: '30px', color: '#1F1B16' }}
          >
            <CloseIcon />
          </IconButton>
          {children}
        </div>
      </div>
    </div>,
    modalRoot,
  );
};

export default Modal;
