import React from 'react';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import SpaIcon from '@material-ui/icons/Spa';
import StyleIcon from '@material-ui/icons/Style';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import HotelIcon from '@material-ui/icons/Hotel';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DomainIcon from '@material-ui/icons/Domain';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

export const CATEGORY_ICONS_BY_ID = [
  { icon: <RestaurantIcon style={{ color: '#b45309' }} />, color: '#fef3c7' }, // Booking / Search restaurant
  { icon: <BeachAccessIcon style={{ color: '#9a3412' }} />, color: '#fed7aa' }, // Search goods
  { icon: <RoomServiceIcon style={{ color: '#6d28d9' }} />, color: '#ede9fe' }, // Shopping Concierge
  { icon: <SpaIcon style={{ color: '#be185d' }} />, color: '#fce7f3' }, // Search and book Salon
  { icon: <StyleIcon style={{ color: '#9d174d' }} />, color: '#fbcfe8' }, // Search product
  { icon: <LocalFloristIcon style={{ color: '#831843' }} />, color: '#f9a8d4' }, // Beauty consultation
  { icon: <FlightTakeoffIcon style={{ color: '#1d4ed8' }} />, color: '#dbeafe' }, // Request
  { icon: <HotelIcon style={{ color: '#1e40af' }} />, color: '#bfdbfe' }, // Booking
  { icon: <LocalParkingIcon style={{ color: '#047857' }} />, color: '#ecfdf5' }, // Looking for parking
  { icon: <LocalHospitalIcon style={{ color: '#065f46' }} />, color: '#d1fae5' }, // Health counseling
  { icon: <AttachMoneyIcon style={{ color: '#064e3b' }} />, color: '#a7f3d0' }, // Sale
  { icon: <DomainIcon style={{ color: '#4d7c0f' }} />, color: '#f7fee7' }, // Real Estate
  { icon: <EmojiTransportationIcon style={{ color: '#3f6212' }} />, color: '#ecfccb' }, // Car-related
  { icon: <VerifiedUserIcon style={{ color: '#365314' }} />, color: '#d9f99d' }, // Insurance
  { icon: <ListAltIcon style={{ color: '#3f3f46' }} />, color: '#f4f4f5' }, // Please, input detailes of a request (Other)
];
