import {
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { getCustomerItemData } from '~/utils/api';
import { axios } from '~/utils/axios';
import { API_ROUTES } from '~/utils/constants/api.constants';
import { TypePoints } from '~/utils/constants/transactions.constants';
import localizeAmount from '~/utils/localization/amount';
import { IUploadFileFn } from '~/utils/types/shops.types';

export const fetchRequests: IEntityFetchFunction = async ({ url, token, page = 0, count, sortDir, sortBy, filter }) => {
  const offset = page && count ? page * count : 0;
  const orderBy = sortDir.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value?.trim() }), {}) || {};

  const response = await axios.get(url, {
    params: {
      offset,
      limit: count,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
    },
    headers: { authorization: token },
  });

  const list = response?.data?.data || [];
  const modifiedList = list.map(item => ({ ...item, clientId: item.author.clientId }));

  return {
    data: {
      list: modifiedList,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};

export const getItems: IEntityFetchFunction = async ({ url, token }) => {
  const response = await axios.get(url, {
    headers: { authorization: token },
  });

  return {
    data: response?.data?.data,
  };
};

export const getRequest: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getCustomerItemData({ url, id, token });
  const data = result?.data?.data;

  const profileData = await axios.get(`${API_ROUTES.users}/${data.author.id}/profile`, {
    headers: { authorization: token },
  });

  const walletsData = profileData?.data?.data.wallets;
  const wallets = Object.keys(TypePoints).map(point => {
    const wallet = walletsData.find(wallet => point === wallet.currency);
    if (!wallet) return { amount: 0, currency: point };
    return wallet;
  });
  const userWallet = wallets.map(item => `${localizeAmount(item.amount)} ${item.currency}`).join(', ');

  const modifiedData = { ...data, clientId: data.author.clientId, userWallet };

  return {
    data: modifiedData || {},
    error: '',
  };
};

export const updateRequest = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(`${url}`, data, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};

export const uploadFile: IUploadFileFn = async ({ url, file, token }) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data', authorization: token },
  });

  return response.data;
};
