import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { USER_ROLE_NAMES } from '~/utils/constants/roles.constants';
import { FormattedDate } from '~/components/common/FormattedDate';
import { FormattedNumber } from '~/components/common/FormattedNumber';

export const EXCHANGE_REQUEST_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'createdAt',
    label: 'Created date',
    sortable: true,
    hideInCreate: true,
    component: props => <FormattedDate {...props} withTime />,
  },
  {
    type: 'string',
    name: 'userName',
    label: 'User',
    hideInCreate: true,
  },
  {
    type: 'select',
    name: 'role',
    label: 'Role',
    options: USER_ROLE_NAMES,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'clientId',
    label: 'Member ID',
    filterable: true,
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: 'amount',
    label: 'Convert amount SP',
    component: FormattedNumber,
  },
];
