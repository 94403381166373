import { createItemsFn, fetchItemsFn, getItemsFn, updateItemsFn } from '~/utils/api';
import { USER_FIELDS } from './fields';
import { UserEntity } from '~/pages/users/components/UserEntity';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';

export default new UserEntity({
  title: 'Users',
  viewable: true,
  editable: true,
  creatable: true,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.users, method: 'get' },
    get: { url: API_ROUTES.users, method: 'get' },
    create: { url: API_ROUTES.users, method: 'post' },
    update: { url: API_ROUTES.users, method: 'put' },
    delete: { url: API_ROUTES.users, method: 'delete' },
    importUsers: { url: API_ROUTES.importUsers, method: 'post' },
  },
  menu: {
    label: 'Users',
    url: '/users',
    enabled: true,
  },
  fields: USER_FIELDS,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
  createItemsFn,
  roles: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge],
    view: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge],
    create: [UserRole.Admin, UserRole.SuperAdmin],
    update: [UserRole.Admin, UserRole.SuperAdmin],
  },
});
