import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface IProps {
  id: number;
  deleteFn: (id: number) => void;
}

const DeleteButton: FC<IProps> = ({ id, deleteFn }) => {
  const { t } = useTranslation();
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    deleteFn(id);
  };

  return (
    <Button onClick={handleClick} variant="contained" color="primary" style={{ height: '50px' }}>
      <p style={{ fontWeight: 'bold' }}>{t('buttons:Delete')}</p>
    </Button>
  );
};

export { DeleteButton };
