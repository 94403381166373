import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { UserEntity } from '~/pages/users/components/UserEntity';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { UserStatus } from '~/utils/constants/roles.constants';
import { useTranslation } from 'react-i18next';

interface IProps extends IEntityFieldProps {
  entity: UserEntity;
}

const UserBlockButton: FC<IProps> = ({ data }) => {
  const entity = useEntity<UserEntity>();
  const { t } = useTranslation();

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      if (data?.status !== UserStatus.Active) {
        entity.unBlockItem(data?.id || 0);
      } else {
        if (!window.confirm(t('Block user?'))) return;
        entity.blockItem(data?.id || 0);
      }
    },
    [data, entity, t],
  );

  return (
    <div className={styles.wrap}>
      <Button style={{ color: data?.status !== UserStatus.Active ? 'red' : 'green' }} onClick={onClick}>
        <PowerSettingsNewIcon />
      </Button>
    </div>
  );
};

export { UserBlockButton };
