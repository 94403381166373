export function isObjectEmpty(obj: Object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function phoneNumberDivider(value: string) {
  const phoneCodes = ['7', '8', '9'];
  const isMobilePhone = phoneCodes.includes(value?.[1]);

  if (!value) return;

  if (isMobilePhone) {
    return `${value.slice(0, 3)} ${value.slice(3, 7)} ${value.slice(7)}`;
  } else {
    return `${value.slice(0, 2)} ${value.slice(2, 6)} ${value.slice(6)}`;
  }
}

export function checkUrlsOnText(value: string) {
  const matchUrl = new RegExp(/(https?\:\/\/)?(www\.)?[^\s\]\\[\$#:;,._(){}]+\.[^\s\]\\[\$#:;,._(){}!]+/g);

  return matchUrl.test(value);
}

export function formatUrlsOnText(value: string) {
  const matchUrl = new RegExp(/(https?\:\/\/)?(www\.)?[^\s\]\\[\$#:;,._(){}]+\.[^\s\]\\[\$#:;,._(){}!]+/g);

  const urlWithProtocol = new RegExp(
    /(https?\:\/\/[^\s\[\]$#:;,_(){}]+\.?[^\s\]\\[\$#:;,._(){}]+\.[^\s\]\\[\$#:;,._(){}!]+)/,
  );
  const urlWithoutProtocol = new RegExp(
    /([a-zA-Z0-9][^\s\[\]$#:;,_(){}]+\.?[^\s\]\\[\$#:;,._(){}]+\.[^\s\]\\[\$#:;,._(){}!]+)/,
  );

  function renderMessageItem(value: string, match: RegExp, linkWithProtocol?: boolean) {
    const message = value
      .split(match)
      .map(item =>
        item.match(match)
          ? `<a href="${linkWithProtocol ? `http://${item}` : item}" target="_blank">${item}</a>`
          : item,
      )
      .join('');

    return message;
  }

  function renderExternalUrl(value: string) {
    if (value.match(urlWithProtocol)) {
      return renderMessageItem(value, urlWithProtocol);
    }

    if (value.match(urlWithoutProtocol)) {
      return renderMessageItem(value, urlWithoutProtocol, true);
    }
  }

  const formattedValue = value.split(' ').map(msg => {
    if (msg.match(matchUrl)) {
      return renderExternalUrl(msg);
    }
    return msg;
  });

  return formattedValue.join(' ');
}
