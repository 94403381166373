import React, { CSSProperties, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Button } from '@material-ui/core';

interface IProps {
  children?: ReactNode;
  headerTitle?: string;
  isButtonShown?: boolean;
  onAddClickFn?: () => void;
  disabledButton?: boolean;
  style?: CSSProperties;
}

const BlockContainer: FC<IProps> = ({ children, headerTitle, onAddClickFn, isButtonShown, disabledButton, style }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container} style={{ marginTop: isButtonShown ? '16px' : '0' }}>
      <div className={styles.head} style={{ height: isButtonShown || headerTitle ? '48px' : '0' }}>
        {headerTitle && <h2 className={styles.title}>{t(headerTitle)}</h2>}
        {isButtonShown && (
          <Button
            className={styles.button}
            variant="outlined"
            color="primary"
            disableElevation
            onClick={onAddClickFn}
            disabled={disabledButton}
          >
            {t('buttons:Add')}
          </Button>
        )}
      </div>
      <div className={styles.body} style={style}>
        {children}
      </div>
    </div>
  );
};

export { BlockContainer };
