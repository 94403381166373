import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { CustomInput } from '~/components/common/CustomInput';
import { IInputField, InputTypesEnum } from '~/utils/types/shops.types';
import { observer } from 'mobx-react';
import { placeHolderText } from '~/pages/shops/helper';

interface IProps {
  fields: Map<string, Omit<IInputField, 'field'>>;
  onChangeFieldValue: (field: string, value: string) => void;
  isViewPage?: boolean;
}

const TopMainInfoContainer: FC<IProps> = observer(({ fields, onChangeFieldValue, isViewPage }) => {
  const { t } = useTranslation();

  const onChange = (field: string, value: string, limit: number) => {
    if (value.length > limit) {
      const editedString = value.slice(0, limit);
      return onChangeFieldValue(field, editedString);
    }
    onChangeFieldValue(field, value);
  };

  return (
    <div className={styles.container}>
      {Array.from(fields.entries()).map(([field, data]) => {
        switch (data.type) {
          case InputTypesEnum.textInput:
            return (
              <CustomInput
                key={field}
                labelSeparate={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
                placeholder={placeHolderText(data.placeholder, isViewPage)}
                value={data.value}
                required={data.required}
                error={data.error.includes(':') ? t(data.error) : data.error}
                disabled={isViewPage}
                onChange={e => onChange(field, e.target.value, data.limit)}
                maxLength={data.limit}
              />
            );
          case InputTypesEnum.numberInput: {
            return (
              <CustomInput
                type={'number'}
                key={field}
                labelSeparate={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
                placeholder={data.placeholder.includes(':') ? t(data.placeholder) : data.placeholder}
                value={data.value}
                error={data.error.includes(':') ? t(data.error) : data.error}
                disabled={isViewPage}
                required={data.required}
                onChange={e => onChange(field, e.target.value, data.limit)}
                maxLength={data.limit}
              />
            );
          }
          case InputTypesEnum.textareaInput: {
            return (
              <div className={styles.textarea} key={field}>
                <CustomInput
                  labelSeparate={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
                  placeholder={data.placeholder.includes(':') ? t(data.placeholder) : data.placeholder}
                  isMultiline={true}
                  minTextareaHeight={'60px'}
                  rows={4}
                  key={field}
                  disabled={isViewPage}
                  error={data.error.includes(':') ? t(data.error) : data.error}
                  required={data.required}
                  value={data.value}
                  onChange={e => onChange(field, e.target.value, data.limit)}
                  maxLength={data.limit}
                />
              </div>
            );
          }
        }
      })}
    </div>
  );
});

export { TopMainInfoContainer };
