import { UserRole } from '~/utils/constants/roles.constants';
import { ConfigurationEntity } from './components/ConfigurationEntity';

export default new ConfigurationEntity({
  title: 'Configuration',
  menu: {
    label: 'Configuration',
    url: '/configuration',
    enabled: true,
  },
  roles: [UserRole.Admin, UserRole.SuperAdmin],
});
