export const API_ROUTES = {
  auth: {
    signin: '/v1/auth/signin',
    refresh: '/v1/auth/refresh',
  },
  users: '/v1/user',
  importUsers: '/v1/user/import',
  serviceRequests: '/v1/service-request',
  requestTypes: '/v1/service-request/type',
  transferRequests: '/v1/transfer-request',
  withdrawRequests: '/v1/withdraw-request',
  exchangeRequests: '/v1/exchange-request',
  qrCodes: '/v1/qr',
  transactions: '/v1/transaction',
  configuration: '/v1/settings',
  configurationByCategory: '/v1/settings/by-category',
  bankTransfers: '/v1/bank-transfer',
  uploadFile: '/v1/file/upload',
  shop: '/v1/shop',
  category: '/v1/category',
  product: '/v1/product',
  commitment: '/v1/commitment',
  reports: '/v1/reports',
  reportsWithParams: '/v1/reports/by-name',
  review: '/v1/review',
  reviewList: '/v1/review/for-admins',
  information: '/v1/information',
};
