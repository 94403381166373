import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { BreadcrumbsEnum, BreadcrumbsType } from '~/utils/types/shops.types';

interface IProps {
  crumbItems: BreadcrumbsType[];
  active: BreadcrumbsEnum;
  onClick: (name: BreadcrumbsEnum) => void;
}

const Breadcrumbs: FC<IProps> = ({ crumbItems, active, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.breadcrumbs}>
      {crumbItems.map(({ name }) => (
        <div
          className={`${styles.item} ${active === name ? styles.item__active : ''}`}
          onClick={() => onClick(name)}
          key={name}
        >
          {t(`buttons:${name}`)}
        </div>
      ))}
    </div>
  );
};

export { Breadcrumbs };
