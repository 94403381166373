import React, { FC, useCallback } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { CustomInput } from '../CustomInput';
import { checkUrlsOnText, formatUrlsOnText } from '~/utils/utils';

interface IProps extends IEntityFieldProps {}

const CustomTextareaField: FC<IProps> = ({ data, value, name, label, error, handler, placeholder, isEditing }) => {
  const onChange = useCallback(
    event => {
      if (!handler) return;

      handler(event.target.value);
    },
    [handler],
  );

  return isEditing ? (
    <CustomInput
      label={label}
      placeholder={placeholder}
      name={name}
      value={value ?? ''}
      onChange={onChange}
      rows={14}
      isMultiline
      error={error}
    />
  ) : (
    <>{checkUrlsOnText(value) ? <div dangerouslySetInnerHTML={{ __html: formatUrlsOnText(value) }} /> : value}</>
  );
};

export { CustomTextareaField };
