import { Entity, EntityBreadcrumbs } from 'icerockdev-admin-toolkit';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { DeleteButton } from '~/pages/category/components/DeleteButton';
import { deleteItemsFn } from '~/pages/category/api';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import { CustomEntityHead } from '~/components/entity/CustomEntityHead';

class CategoryEntity extends Entity {
  deletionText = 'このカテゴリを削除してもよろしいですか?';

  @action
  deleteItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.deletionText)) return;

    this.isLoading = true;
    try {
      await this.parent.auth
        .withToken(deleteItemsFn, {
          url: `${this?.api?.delete.url}/${id}`,
        })
        .catch((e: Error) => this.parent?.notifications.showError(e.message));

      await this.fetchItems();

      return 'success';
    } catch (e) {
      this.parent?.notifications.showError(e.message.toString());
    } finally {
      this.isLoading = false;
      if (this.parent?.history?.length && this.parent?.history.goBack) {
        this.parent?.history.goBack();
      } else if (this.parent?.history?.push) {
        this.parent?.history.push(this.menu.url);
      }
    }
  };

  @computed
  get ListHead() {
    return observer(() => (
      <CustomEntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get Breadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        buttons?: ReactElement;
      }) => (
        <EntityBreadcrumbs
          data={this.editorData}
          fields={this.fields}
          id={id}
          name={this.title}
          url={this.menu.url}
          isEditing={isEditing}
          isCreating={isCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={false}
        />
      ),
    );
  }

  @computed
  get ListBody() {
    return observer(() => {
      return (
        <CustomEntityList
          fields={this.fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable && this.canView}
          canEdit={this.editable && this.canEdit}
          canSelect={this.selectable}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: any }) => <DeleteButton id={id} deleteFn={this.deleteItem} />);
  }
}

export { CategoryEntity };
