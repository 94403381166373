import React, { FC } from 'react';
import localizeAmount from '~/utils/localization/amount';

type IProps = {
  value: number | null;
};

const FormattedNumber: FC<IProps> = ({ value }: IProps) => {
  return <>{value ? localizeAmount(value) : ''}</>;
};
export { FormattedNumber };
