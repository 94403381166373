import { UserRole } from '~/utils/constants/roles.constants';
import { ProfileEntity } from './components/ProfileEntity';

export default new ProfileEntity({
  title: 'Profile',
  menu: {
    label: 'Profile',
    url: '/profile',
    enabled: false,
  },
  roles: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business],
});
