import React, { createElement, Fragment, MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import styles from './styles';
import { Entity, EntityField, ENTITY_SORT_DIRS, IEntityField } from 'icerockdev-admin-toolkit';
import { observer } from 'mobx-react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import omit from 'ramda/es/omit';
import classnames from 'classnames';
import { CustomEntityHeadSortable } from '../CustomEntityHeadSortable';
import { useTranslation } from 'react-i18next';

type IProps = WithStyles<typeof styles> & {
  isLoading: boolean;
  fields: IEntityField[];
  data: Record<string, string>[];
  url: string;
  sortBy: string;
  sortDir: typeof ENTITY_SORT_DIRS[keyof typeof ENTITY_SORT_DIRS];
  selected: any[];
  disabledIfFieldName?: string;
  disabledIfKeysName?: string[];
  entity: Entity;
  extra: (({ id, onClose }: { id: any; onClose: (id: any) => void }) => JSX.Element) | null;
  canView: boolean;
  canEdit: boolean;
  canDelete?: boolean;
  canSelect?: boolean;
  setSelected: (items: any[]) => void;
  onSortChange: (field: string) => void;
  withToken?: (req: any, args: any) => void;
  onRowClick?: (id: any, event: MouseEvent<any>) => void;
  onDelete?: (id: any) => void;

  firstRow?: ReactNode;
  lastRow?: ReactNode;
  tableHead?: ReactNode;
  before?: ReactNode;
  after?: ReactNode;
};

const CustomEntityList = observer(
  withStyles(styles)(
    ({
      classes,
      isLoading,
      fields,
      entity,
      data,
      url,
      extra,
      selected,
      disabledIfFieldName,
      disabledIfKeysName,
      sortBy,
      sortDir,
      canView,
      canEdit,
      canDelete,
      canSelect,
      onSortChange,
      setSelected,
      withToken,
      onRowClick,
      onDelete,
      before = null,
      after = null,
      firstRow = null,
      lastRow = null,
      tableHead = null,
    }: IProps) => {
      const { t } = useTranslation();

      const [expanded, setExpanded] = useState<Record<any, boolean>>({});

      const visibleFields = useMemo(() => fields.filter(field => !field.hideInList), [fields]);

      const history = useHistory();

      const onRowClicked = useCallback(
        (id: any, event: MouseEvent<any>) => {
          if (onRowClick) {
            return onRowClick(id, event);
          }

          if (extra) {
            return setExpanded({ ...expanded, [id]: !expanded[id] });
          }

          if (canView) {
            return history.push(`${url}/${id}`);
          }

          if (canEdit) {
            return history.push(`${url}/${id}/edit`);
          }
        },
        [canView, canEdit, history, url, extra, expanded, setExpanded, onRowClick],
      );

      const onItemDelete = useCallback(
        (id: any) => {
          if (!window.confirm(t('Do you really want to delete QR Code?'))) return;
          if (onDelete) {
            onDelete(id);
          }
        },
        [onDelete, t],
      );

      const onSelect = useCallback(
        (id: any, includes: boolean) => {
          setSelected(!includes ? selected.filter(el => el !== id) : [...selected, id]);
        },
        [selected, setSelected],
      );

      const isAllSelected = useMemo(() => {
        if (disabledIfKeysName && disabledIfFieldName) {
          const filteredData = data.filter(item => !disabledIfKeysName.includes(item[disabledIfFieldName]));

          return !!data.length && !filteredData.length ? false : selected.length === filteredData.length;
        } else {
          return selected.length === data.length;
        }
      }, [data, disabledIfKeysName, disabledIfFieldName, selected]);

      const onSelectAll = useCallback(() => {
        if (disabledIfKeysName && disabledIfFieldName) {
          setSelected(
            isAllSelected
              ? []
              : data.filter(item => !disabledIfKeysName.includes(item[disabledIfFieldName])).map(el => el.id),
          );
        } else {
          setSelected(isAllSelected ? [] : data.map(el => el.id));
        }
      }, [data, disabledIfKeysName, disabledIfFieldName, isAllSelected, setSelected]);

      const colSpan = useMemo(
        () => visibleFields.length + (canView ? 1 : 0) + (canEdit ? 1 : 0) + (canSelect ? 1 : 0) + (extra ? 1 : 0),
        [visibleFields, canEdit, canView, canSelect, extra],
      );

      const onExtraClose = useCallback(id => setExpanded(omit([id], expanded)), [setExpanded, expanded]);

      if (isLoading) {
        return (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        );
      }

      return (
        <>
          {before}
          <TableContainer>
            <Table className={classes.table} stickyHeader size="medium">
              {tableHead || (
                <TableHead>
                  <TableRow>
                    {extra && <TableCell />}

                    {canSelect && (
                      <TableCell>
                        <Checkbox onChange={onSelectAll} checked={isAllSelected} />
                      </TableCell>
                    )}

                    {visibleFields.map(field =>
                      field.sortable ? (
                        <CustomEntityHeadSortable
                          active={sortBy === field.name}
                          direction={sortDir}
                          key={field.name}
                          field={field.name}
                          onSortChange={onSortChange}
                        >
                          {field.label ? t(`fields:${field.label}`) : field.name}
                        </CustomEntityHeadSortable>
                      ) : (
                        <TableCell key={field.name}>{field.label ? t(`fields:${field.label}`) : field.name}</TableCell>
                      ),
                    )}

                    {canView && <TableCell />}
                    {canEdit && <TableCell />}
                    {canDelete && <TableCell />}
                  </TableRow>
                </TableHead>
              )}

              <TableBody>
                {firstRow}
                {data.map((entry, i) => {
                  const disabled =
                    disabledIfFieldName && disabledIfKeysName
                      ? disabledIfKeysName.includes(entry[disabledIfFieldName])
                      : false;

                  return (
                    <Fragment key={i}>
                      <TableRow hover>
                        {extra && (
                          <TableCell onClick={event => onRowClicked(entry.id, event)}>
                            {expanded[entry.id] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                          </TableCell>
                        )}

                        {canSelect && (
                          <TableCell>
                            <Checkbox
                              checked={selected.includes(entry.id)}
                              onChange={(_, includes) => onSelect(entry.id, includes)}
                              disabled={disabled}
                            />
                          </TableCell>
                        )}

                        {visibleFields.map(field => (
                          <TableCell key={field.name} onClick={event => onRowClicked(entry.id, event)}>
                            <EntityField
                              name={field.name}
                              fields={fields}
                              data={entry}
                              withToken={withToken}
                              entity={entity}
                            />
                          </TableCell>
                        ))}

                        {canEdit && (
                          <TableCell size="small" align="right" className={classes.button}>
                            <Button to={`${url}/${entry.id}/edit`} component={RouterLink}>
                              <EditIcon />
                            </Button>
                          </TableCell>
                        )}

                        {canView && (
                          <TableCell
                            size="small"
                            align="right"
                            className={classnames(classes.button, classes.button_active)}
                          >
                            <Button to={`${url}/${entry.id}/`} component={RouterLink}>
                              <LaunchIcon />
                            </Button>
                          </TableCell>
                        )}

                        {canDelete && onDelete && (
                          <TableCell size="small" align="right" className={classes.button}>
                            <Button
                              onClick={() => onItemDelete(entry.id)}
                              style={{ minWidth: 'auto', color: '#aea69e' }}
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>

                      {!!extra && expanded[entry.id] && (
                        <TableRow>
                          <TableCell colSpan={colSpan}>
                            {createElement(extra, {
                              id: entry.id,
                              onClose: onExtraClose,
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  );
                })}
                {lastRow}
              </TableBody>
            </Table>
          </TableContainer>
          {after}
        </>
      );
    },
  ),
);

export { CustomEntityList };
