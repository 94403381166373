import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { CircularProgress } from '@material-ui/core';
import styles from './styles.module.scss';

interface IProps {
  getItem?: (id: string) => void;
  id?: string;
  cleanStore: () => void;
  isLoading: boolean;
}

const PageContainer: FC<IProps> = observer(({ getItem, id, children, cleanStore, isLoading }) => {
  useEffect(() => {
    if (getItem && id) {
      getItem(id);
    }
    return () => cleanStore();
  }, []);

  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      )}
      {children}
    </div>
  );
});

export { PageContainer };
