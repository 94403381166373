import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { TransferRequestEntity } from '../TransferRequestEntity';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

interface IProps extends IEntityFieldProps {
  entity: TransferRequestEntity;
}

export const ServiceRequestButton: FC<IProps> = ({ value, entity }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      history.push(`service-requests/${value}`);
    },
    [history, value],
  );

  return (
    <Button onClick={onClick} className={styles.link}>
      <div className={styles.body__wrap}>
        <ReceiptOutlinedIcon style={{ color: '#DC9B2D' }} />
        <span>{t('buttons:Service request')}</span>
      </div>
    </Button>
  );
};
