import React, { CSSProperties, FC } from 'react';
import styles from './styles.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField, FormHelperText } from '@material-ui/core';

type IProps = {
  label?: string;
  labelSeparate?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
  type?: 'date' | 'time' | 'number';
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isMultiline?: boolean;
  minTextareaHeight?: string;
  rows?: number;
  name?: string;
  disabled?: boolean | undefined;
  maxLength?: number;
  style?: CSSProperties;
};

const CustomInput: FC<IProps> = ({
  label,
  labelSeparate,
  required,
  value,
  placeholder,
  type,
  error,
  onChange,
  isMultiline,
  minTextareaHeight,
  rows,
  name,
  disabled,
  maxLength,
  style,
}: IProps) => {
  const classes = makeStyles(theme => ({
    root: {
      '& .MuiOutlinedInput-multiline': {
        minHeight: minTextareaHeight,
        padding: '12px 14px',
      },
    },
  }));

  return (
    <FormControl variant="outlined" fullWidth>
      {!!labelSeparate && (
        <div className={`${styles.label} ${error && styles.label__error}`}>
          <span>
            {labelSeparate}
            {required && ' *'}
          </span>
          {maxLength && <span>{`${value?.length} / ${maxLength}`}</span>}
        </div>
      )}
      <TextField
        label={label}
        placeholder={placeholder}
        variant="outlined"
        type={!!type ? type : 'string'}
        value={value}
        rows={rows}
        style={style}
        name={name}
        disabled={disabled}
        className={classes().root}
        multiline={isMultiline}
        error={!!error}
        onChange={onChange}
        fullWidth
        inputProps={{ maxLength }}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
};

export { CustomInput };
