import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityFetchFunction,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const fetchWihdrawRequests: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
}) => {
  const offset = page && count ? page * count : 0;
  const orderBy = sortDir?.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value?.trim() }), {}) || {};

  const response = await axios.get(url, {
    params: {
      offset,
      limit: count,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
    },
    headers: { authorization: token },
  });

  const list = response?.data?.data || [];
  const modifiedList = list.map(item => ({
    ...item,
    userName: `${item.user.firstName} ${item.user.lastName}`,
    role: item.user.role,
    clientId: item.user.clientId,
    pointsType: item.currency,
  }));

  return {
    data: {
      list: modifiedList,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};

export const createWithdrawRequest = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(`${url}`, data, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};

export const updateWithdrawRequest = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(`${url}`, data, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};
