import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@material-ui/core';
import styles from './styles.module.scss';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { ShopFieldType } from '~/utils/types/shops.types';

interface IProps {
  fields: IEntityField[];
  applyCustomFieldsVisibilityFn: (data: ShopFieldType) => void;
  customListFields: ShopFieldType;
}

const CustomFieldsModalContent: FC<IProps> = observer(({ applyCustomFieldsVisibilityFn, customListFields }) => {
  const [filteredFields, setFilteredFields] = useState<ShopFieldType>(customListFields);

  const onChangeHandler = (key: string, value: boolean) => {
    setFilteredFields(prev => ({ ...prev, [key]: value }));
  };

  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('fields:Choose columns to display in the list')}</h2>
      <div className={styles.fieldContainer}>
        {Object.keys(filteredFields).map(key => {
          return (
            <div className={styles.checkboxItem} key={key}>
              <p className={styles.label}>{t(`fields:${key}`)}:</p>
              <Checkbox
                className={styles.checkbox}
                checked={filteredFields[key]}
                color="primary"
                onChange={e => onChangeHandler(key, e.target.checked)}
              />
            </div>
          );
        })}
      </div>
      <Button
        className={styles.button}
        type="button"
        variant="contained"
        color="primary"
        onClick={() => applyCustomFieldsVisibilityFn(filteredFields)}
      >
        {t('buttons:Done')}
      </Button>
    </div>
  );
});

export { CustomFieldsModalContent };
