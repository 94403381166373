import React, { ReactElement } from 'react';
import { Entity, EntityBreadcrumbs } from 'icerockdev-admin-toolkit';
import { computed, action } from 'mobx';
import { observer } from 'mobx-react';
import { CustomEntityHead } from '~/components/entity/CustomEntityHead';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import { API_ROUTES } from '~/utils/constants/api.constants';
import i18n from 'i18next';
import { deleteItemFn } from '~/pages/information/api';
import { DeleteButton } from '~/pages/category/components/DeleteButton';
import { EditButton } from '~/components/common/EditButton';
import { getErrorMessage } from '~/utils/axios';

class InformationEntity extends Entity {
  @action
  deleteInformationById = async (id: number) => {
    this.isLoading = true;
    try {
      const result = await this.parent?.auth?.withToken(deleteItemFn, {
        url: `${API_ROUTES.information}/${id}`,
      });
      if (result.status) {
        this.parent?.notifications.showSuccess(i18n.t('messages:The information deleted successfully'));
      }

      this.parent?.history.push('/information');
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    } finally {
      this.isLoading = false;
      this.fetchItems();
    }
  };

  @computed
  get ListHead() {
    return observer(() => (
      <CustomEntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get Breadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        buttons?: ReactElement;
      }) => (
        <EntityBreadcrumbs
          data={this.editorData}
          fields={this.fields}
          id={id}
          name={this.title}
          url={this.menu.url}
          isEditing={isEditing}
          isCreating={isCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={false}
        />
      ),
    );
  }

  @computed
  get ListBody() {
    return observer(() => {
      const formattedData = this.data.map(item => ({
        ...item,
        text: item.text?.length <= 60 ? item.text : `${item.text.slice(0, 60)}...`,
      }));

      return (
        <>
          <CustomEntityList
            fields={this.fields}
            data={formattedData}
            extra={this.ListExtra}
            isLoading={this.isLoading}
            url={this.menu.url}
            selected={this.selected}
            sortBy={this.sortBy}
            sortDir={this.sortDir}
            canView={this.viewable && this.canView}
            canEdit={this.editable && this.canEdit}
            canSelect={this.selectable}
            setSelected={this.setSelected}
            onSortChange={this.setSort}
            withToken={this.parent?.auth?.withToken}
            entity={this}
          />
        </>
      );
    });
  }

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: number }) => (
      <div style={{ display: 'flex', gap: '10px' }}>
        <DeleteButton id={id} deleteFn={this.deleteInformationById} />
        <EditButton id={id} url={this.menu.url} />
      </div>
    ));
  }
}

export { InformationEntity };
