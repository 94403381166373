import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { CustomInput } from '~/components/common/CustomInput';
import { URL_LIMIT } from '~/pages/shops/shops-constants';
import { IUrlItem, UrlActionsEnum } from '~/utils/types/shops.types';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  selectedItem: IUrlItem | null;
  addItem: (action: UrlActionsEnum, item: IUrlItem) => void;
  cleanSelectedItem: () => void;
  title: string;
}

const UrlModalContent: FC<IProps> = observer(({ addItem, selectedItem, cleanSelectedItem, title }) => {
  const [item, setItem] = useState<IUrlItem>(
    selectedItem || {
      id: uuidv4(),
      title: '',
      link: '',
      isHide: false,
    },
  );

  useEffect(() => {
    if (selectedItem) {
      setItem(selectedItem);
    }
    return () => {
      cleanSelectedItem();
    };
  }, [selectedItem]);

  const { t } = useTranslation();

  const onChangeInput = (event: any) => {
    event.persist();
    let editedString = event.target.value;
    if (editedString.length > URL_LIMIT[event.target.name]) {
      editedString = editedString.slice(0, URL_LIMIT[event.target.name]);
    }

    setItem(prevState => ({ ...prevState, [event.target.name]: editedString }));
  };

  const addItemFunction = () => {
    if (item.title && item.link && !selectedItem) {
      addItem(UrlActionsEnum.CREATE, item);
    }
    if (item.title && item.link && selectedItem) {
      addItem(UrlActionsEnum.EDIT, item);
    }
  };

  const toggleCheckbox = () => {
    setItem(prevState => ({ ...prevState, isHide: !prevState.isHide }));
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t(`fields:${title}`)}</h2>
      <CustomInput
        labelSeparate={t('fields:Title')}
        placeholder={''}
        value={item.title}
        name="title"
        onChange={e => onChangeInput(e)}
        maxLength={URL_LIMIT.title}
      />
      <CustomInput
        labelSeparate={t('fields:Link')}
        placeholder={''}
        value={item.link}
        name="link"
        onChange={e => onChangeInput(e)}
        maxLength={URL_LIMIT.link}
      />
      <div className={styles.checkboxBox}>
        <Checkbox
          style={{ width: '18px', height: '18px' }}
          checked={item.isHide}
          onChange={toggleCheckbox}
          color="primary"
        />
        <h3>{t('fields:Hide in front page')}</h3>
      </div>
      <Button
        className={styles.bottomButton}
        variant="contained"
        color="primary"
        type="button"
        disableElevation
        onClick={addItemFunction}
        disabled={!item.link || !item.title}
      >
        {t(`buttons:${selectedItem ? 'Save' : 'Add'}`)}
      </Button>
    </div>
  );
});

export { UrlModalContent };
