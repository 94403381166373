import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { observer } from 'mobx-react';
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from '@material-ui/core';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { phoneNumberDivider } from '~/utils/utils';

type IProps = IEntityFieldProps;

const UserPhoneInput: FC<IProps> = observer(({ label, value, handler, isEditing, error }) => {
  useEffect(() => {
    return () => {
      if (!handler) return;
      handler('');
    };
  }, []);

  const handlePhoneChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!handler) return;
      handler(e.target.value.replace(/ /g, ''));
    },
    [handler],
  );

  const phoneCodes = ['7', '8', '9'];
  const isMobilePhone = phoneCodes.includes(value?.[1]);

  return (
    <div>
      {isEditing ? (
        <FormControl variant="outlined">
          <InputLabel htmlFor="phone">{label}</InputLabel>

          <InputMask
            mask={isMobilePhone ? '099 9999 9999' : '09 9999 9999'}
            maskChar={null}
            value={value}
            onChange={handlePhoneChange}
          >
            {inputProps => <OutlinedInput id="phone" label={label} error={!!error} />}
          </InputMask>

          {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </FormControl>
      ) : (
        <div className={styles.field__wrap}>{phoneNumberDivider(value)}</div>
      )}
    </div>
  );
});
export { UserPhoneInput };
