import React, { FC, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { CustomInput } from '~/components/common/CustomInput';
import { IInputField, InputTypesEnum } from '~/utils/types/shops.types';
import { observer } from 'mobx-react';
import { DatePicker } from '~/components/common/DatePicker';
import { CustomSelect } from '~/components/common/CustomSelect';
import { CustomRadio } from '~/components/common/CustomRadio';
import { formatISO } from 'date-fns';
import { placeHolderText } from '~/pages/shops/helper';

interface IProps {
  fields: Map<string, Omit<IInputField, 'field'>>;
  onChangeFieldValue: (field: string, value: string) => void;
  style?: CSSProperties;
  isViewPage?: boolean;
}

const FieldsContainer: FC<IProps> = observer(({ fields, onChangeFieldValue, style, isViewPage }) => {
  const { t } = useTranslation();

  const onChange = (field: string, value: string | Date | { label: string; value: string }, limit: number) => {
    if (value === 'null') {
      return;
    }
    if (value instanceof Date) {
      return onChangeFieldValue(field, formatISO(value));
    }
    if (typeof value === 'object') {
      return onChangeFieldValue(field, value.value);
    }
    if (value.length > limit) {
      const editedString = value.slice(0, limit);
      return onChangeFieldValue(field, editedString);
    }
    onChangeFieldValue(field, value);
  };

  return (
    <div className={styles.container} style={style}>
      {Array.from(fields.entries()).map(([field, data]) => {
        switch (data.type) {
          case InputTypesEnum.textInput:
            return (
              <CustomInput
                key={field}
                disabled={isViewPage}
                error={data.error.includes(':') ? t(data.error) : data.error}
                labelSeparate={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
                placeholder={placeHolderText(data.placeholder, isViewPage)}
                value={data.value ?? ''}
                required={data.required}
                onChange={e => onChange(field, e.target.value, data.limit)}
                maxLength={data.limit}
              />
            );
          case InputTypesEnum.numberInput: {
            return (
              <CustomInput
                type={'number'}
                key={field}
                disabled={isViewPage}
                error={data.error.includes(':') ? t(data.error) : data.error}
                labelSeparate={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
                placeholder={placeHolderText(data.placeholder, isViewPage)}
                value={data.value.toString() ?? ''}
                required={data.required}
                onChange={e => onChange(field, e.target.value, data.limit)}
                maxLength={data.limit}
              />
            );
          }
          case InputTypesEnum.textareaInput: {
            return (
              <CustomInput
                labelSeparate={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
                placeholder={placeHolderText(data.placeholder, isViewPage)}
                isMultiline={true}
                minTextareaHeight={'60px'}
                rows={3}
                disabled={isViewPage}
                key={field}
                error={data.error.includes(':') ? t(data.error) : data.error}
                required={data.required}
                value={data.value ?? ''}
                onChange={e => onChange(field, e.target.value, data.limit)}
                maxLength={data.limit}
              />
            );
          }
          case InputTypesEnum.date:
            return (
              <DatePicker
                key={field}
                labelSeparate={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
                value={data.value && data.value !== 'null' ? new Date(data.value) : null}
                onChange={(date: Date) => onChange(field, date, data.limit)}
                required={data.required}
                disabled={isViewPage}
                error={data.error.includes(':') ? t(data.error) : data.error}
              />
            );
          case InputTypesEnum.selector:
            return (
              <CustomSelect
                key={field}
                labelSeparate={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
                placeholder={placeHolderText(data.placeholder, isViewPage)}
                options={data?.additionalFields || []}
                value={data?.additionalFields?.find((field: any) => field.value === data.value) || undefined}
                onChange={e => onChange(field, e, data.limit)}
                required={data.required}
                isDisabled={isViewPage}
                error={data.error.includes(':') ? t(data.error) : data.error}
              />
            );
          case InputTypesEnum.checkbox:
            return (
              <CustomRadio
                className={styles.radio}
                key={field}
                label={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
                ariaId={field}
                fields={[]}
                disabled={isViewPage}
                value={data.value ?? ''}
                onChange={e => onChange(field, e.target.value, data.limit)}
                required={data.required}
                error={data.error.includes(':') ? t(data.error) : data.error}
              />
            );
        }
      })}
    </div>
  );
});

export { FieldsContainer };
