import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

type IProps = IEntityFieldProps;

const TransactionOwnerWallet: FC<IProps> = observer(({ value }) => {
  return <>{value && `${value?.owner?.firstName} ${value?.owner?.lastName}`}</>;
});

export { TransactionOwnerWallet };
