import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface IProps {
  onEdit: () => void;
  onSave: () => void;

  title: string;
  isEditing: boolean;
  isLoading: boolean;
  isSwitcherLoading: boolean;
}

const ConfigurationHead: FC<IProps> = observer(({ onEdit, onSave, title, isLoading, isSwitcherLoading, isEditing }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <h1>
        {t(`common:${title}`)} {isSwitcherLoading && <CircularProgress size={24} style={{ marginLeft: 12 }} />}
      </h1>
      <div className={styles.buttons__wrap}>
        {isEditing && (
          <Button
            variant="contained"
            color="primary"
            className={styles.button}
            disableElevation
            onClick={onSave}
            disabled={isLoading}
          >
            {t('buttons:Save')}
          </Button>
        )}
        <Button variant="outlined" color="default" className={styles.button} onClick={onEdit} disabled={isLoading}>
          {!isEditing ? t('Edit') : t('Cancel')}
        </Button>
      </div>
    </div>
  );
});

export { ConfigurationHead };
