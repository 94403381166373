import { axios } from '~/utils/axios';
import { IEntityFetchFunctionProps, IEntityFetchFunctionResult, ShopOptionType } from '~/utils/types/reports.types';

export const fetchShopFn = async ({ url, token, params }: IEntityFetchFunctionProps): Promise<ShopOptionType[]> => {
  const result = await axios.get(url, {
    headers: { authorization: token },
    params,
  });

  return result.data.data.map(shop => ({
    label: shop.name,
    value: shop.id,
  }));
};

export const fetchReportItem = async ({
  url,
  token,
  params,
}: IEntityFetchFunctionProps): Promise<IEntityFetchFunctionResult> => {
  const result = await axios.get(url, {
    headers: { authorization: token },
    params,
  });

  return result.data.data;
};
