import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, CircularProgress, Paper, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from '~/components/common/CustomSelect';
import { ServiceRequestEntity } from '../ServiceRequestEntity';
import { ServiceRequestDynamicFields } from '~/utils/constants/requests.constants';
import { formatISO, isValid } from 'date-fns';
import {
  IDate,
  IServiceField,
  IServiceFieldOptionExtraData,
  IServiceFieldStepperExtraData,
} from '~/utils/types/requests.types';
import { isObjectEmpty } from '~/utils/utils';
import { CustomInput } from '~/components/common/CustomInput';
import { CustomRadio } from '~/components/common/CustomRadio';
import { DatePicker } from '~/components/common/DatePicker';
import useDebounce from '~/utils/hooks/useDebounce';
import { ImagesAttachment } from '../ImagesAttachment';
import { useConfig } from 'icerockdev-admin-toolkit';
import { UserRole } from '~/utils/constants/roles.constants';
import { CustomStepper } from '~/components/common/CustomStepper';

type IProps = {
  url: string;
  isLoading: boolean;
  entity: ServiceRequestEntity;
  onCancel: () => void;
};

interface IOption {
  value: number;
  label: string;
  subtypes?: {
    id: number;
    name: string;
  }[];
}

interface IDynamicFields {
  value: string | IDate | null;
  type: ServiceRequestDynamicFields;
  required: boolean;
}

const EntityCreator: FC<IProps> = observer(({ url, onCancel, isLoading, entity }: IProps) => {
  const { t } = useTranslation();
  const {
    listConcierges,
    isConsiergesListLoading,
    fetchUsers,
    listUsers,
    isUsersListLoading,
    fetchTypes,
    listTypes,
    isTypesListLoading,
    fetchScreens,
    listScreens,
    isScreensListLoading,
    fetchConcierges,
  } = entity;
  const config = useConfig();
  const userRole = config.auth?.user.role;

  const conciergeOptions = useMemo(() => {
    return listConcierges.map(concierge => ({
      value: concierge.id,
      label: `${concierge.firstName} ${concierge.lastName}`,
    }));
  }, [listConcierges]);

  const userOptions = useMemo(
    () =>
      listUsers.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })),
    [listUsers],
  );

  const typesOptions = useMemo(
    () =>
      listTypes.map(type => ({
        value: !!type.id ? type.id : type.name,
        label: type.name,
        subtypes: type.subtypes,
      })),
    [listTypes],
  );

  const [staticFields, setStaticFields] = useState<{ [key: string]: { value: string; required: boolean } }>({
    title: {
      value: '',
      required: true,
    },
    comment: {
      value: '',
      required: false,
    },
    user: {
      value: '',
      required: true,
    },
    concierge: {
      value: '',
      required: false,
    },
    category: {
      value: '',
      required: true,
    },
    service: {
      value: '',
      required: true,
    },
  });

  const [selectedType, setSelectedType] = useState<IOption | null>(null);
  const [selectedSubtype, setSelectedSubtype] = useState<IOption | null>(null);
  const [subtypesOptions, setSubtypesOptions] = useState<IOption[]>();
  const [showService, setShowService] = useState(false);

  const [dynamicFields, setDynamicFields] = useState<{ [key: string]: IDynamicFields }>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [searchQueryUser, setSearchQueryUser] = useState<string>('');
  const [searchQueryConcierge, setSearchQueryConcierge] = useState<string>('');

  const searchQueryUserThrottle: string = useDebounce(searchQueryUser, 500);
  const searchQueryConciergeThrottle: string = useDebounce(searchQueryConcierge, 500);

  useEffect(() => {
    fetchConcierges(searchQueryConciergeThrottle);
  }, [fetchConcierges, searchQueryConciergeThrottle]);

  const onConciergeChange = useCallback((value: string) => {
    setSearchQueryConcierge(value);
  }, []);

  useEffect(() => {
    fetchUsers(searchQueryUserThrottle);
  }, [fetchUsers, searchQueryUserThrottle]);

  const onUserChange = useCallback((value: string) => {
    setSearchQueryUser(value);
  }, []);

  const onChangeType = (option: IOption) => {
    setSelectedType(option);
    setShowService(false);
    setSelectedSubtype(null);
    setDynamicFields({});
    entity.listScreens = [];

    if (!!option.subtypes) {
      setShowService(true);

      const options = option.subtypes.map(sub => ({
        value: sub.id,
        label: sub.name,
      }));

      setSubtypesOptions(options);
    }

    onChangeStaticField('category', String(option.value));
  };

  const onChangeSubtype = (option: IOption) => {
    setSelectedSubtype(option);
    setDynamicFields({});
    entity.listScreens = [];

    onChangeStaticField('service', String(option.value));
  };

  const onChangeStaticField = (key: string, value: string) => {
    setStaticFields(prevState => ({ ...prevState, [key]: { ...prevState[key], value } }));
    const err = errors;
    delete err[key];
    setErrors(err);
  };

  const onChangeDynamicField = (key: string, value: string | IDate) => {
    setDynamicFields(prevState => ({ ...prevState, [key]: { ...prevState[key], value } }));
    const err = errors;
    delete err[key];
    setErrors(err);
  };

  const toIsoDate = (value: Date) => {
    const isValidDate = isValid(value);
    return isValidDate ? formatISO(value) : null;
  };

  const onCreateServiceRequest = useCallback(() => {
    const newErrors = {};

    Object.keys(staticFields).forEach(key => {
      if (!staticFields[key].value && staticFields[key].required) {
        newErrors[key] = t('messages:Required field');
      }
    });

    Object.keys(dynamicFields).forEach(key => {
      if (!dynamicFields[key].value && dynamicFields[key].required) {
        newErrors[key] = t('messages:Required field');
      }
    });

    setErrors(newErrors);

    if (isObjectEmpty(newErrors) && !!selectedType) {
      entity.createNewRequest({
        userId: Number(staticFields['user'].value),
        conciergeId: !!staticFields['concierge'].value ? Number(staticFields['concierge'].value) : null,
        typeId: !!selectedSubtype ? selectedSubtype.value : selectedType?.value,
        title: staticFields['title'].value,
        comment: staticFields['comment'].value,
        fields: Object.keys(dynamicFields).map(key => {
          const date = toIsoDate((dynamicFields[key].value as IDate).date as Date);

          const fieldDate = !!(dynamicFields[key].value as IDate).date
            ? JSON.stringify({
                date,
                isNotFixed: (dynamicFields[key].value as IDate).isNotFixed,
              })
            : null;

          const fieldRadio = dynamicFields[key].value ? dynamicFields[key].value : null;

          const value =
            dynamicFields[key].type === ServiceRequestDynamicFields.Date
              ? fieldDate
              : dynamicFields[key].type === ServiceRequestDynamicFields.Radio
              ? fieldRadio
              : dynamicFields[key].value;

          return { fieldId: key, value };
        }),
      });
    }
  }, [staticFields, dynamicFields, selectedSubtype, selectedType, t, entity]);

  useEffect(() => {
    fetchUsers();
    fetchTypes();

    return function () {
      entity.listScreens = [];
    };
  }, [entity, fetchTypes, fetchUsers]);

  useEffect(() => {
    if (!showService && !!selectedType) {
      fetchScreens(selectedType.value);
    }
    if (selectedSubtype) {
      fetchScreens(selectedSubtype.value);
    }
  }, [fetchScreens, selectedSubtype, selectedType, showService]);

  useEffect(() => {
    if (!!listScreens) {
      listScreens.forEach(screen => {
        screen.fields.forEach(field =>
          setDynamicFields(prevState => ({
            ...prevState,
            [field.id]: {
              value: field.type === ServiceRequestDynamicFields.Date ? { date: null, isNotFixed: false } : '',
              type: field.type,
              required: field.required,
            },
          })),
        );
      });
    }
  }, [listScreens]);

  function renderNewFields(item: ServiceRequestDynamicFields, field: IServiceField) {
    switch (item) {
      case ServiceRequestDynamicFields.Input:
        return (
          <CustomInput
            key={field.id}
            labelSeparate={field.title}
            value={dynamicFields[field.id]?.value as string}
            placeholder={field.emptyMessage}
            onChange={e => onChangeDynamicField(field.id, e.target.value)}
            required={field.required}
            error={errors[field.id]}
          />
        );
      case ServiceRequestDynamicFields.Number:
        return (
          <CustomInput
            type="number"
            key={field.id}
            labelSeparate={field.title}
            value={dynamicFields[field.id]?.value as string}
            placeholder={field.emptyMessage}
            onChange={e => onChangeDynamicField(field.id, e.target.value)}
            required={field.required}
            error={errors[field.id]}
          />
        );
      case ServiceRequestDynamicFields.Date:
        return (
          <div key={field.id}>
            <DatePicker
              labelSeparate={field.title}
              value={(dynamicFields[field.id]?.value as IDate)?.date}
              onChange={(date: Date) =>
                onChangeDynamicField(field.id, { ...(dynamicFields[field.id].value as IDate), date })
              }
              required={field.required}
              error={errors[field.id]}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value={(dynamicFields[field.id]?.value as IDate)?.isNotFixed}
                    onChange={e =>
                      onChangeDynamicField(field.id, {
                        ...(dynamicFields[field.id].value as IDate),
                        isNotFixed: e.target.checked,
                      })
                    }
                  />
                }
                label={t('buttons:Date is not fixed yet')}
              />
            </FormGroup>
          </div>
        );
      case ServiceRequestDynamicFields.Radio:
        return (
          <CustomRadio
            key={field.id}
            label={field.title}
            ariaId={field.id}
            fields={field.extraData as IServiceFieldOptionExtraData[]}
            onChange={e => onChangeDynamicField(field.id, e.target.value)}
            required={field.required}
            error={errors[field.id]}
          />
        );
      case ServiceRequestDynamicFields.Time:
        return (
          <CustomInput
            key={field.id}
            labelSeparate={field.title}
            value={dynamicFields[field.id]?.value as string}
            placeholder={field.emptyMessage}
            onChange={e => onChangeDynamicField(field.id, e.target.value)}
            type="time"
            required={field.required}
            error={errors[field.id]}
          />
        );
      case ServiceRequestDynamicFields.Select: {
        const options = (field.extraData as IServiceFieldOptionExtraData[])?.map(option => ({
          value: option.id,
          label: option.value,
        }));
        return (
          <CustomSelect
            key={field.id}
            labelSeparate={field.title}
            placeholder={field.emptyMessage}
            options={options || []}
            onChange={option => onChangeDynamicField(field.id, option.value)}
            required={field.required}
            error={errors[field.id]}
          />
        );
      }
      case ServiceRequestDynamicFields.Stepper: {
        const item = dynamicFields[field.id];
        const extraData = field.extraData as IServiceFieldStepperExtraData;

        return (
          <CustomStepper
            key={field.id}
            labelSeparate={field.title}
            value={Number(item.value)}
            onChange={value => onChangeDynamicField(field.id, value >= extraData.minVal ? value.toString() : '')}
            minValue={extraData.minVal}
            maxValue={extraData.maxValue}
            required={field.required}
            error={errors[field.id]}
          />
        );
      }
      case ServiceRequestDynamicFields.Image: {
        return (
          <ImagesAttachment
            key={field.id}
            label={field.title}
            handler={value => onChangeDynamicField(field.id, value)}
            isEditing
          />
        );
      }
    }
  }

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.wrap}>
      <Paper className={styles.main}>
        <CustomSelect
          labelSeparate={t('fields:User')}
          placeholder={t('fields:Select user')}
          options={userOptions}
          filterOption={() => true}
          onChange={option => onChangeStaticField('user', option.value)}
          onInputChange={onUserChange}
          isLoading={isUsersListLoading}
          required
          error={errors['user']}
        />
        {userRole !== UserRole.Concierge && (
          <CustomSelect
            labelSeparate={t('fields:Concierge')}
            placeholder={t('fields:Select concierge')}
            options={conciergeOptions}
            filterOption={() => true}
            onChange={option => onChangeStaticField('concierge', option.value)}
            onInputChange={onConciergeChange}
            isLoading={isConsiergesListLoading}
          />
        )}
      </Paper>

      <Paper className={styles.main}>
        <CustomInput
          labelSeparate={t('fields:Title')}
          placeholder={t('fields:Enter a title')}
          value={staticFields['title'].value}
          onChange={e => onChangeStaticField('title', e.target.value)}
          required
          error={errors['title']}
        />
        <CustomInput
          labelSeparate={t('fields:Comment')}
          placeholder={t('fields:Enter a comment')}
          value={staticFields['comment'].value}
          onChange={e => onChangeStaticField('comment', e.target.value)}
        />
      </Paper>

      <div className={styles.grid}>
        <Paper className={styles.element}>
          <CustomSelect
            labelSeparate={t('fields:Category')}
            placeholder={t('fields:Select category')}
            options={typesOptions}
            onChange={onChangeType}
            isLoading={isTypesListLoading}
            required
            error={errors['category']}
          />

          {showService && subtypesOptions && (
            <CustomSelect
              labelSeparate={t('fields:Service')}
              placeholder={t('fields:Select service')}
              options={subtypesOptions}
              value={selectedSubtype}
              onChange={onChangeSubtype}
              required
              error={errors['service']}
            />
          )}
        </Paper>

        {isScreensListLoading && <CircularProgress size={24} />}

        {!isObjectEmpty(dynamicFields) &&
          listScreens.map((screen, index) => {
            return (
              <Paper key={`${index}-${screen}`} className={styles.element}>
                {!!screen.title && <div className={styles.title}>{screen.title}</div>}
                {screen.fields.map(field => renderNewFields(field.type, field))}
              </Paper>
            );
          })}
      </div>

      <Paper className={styles.buttons__wrap}>
        <Grid container spacing={1}>
          <Grid item style={{ flex: 1 }} />

          <Grid item>
            <Button type="button" color="default" variant="outlined" onClick={onCancel}>
              {t('buttons:Cancel')}
            </Button>
          </Grid>

          <Grid item>
            <Button type="button" variant="contained" color="primary" onClick={onCreateServiceRequest}>
              {t('buttons:Save')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
});

export { EntityCreator };
