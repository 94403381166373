import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

const FullNameField: FC<IEntityFieldProps> = ({ value }) => {
  if (!value) return null;

  const { firstName, lastName } = value;

  return <span>{`${firstName || ''} ${lastName || ''}`}</span>;
};

export default FullNameField;
