import React, { useCallback, useMemo } from 'react';
import { CircularProgress, Paper, withStyles, WithStyles } from '@material-ui/core';
import styles from './styles';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { EntityField, IEntityField } from 'icerockdev-admin-toolkit';
import { ServiceRequestDynamicFields } from '~/utils/constants/requests.constants';
import { FormattedDate } from '~/components/common/FormattedDate';
import localizeAmount from '~/utils/localization/amount';

type IProps = WithStyles<typeof styles> & {
  url?: string;
  id?: string;
  fields: IEntityField[];
  errors: Record<string, string>;
  isEditing?: boolean;
  isLoading?: boolean;
  data?: Record<string, any>;
  viewable?: boolean;

  setEditorData: (data: Record<string, any>) => void;
  getItem?: (id: any) => void;
  onResetFieldError: (field: string) => void;
  withToken?: (req: any, args: any) => any;
};

const ProfileViewer = withStyles(styles)(
  observer(
    ({
      classes,
      id,
      fields,
      errors,
      onResetFieldError,
      isLoading,
      data,
      setEditorData,
      withToken,
      isEditing,
    }: IProps) => {
      const { t } = useTranslation();
      const isCreating = useMemo(() => typeof id === 'undefined', [id]);
      const walletDataProfile = data?.wallets;

      const visibleFields = useMemo(
        () =>
          fields.filter(
            field =>
              (isEditing && !isCreating && !field.hideInEdit) ||
              (isCreating && !field.hideInCreate) ||
              (!isEditing && !isCreating && !field.hideInView),
          ),
        [fields, isEditing, isCreating],
      );

      const onFieldChange = useCallback(
        f => (value: any) => {
          if (errors[f]) {
            onResetFieldError(f);
          }

          setEditorData({ ...data, [f]: value });
        },
        [errors, setEditorData, data, onResetFieldError],
      );

      if (isLoading) {
        return (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        );
      }

      return (
        <div className={classes.wrap}>
          {!!data && (
            <Paper>
              <div className={classes.grid} style={{ flexWrap: 'wrap' }}>
                {visibleFields.map(field =>
                  field.name === 'fields' ? (
                    <div key={field.name}>
                      {data[field.name]?.map(item => (
                        <div className={classes.field} key={item.id}>
                          <div className="label">{item.title}</div>
                          <div className="field">
                            {item.type === ServiceRequestDynamicFields.Date ? (
                              <FormattedDate value={item.value} />
                            ) : (
                              item.value
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className={classes.field} key={field.name}>
                      {!isEditing && (
                        <div className="label" style={{ width: '100%' }}>
                          {field.label ? t(`fields:${field.label}`) : field.name}
                          {isEditing && field.required && <span>{` *`}</span>}
                        </div>
                      )}
                      <div className="field" style={{ width: '100%', textAlign: 'right' }}>
                        <EntityField
                          name={field.name}
                          data={data}
                          fields={fields}
                          isEditing={isEditing}
                          error={errors[field.name]}
                          handler={onFieldChange(field.name)}
                          withToken={withToken}
                        />
                      </div>
                    </div>
                  ),
                )}
              </div>
            </Paper>
          )}

          {!!walletDataProfile && !isEditing && (
            <>
              {!!walletDataProfile.length && <h2>{walletDataProfile.length > 1 ? t('Wallets') : t('Wallet')}</h2>}
              <Paper>
                <div style={{ flexWrap: 'wrap' }}>
                  {walletDataProfile.map((wallet, index) => (
                    <div key={`${index}-${wallet.currency}`}>
                      <div className={classes.field}>
                        <div className="label">{t('fields:Currency')}</div>
                        <div className="field">{wallet.currency}</div>
                      </div>
                      <div className={classes.field}>
                        <div className="label">{t('fields:Amount')}</div>
                        <div className="field">{localizeAmount(wallet.amount)}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </Paper>
            </>
          )}
        </div>
      );
    },
  ),
);

export { ProfileViewer };
