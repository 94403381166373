import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { FormattedDate } from '~/components/common/FormattedDate';
import { Category } from './components/Category';
import { SelectConcierge } from './components/SelectConcierge';
import { SelectUser } from './components/SelectUser';
import { ColoredStatus } from '~/components/common/ColoredStatus';
import { SERVICE_REQUEST_STATUS_NAMES, SERVICE_REQUEST_STATUS_NAME_COLORS } from '~/utils/constants/requests.constants';

export const SERVICE_REQUEST_FIELDS: IEntityField[] = [
  {
    type: 'textarea',
    name: 'note',
    label: 'Note',
    hideInList: true,
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: 'status',
    label: 'Status',
    component: props => (
      <ColoredStatus
        {...props}
        statusNames={SERVICE_REQUEST_STATUS_NAMES}
        statusNameColors={SERVICE_REQUEST_STATUS_NAME_COLORS}
      />
    ),
    filterable: true,
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'title',
    label: 'Request title',
    hideInList: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: 'type',
    label: 'Request category',
    required: true,
    hideInEdit: true,
    component: Category,
  },
  {
    type: 'string',
    name: 'createdAt',
    label: 'Request creation date',
    required: true,
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
    component: props => <FormattedDate {...props} withTime />,
  },
  {
    type: 'string',
    name: 'updatedAt',
    label: 'Updated date',
    required: true,
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
    component: props => <FormattedDate {...props} withTime />,
  },
  {
    type: 'custom',
    name: 'author',
    label: 'Member Name',
    required: true,
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
    component: SelectUser,
  },
  {
    type: 'string',
    name: 'clientId',
    label: 'Member ID',
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
    filterable: true,
  },
  {
    type: 'string',
    name: 'userWallet',
    label: 'User wallet',
    hideInEdit: true,
    hideInList: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'comment',
    label: 'Comment',
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: 'concierge',
    label: 'Manager',
    required: true,
    hideInEdit: true,
    component: SelectConcierge,
  },
  {
    type: 'string',
    name: 'fields',
    label: ' ',
    required: true,
    hideInList: true,
    hideInCreate: true,
  },
];
