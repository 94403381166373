import { IEntityField } from 'icerockdev-admin-toolkit';
import { CustomTextareaField } from '~/components/common/CustomTextareaField';

export const INFORMATION_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'Id',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'date',
    name: 'createdAt',
    label: 'Created at',
    sortable: true,
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'title',
    label: 'Title',
    required: true,
  },
  {
    type: 'custom',
    name: 'text',
    label: 'Text',
    required: true,
    component: CustomTextareaField,
  },
];
