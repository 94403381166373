import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { ITransferRequest } from '~/utils/types/requests.types';
import styles from './styles.module.scss';
import { Button } from '@material-ui/core';
import { useEntity } from 'icerockdev-admin-toolkit';
import { ServiceRequestEntity } from '../ServiceRequestEntity';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '~/components/icons/CloseIcon';
import {
  ServiceRequestDynamicFields,
  ServiceRequestStatus,
  TransferRequestStatus,
} from '~/utils/constants/requests.constants';

interface IProps {
  id: string;
  data: Record<string, any>;
  listTransferRequests: ITransferRequest[];
  isLoading: boolean;
  isAdmin: boolean;
}

const HeadButtons: FC<IProps> = observer(({ id, data, listTransferRequests, isLoading, isAdmin }) => {
  const isPaid = data.status === ServiceRequestStatus.Paid;
  const isWaitPayment = data.status === ServiceRequestStatus.WaitPayment;
  const approvedTransferRequests = listTransferRequests.filter(
    ({ status }) => status === TransferRequestStatus.ApprovedByUser,
  );
  const isCanPaid = isWaitPayment && approvedTransferRequests.length !== 0;
  const isCanDecline = data.status !== ServiceRequestStatus.Declined;

  const entity = useEntity<ServiceRequestEntity>();
  const history = useHistory();
  const { t } = useTranslation(['buttons', 'common']);

  const acceptTransferStatus = [
    TransferRequestStatus.ApprovedByAdmin,
    TransferRequestStatus.ApprovedByUser,
    TransferRequestStatus.Pending,
  ];

  const isTransfersNotRejected = !!entity.listTransferRequests
    .filter(item => item.serviceRequestId === Number(id))
    .filter(item => acceptTransferStatus.includes(item.status)).length;

  const [requestPointsModal, setRequestPointsModal] = useState<boolean>(false);
  const [pointsValue, setPointsValue] = useState<string>('');

  useEffect(() => {
    entity.fetchTransferRequests();
  }, [entity]);

  useEffect(() => {
    return () => {
      entity.isEditing = false;
    };
  }, [entity]);

  const onCloseServiceRequest = useCallback(() => {
    if (!window.confirm(t('common:Do you really want the request to be resolved?'))) return;
    entity.closeRequest(id);
    history.push('/service-requests');
  }, [entity, t, id, history]);

  const onPaidServiceRequest = useCallback(() => {
    if (!window.confirm(t('common:Do you really want the request to be paid?'))) return;
    entity.paidRequest(id);
  }, [entity, t, id]);

  const onDeclineServiceRequest = useCallback(() => {
    if (!window.confirm(t('common:Do you really want the request to be declined?'))) return;
    entity.declineRequest(id);
  }, [entity, t, id]);

  const handleChangePointsAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setPointsValue(event.target.value);
  };

  const handleModal = (): void => {
    setRequestPointsModal(prevState => !prevState);
  };

  const handleRequestPoints = useCallback(() => {
    if (!pointsValue) return;

    entity.requestTransferPoints(Number(id), pointsValue).then(() => {
      setPointsValue('');
      setRequestPointsModal(false);
    });
  }, [entity, id, pointsValue]);

  const handleEditing = useCallback(() => {
    entity.isEditing = !entity.isEditing;
  }, [entity]);

  const onSave = useCallback(() => {
    const fields = data.fields.map(item => ({
      fieldId: item.id,
      value:
        item.type === ServiceRequestDynamicFields.Radio
          ? item.extraData.find(field => field.value === item.value)?.id
          : item.value,
    }));

    const payload = {
      status: data.status,
      note: data.note,
      fields,
    };

    entity.updateServiceRequest(payload, data.id);
    entity.isEditing = false;
  }, [data]);

  if (isAdmin) {
    return (
      <div className={styles.wrap}>
        {entity.isEditing ? (
          <>
            <Button variant="contained" color="primary" className={styles.button} onClick={onSave}>
              {t('Save')}
            </Button>
            <Button variant="outlined" color="default" className={styles.button} onClick={handleEditing}>
              {t('Cancel')}
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              color="primary"
              className={styles.button}
              onClick={onDeclineServiceRequest}
              disabled={isLoading || !isCanDecline}
            >
              {t('Decline')}
            </Button>
            <Button
              variant="outlined"
              color="default"
              className={styles.button}
              onClick={onPaidServiceRequest}
              disabled={isLoading || !isCanPaid}
            >
              {t('Paid')}
            </Button>
            <Button variant="contained" color="primary" className={styles.button} onClick={handleEditing}>
              {t('Edit')}
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <div className={styles.wrap}>
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={handleModal}
          disabled={isLoading || isTransfersNotRejected}
          disableElevation
        >
          {t('Request a CP transfer')}
        </Button>
        <Button
          variant="outlined"
          color="default"
          className={styles.button}
          onClick={onCloseServiceRequest}
          disabled={isLoading || !isPaid}
        >
          {t('Done')}
        </Button>
      </div>

      <Dialog open={requestPointsModal} onClose={handleModal} aria-labelledby="form-dialog-title">
        <div className={styles.modal__wrap}>
          <div className={styles.modal__head}>
            <h2>{t('common:Request CP transfer')}</h2>
            <Button onClick={handleModal} className={styles.button__close}>
              <CloseIcon />
            </Button>
          </div>
          <TextField
            label={t('common:Amount of CP')}
            type="number"
            variant="outlined"
            value={pointsValue}
            onChange={handleChangePointsAmount}
            autoFocus
            fullWidth
          />
          <div className={styles.modal__foot}>
            <Button onClick={handleRequestPoints} variant="contained" color="primary">
              {t('Send')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
});

export { HeadButtons };
