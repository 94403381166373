import { IEntityCreateFunctionProps, IEntityCreateFunctionResult, IEntityGetFunction } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const blockCustomerItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.put(
    `${url}/block`,
    {},
    {
      headers: { authorization: token },
    },
  );

  return {
    data: result.data,
  };
};

export const deleteItemsFn: IEntityGetFunction = async ({ url, token }) => {
  const result = await axios.delete(`${url}`, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};
