import { IEntityField } from 'icerockdev-admin-toolkit';
import FullNameField from '~/pages/review/components/FullNameComponent';
import DescriptionComponent from '~/pages/review/components/DescriptionComponent';
import { ApprovedButton } from '~/pages/review/components/ApprovedButton';

export const REVIEW_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'Id',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Created at',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'number',
    name: 'shopId',
    label: 'Shop Id',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'author',
    label: 'Author',
    hideInEdit: true,
    component: FullNameField,
  },
  {
    type: 'number',
    name: 'rate',
    label: 'Rate',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'boolean',
    name: 'approved',
    label: 'Approved',
    hideInEdit: false,
    component: ApprovedButton,
  },
  {
    type: 'string',
    name: 'description',
    label: 'Description',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
    component: DescriptionComponent,
  },
];
