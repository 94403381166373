import {
  ImagesType,
  IItemData,
  IShopThumbnail,
  ProductsType,
  FieldsEnum,
  CreateShopRequest,
  IUrlItem,
} from '~/utils/types/shops.types';
import { CategoryType } from '~/utils/types/category.types';
import { useTranslation } from 'react-i18next';

export const finalShopThumbnailsData = (shopThumbnailsData: Map<string, IShopThumbnail>) => {
  const images: ImagesType[] = [];
  shopThumbnailsData.forEach(item => {
    if (item.imageKey) {
      images.push({ description: item.value.trim(), imageKey: item.imageKey });
    }
  });
  return images;
};

export const finalItemData = (productData: Map<string, Omit<IItemData, 'id'>>) => {
  const products: ProductsType[] = [];
  productData.forEach(product => {
    products.push({
      description: product.description,
      imageKey: product.imageKey,
      name: product.name,
      isHide: product.checkbox,
    });
  });

  return products;
};

export const noPublicFinalData = (data: Map<string, any>) => {
  const finalObj = {};
  const fields = data.keys();
  for (const field of fields) {
    finalObj[field] = data.get(field)?.value;
  }
  return finalObj;
};

export const finalCategoryData = (category: Map<string, CategoryType>): number[] => {
  const categories: number[] = [];
  category.forEach(category => {
    categories.push(Number(category.value));
  });
  return categories;
};

export const finalUrlArray = (url: Map<string, Omit<IUrlItem, 'id'>>): Omit<IUrlItem, 'id'>[] => {
  const urls: Omit<IUrlItem, 'id'>[] = [];
  url.forEach(url => {
    urls.push(url);
  });
  return urls;
};

export const createNewItemObject = (data): any => {
  return {
    imageBase64: '',
    imageKey: data.imageKey,
    name: data.name,
    description: data.description,
    checkbox: data.checkbox,
    imageUrl: data.imageUrl,
  };
};

export const createMapData = <T extends { field: string }>(data: T[]): Map<string, Omit<T, 'field'>> => {
  return new Map(data.map(({ field, ...rest }) => [field, rest]));
};

export const placeHolderText = (text: string, isEditView?) => {
  const { t } = useTranslation();
  if (isEditView) {
    return '';
  }
  if (text.includes(':')) {
    return t(text);
  } else {
    return text;
  }
};

export const isThereSomethingAfterSlashFunction = (str: string): boolean => {
  // Checks is there any number or not after slash(/). E.g: shop/1 or shop/1/ - true, shop/ - false
  const pattern = /\/\d+\/?/;
  return pattern.test(str);
};

export const finalShopData = (data: CreateShopRequest) => {
  return {
    ...data,
    shop: {
      [FieldsEnum.NAME]: data.shop[FieldsEnum.NAME],
      [FieldsEnum.IMAGES]: data.shop[FieldsEnum.IMAGES],
      [FieldsEnum.SHORT_DESCRIPTION]: data.shop[FieldsEnum.SHORT_DESCRIPTION] || null,
      [FieldsEnum.DESCRIPTION]: data.shop[FieldsEnum.DESCRIPTION] || null,
      [FieldsEnum.REFERENCE_PRICE]: data.shop[FieldsEnum.REFERENCE_PRICE] || null,
      [FieldsEnum.ACCESS]: data.shop[FieldsEnum.ACCESS] || null,
      [FieldsEnum.IS_HIDE]: Boolean(Number(data.shop[FieldsEnum.IS_HIDE])) || false,
      [FieldsEnum.BUDGET]: data.shop[FieldsEnum.BUDGET] || null,
      [FieldsEnum.POST_CODE]: data.shop[FieldsEnum.POST_CODE] || null,
      [FieldsEnum.PREFECTURE]: data.shop[FieldsEnum.PREFECTURE] || null,
      [FieldsEnum.SHOP_CONTACT_PERSON]: data.shop[FieldsEnum.SHOP_CONTACT_PERSON] || null,
      [FieldsEnum.MEMBERSHIP_BENEFITS]: data.shop[FieldsEnum.MEMBERSHIP_BENEFITS] || null,
      [FieldsEnum.SHOP_ATMOSPHERE]: data.shop[FieldsEnum.SHOP_ATMOSPHERE] || null,
      [FieldsEnum.OUTLINE_SHOP_ATMOSPHERE]: data.shop[FieldsEnum.OUTLINE_SHOP_ATMOSPHERE] || null,
      [FieldsEnum.PRODUCT_DESCRIPTION]: data.shop[FieldsEnum.PRODUCT_DESCRIPTION] || null,
      [FieldsEnum.PRICE_DESCRIPTION]: data.shop[FieldsEnum.PRICE_DESCRIPTION] || null,
      [FieldsEnum.ADDRESS]: data.shop[FieldsEnum.ADDRESS] || null,
      [FieldsEnum.COORDINATE]: data.shop[FieldsEnum.COORDINATE],
      [FieldsEnum.PHONE]: data.shop[FieldsEnum.PHONE] || null,
      [FieldsEnum.BUSINESS_HOURS]: data.shop[FieldsEnum.BUSINESS_HOURS] || null,
      [FieldsEnum.WEBSITE]: data.shop[FieldsEnum.WEBSITE] || null,
      [FieldsEnum.STORE_REPRESENTATIVE_NAME]: data.shop[FieldsEnum.STORE_REPRESENTATIVE_NAME] || null,
      [FieldsEnum.TAG]: data.shop[FieldsEnum.TAG] || null,
      [FieldsEnum.SALES_PLANNER_ID]: Number(data.shop[FieldsEnum.SALES_PLANNER_ID]),
      [FieldsEnum.SITE_PLANNER_A_ID]: Number(data.shop[FieldsEnum.SITE_PLANNER_A_ID]) || null,
      [FieldsEnum.SITE_PLANNER_B_ID]: Number(data.shop[FieldsEnum.SITE_PLANNER_B_ID]) || null,
      [FieldsEnum.AFFILIATE_PLANNER_ID]: Number(data.shop[FieldsEnum.AFFILIATE_PLANNER_ID]) || null,
      [FieldsEnum.BUSINESS_OWNER_ID]: Number(data.shop[FieldsEnum.BUSINESS_OWNER_ID]) || null,
      [FieldsEnum.BUSINESS_ID]: Number(data.shop[FieldsEnum.BUSINESS_ID]) || null,
      [FieldsEnum.BUSINESS_NAME]: data.shop[FieldsEnum.BUSINESS_NAME] || null,
      [FieldsEnum.ESTABLISHMENT_DATE]: data.shop[FieldsEnum.ESTABLISHMENT_DATE],
      [FieldsEnum.SHOP_VISIT_DATE]: data.shop[FieldsEnum.SHOP_VISIT_DATE] || null,
      [FieldsEnum.CAPITAL]: data.shop[FieldsEnum.CAPITAL] || null,
      [FieldsEnum.COMPANY_NAME]: data.shop[FieldsEnum.COMPANY_NAME],
      [FieldsEnum.COMPANY_NAME_KANA]: data.shop[FieldsEnum.COMPANY_NAME_KANA],
      [FieldsEnum.SHOP_NAME]: data.shop[FieldsEnum.SHOP_NAME],
      [FieldsEnum.SHOP_NAME_KANA]: data.shop[FieldsEnum.SHOP_NAME_KANA],
      [FieldsEnum.SHOP_REPRESENTATIVE_NAME]: data.shop[FieldsEnum.SHOP_REPRESENTATIVE_NAME],
      [FieldsEnum.SHOP_REPRESENTATIVE_NAME_KANA]: data.shop[FieldsEnum.SHOP_REPRESENTATIVE_NAME_KANA],
      [FieldsEnum.SHOP_REPRESENTATIVE_GENDER]: Number(data.shop[FieldsEnum.SHOP_REPRESENTATIVE_GENDER]) ?? null,
      [FieldsEnum.SHOP_ADDRESS]: data.shop[FieldsEnum.SHOP_ADDRESS] || null,
      [FieldsEnum.FAX]: data.shop[FieldsEnum.FAX] || null,
      [FieldsEnum.SHOP_MANAGER]: data.shop[FieldsEnum.SHOP_MANAGER] || null,
      [FieldsEnum.SHOP_MANAGER_KANA]: data.shop[FieldsEnum.SHOP_MANAGER_KANA] || null,
      [FieldsEnum.BUSINESS_CATEGORY]: data.shop[FieldsEnum.BUSINESS_CATEGORY] || null,
      [FieldsEnum.BRANCH]: data.shop[FieldsEnum.BRANCH] || null,
      [FieldsEnum.MULTIPLE_SHOPS]: Boolean(Number(data.shop[FieldsEnum.MULTIPLE_SHOPS])) || false,
      [FieldsEnum.MULTIPLE_BRANCHES]: Boolean(Number(data.shop[FieldsEnum.MULTIPLE_BRANCHES])) || false,
      [FieldsEnum.IS_PARTNER_ALLOWED]: Boolean(Number(data.shop[FieldsEnum.IS_PARTNER_ALLOWED])) || false,
      [FieldsEnum.RECOMMENDATION_REASON]: data.shop[FieldsEnum.RECOMMENDATION_REASON] || null,
      [FieldsEnum.BUSINESS_DESCRIPTION]: data.shop[FieldsEnum.BUSINESS_DESCRIPTION] || null,
      [FieldsEnum.BANK_NAME]: data.shop[FieldsEnum.BANK_NAME],
      [FieldsEnum.BANK_CODE]: data.shop[FieldsEnum.BANK_CODE],
      [FieldsEnum.BRANCH_NAME]: data.shop[FieldsEnum.BRANCH_NAME],
      [FieldsEnum.BRANCH_CODE]: data.shop[FieldsEnum.BRANCH_CODE],
      [FieldsEnum.BANK_TYPE]: Number(data.shop[FieldsEnum.BANK_TYPE]),
      [FieldsEnum.ACCOUNT_NUMBER]: data.shop[FieldsEnum.ACCOUNT_NUMBER],
      [FieldsEnum.ACCOUNT_HOLDER_NAME]: data.shop[FieldsEnum.ACCOUNT_HOLDER_NAME],
      [FieldsEnum.INDIVIDUAL_WITHDRAWAL_FEE]: data.shop[FieldsEnum.INDIVIDUAL_WITHDRAWAL_FEE] || null,
    },
  };
};
