import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ImageUploader from '~/components/common/ImageLoader';
import { v4 as uuidv4 } from 'uuid';
import { CustomInput } from '~/components/common/CustomInput';
import { CHARACTER_LIMIT } from '~/pages/shops/shops-constants';
import { IItemData } from '~/utils/types/shops.types';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  selectedItem: IItemData | null;
  addItem: (obj: any) => void;
  onUploadImage: (file: File) => Promise<string>;
  cleanSelectedItem: () => void;
  title: string;
}

const ModalContent: FC<IProps> = observer(({ addItem, selectedItem, cleanSelectedItem, onUploadImage, title }) => {
  const [item, setItem] = useState<IItemData>(
    selectedItem || {
      id: uuidv4(),
      imageBase64: '',
      imageKey: '',
      description: '',
      name: '',
      checkbox: false,
      imageUrl: '',
    },
  );

  useEffect(() => {
    if (selectedItem) {
      setItem(selectedItem);
    }
    return () => {
      cleanSelectedItem();
    };
  }, [selectedItem]);

  const { t } = useTranslation();

  const onSaveBase64 = (id: string, base64Value: string) => {
    setItem(prevState => ({ ...prevState, imageBase64: base64Value }));
  };

  const onUploadImageFunction = async (id: string, file: File): Promise<void> => {
    const imageKey: string = await onUploadImage(file);
    setItem(prevState => ({ ...prevState, imageKey: imageKey }));
  };

  const deleteImage = (id: string) => {
    setItem(prevState => ({ ...prevState, imageBase64: '', imageKey: '', imageUrl: '' }));
  };

  const onChangeInput = (event: any) => {
    event.persist();
    let editedString = event.target.value;
    if (editedString.length > CHARACTER_LIMIT[event.target.name]) {
      editedString = editedString.slice(0, CHARACTER_LIMIT[event.target.name]);
    }

    setItem(prevState => ({ ...prevState, [event.target.name]: editedString }));
  };

  const addItemFunction = () => {
    if (item.description && item.name && (item.imageKey || item.imageUrl)) {
      addItem(item);
    }
  };

  const toggleCheckbox = () => {
    setItem(prevState => ({ ...prevState, checkbox: !prevState.checkbox }));
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t(`fields:${title}`)}</h2>
      <div className={styles.imageContainer}>
        <ImageUploader
          location={`item-${item.id}`}
          id={item.id}
          imageData={item.imageBase64 || item.imageUrl}
          onSaveBase64={onSaveBase64}
          imagePxSize={112}
          onUploadImage={onUploadImageFunction}
          deleteImage={deleteImage}
        />
      </div>
      <CustomInput
        labelSeparate={t('fields:Name')}
        placeholder={'Shanon Hancock'}
        value={item.name}
        name="name"
        onChange={e => onChangeInput(e)}
        maxLength={CHARACTER_LIMIT.name}
      />
      <div className={styles.descriptionFieldContainer}>
        <CustomInput
          labelSeparate={t('fields:Description')}
          placeholder={'Shanon Hancock'}
          isMultiline={true}
          name="description"
          minTextareaHeight={'60px'}
          rows={4}
          value={item.description}
          onChange={e => onChangeInput(e)}
          maxLength={CHARACTER_LIMIT.description}
        />
      </div>
      <div className={styles.checkboxBox}>
        <Checkbox
          style={{ width: '18px', height: '18px' }}
          checked={item.checkbox}
          onChange={toggleCheckbox}
          color="primary"
        />
        <h3>{t('fields:Hide in front page')}</h3>
      </div>
      <Button
        className={styles.bottomButton}
        variant="contained"
        color="primary"
        type="button"
        disableElevation
        onClick={addItemFunction}
        disabled={!(item.imageKey || item.imageUrl) || !item.description || !item.name}
      >
        {t(`buttons:${selectedItem ? 'Save' : 'Add'}`)}
      </Button>
    </div>
  );
});

export { ModalContent };
