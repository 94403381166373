import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { action, computed, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { CustomEntityHead } from '~/components/entity/CustomEntityHead';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import { BlockContainer } from '~/pages/shops/components/BlockContainer';
import debounce from 'lodash.debounce';
import { API_ROUTES } from '~/utils/constants/api.constants';
import SearchBlock from '~/pages/reports/components/SearchBlock';
import { RangeDatePicker } from '~/components/common/RangeDatePicker';
import i18n from 'i18next';
import { CustomSelect } from '~/components/common/CustomSelect';
import styles from '~/pages/profile/components/ProfileHead/styles.module.scss';
import { Button } from '@material-ui/core';
import { DateType, IReportData, ShopOptionType } from '~/utils/types/reports.types';
import { fetchReportItem, fetchShopFn } from '~/pages/reports/api';
import SelectedReportBlock from '~/pages/reports/components/SelectedReportBlock';

class ReportEntity extends Entity {
  @observable dateValue: DateType = [null, null];
  @observable shopOptions: ShopOptionType[] = [];
  @observable shopId: number | null = null;
  @observable shopInputValue: string = '';
  @observable selectedData: IReportData | null = null;
  @observable reportLoading: boolean = false;

  constructor(fields: Partial<Entity>) {
    super();
    if (fields) {
      Object.assign(this, fields);
    }

    const debouncedShopFetch = debounce(this.fetchShopsData, 500);
    reaction(() => [this.shopInputValue], debouncedShopFetch);
  }

  @action
  onChangeShopInputField = (value: string) => {
    this.shopInputValue = value;
  };

  @action
  onMount = () => {
    this.fetchItems();
    this.fetchShopsData();
  };

  @action
  fetchShopsData = async () => {
    this.reportLoading = true;
    try {
      this.shopOptions = await this.parent?.auth?.withToken(fetchShopFn, {
        url: API_ROUTES.shop,
        params: { name: this.shopInputValue || '' },
      });
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    } finally {
      this.reportLoading = false;
    }
  };

  @action
  async fetchSelectedReport() {
    this.reportLoading = true;
    const [dateStart, dateEnd] = this.dateValue;
    try {
      this.selectedData = await this.parent?.auth?.withToken(fetchReportItem, {
        url: API_ROUTES.reportsWithParams,
        params: {
          shopId: this.shopId,
          dateStart: (dateStart && new Date(dateStart).toISOString()) || '',
          dateEnd: (dateEnd && new Date(dateEnd).toISOString()) || '',
        },
      });
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    } finally {
      this.reportLoading = false;
    }
  }

  @action
  fetchReportByData = async () => {
    await this.fetchSelectedReport();
  };

  @action
  onChangeDateValue = (date: DateType) => {
    this.dateValue = date;
  };

  @action
  onChangeShopValue = (shopData: ShopOptionType) => {
    this.shopId = shopData.value;
  };

  @computed
  get SearchListBlock() {
    return observer(() => (
      <BlockContainer headerTitle={'fields:Particular shop'}>
        <SearchBlock>
          <RangeDatePicker
            labelSeparate={i18n.t('fields:Time period')}
            value={this.dateValue}
            onChange={this.onChangeDateValue}
          />
          <CustomSelect
            options={this.shopOptions}
            labelSeparate={i18n.t('fields:Shop Name')}
            placeholder={i18n.t('fields:Select Shop')}
            onChange={this.onChangeShopValue}
            onInputChange={this.onChangeShopInputField}
            required={true}
          />
          <Button
            style={{ height: '56px' }}
            variant="outlined"
            color="primary"
            className={styles.button}
            onClick={this.fetchReportByData}
            disabled={this.reportLoading || !this.shopId}
          >
            {i18n.t('buttons:Show report')}
          </Button>
        </SearchBlock>
        {this.selectedData && <SelectedReportBlock data={this.selectedData} />}
      </BlockContainer>
    ));
  }

  @computed
  get List() {
    return observer(() => (
      <>
        <this.ListHead />
        <this.SearchListBlock />
        <BlockContainer headerTitle={'fields:All shops'} style={{ padding: 0 }}>
          <this.ListBody />
          <this.ListFooter />
        </BlockContainer>
      </>
    ));
  }

  @computed
  get ListHead() {
    return observer(() => (
      <>
        <CustomEntityHead
          filterData={this.filterData}
          title={<this.ListHeadTitle />}
          buttons={<this.ListHeadButtons />}
          filters={this.filters}
          fields={this.fields}
          setFilters={this.setFilters}
          url={this.menu.url}
          applyFilter={this.applyFilter}
          withToken={this.parent?.auth?.withToken}
          onExport={this.exportData}
          canExport={this.exportable && this.canExport}
          canCreate={this.creatable && this.canCreate}
          entity={this}
        />
      </>
    ));
  }

  @computed
  get ListBody() {
    return observer(() => {
      return (
        <>
          <CustomEntityList
            fields={this.fields}
            data={this.data}
            extra={this.ListExtra}
            isLoading={this.isLoading}
            url={this.menu.url}
            selected={this.selected}
            sortBy={this.sortBy}
            sortDir={this.sortDir}
            canView={this.viewable && this.canView}
            canEdit={this.editable && this.canEdit}
            canSelect={this.selectable}
            setSelected={this.setSelected}
            onSortChange={this.setSort}
            withToken={this.parent?.auth?.withToken}
            entity={this}
          />
        </>
      );
    });
  }
}

export { ReportEntity };
