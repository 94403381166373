import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { CustomEntityHead } from '~/components/entity/CustomEntityHead';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import { HeadButtons } from '../HeadButtons';
import { fetchWihdrawRequests, updateWithdrawRequest } from '../../api';
import { WithdrawRequestStatus } from '~/utils/constants/requests.constants';
import Axios from 'axios';
import { UserRole } from '~/utils/constants/roles.constants';
import { getErrorMessage } from '~/utils/axios';

class WhitdrawRequestEntity extends Entity {
  @observable isLoading: boolean = false;
  @observable data: Record<string, string>[] = [];

  @action
  updateRequest = async (withdrawId: string[], status: string, reason?: string) => {
    if (!this.parent?.auth?.withToken) return;

    const withToken = this.parent.auth.withToken;

    await Axios.all(
      withdrawId.map(id =>
        withToken(updateWithdrawRequest, {
          url: `${this?.api?.update.url}/${id}`,
          data: {
            status,
            reason,
          },
        }),
      ),
    ).catch(err => this.parent?.notifications.showError(err.message));

    this.fetchItems();
  };

  @action
  onSearchWithdrawRequest = async (query?: string) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    try {
      const result = await this.parent.auth.withToken(fetchWihdrawRequests, {
        url: this.api?.list.url,
        filter: [{ name: 'query', value: query }],
      });

      this.data = result?.data?.list;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isLoading = false;
  };

  @computed
  get ListHead() {
    return observer(() => (
      <CustomEntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListBody() {
    return observer(() => {
      const allowedRoles = [UserRole.Admin, UserRole.SuperAdmin];
      const businessFields = ['createdAt', 'pointsType', 'amount', 'status'];
      const filteredFields = this.fields.filter(item => businessFields.includes(item.name));
      const fields = allowedRoles.includes(this.parent?.auth?.userRole as UserRole) ? this.fields : filteredFields;

      return (
        <CustomEntityList
          fields={fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          disabledIfFieldName={'status'}
          disabledIfKeysName={[WithdrawRequestStatus.Declined, WithdrawRequestStatus.Cashout]}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable && this.canView}
          canEdit={this.editable && this.canEdit}
          canSelect={this.permissions?.update?.includes(this.parent?.auth?.userRole as UserRole)}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }

  @computed
  get ListHeadButtons() {
    return observer(() => {
      return <HeadButtons entity={this} data={this.data} isLoading={this.isLoading} />;
    });
  }
}

export { WhitdrawRequestEntity };
