import * as React from 'react';
import { SVGProps } from 'react';

const PinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <linearGradient id="a" x1={11.274} x2={36.726} y1={9.271} y2={34.723} gradientUnits="userSpaceOnUse">
      <stop offset={0} stopColor="#d43a02" />
      <stop offset={1} stopColor="#b9360c" />
    </linearGradient>
    <path
      fill="url(#a)"
      d="M36.902 34.536C40.052 31.294 42 26.877 42 22c0-9.94-8.06-18-18-18S6 12.06 6 22c0 4.877 1.948 9.294 5.098 12.536.018.019.03.04.048.059l.059.059.142.142 11.239 11.239a2 2 0 0 0 2.828 0l11.239-11.239.142-.142.059-.059c.019-.019.031-.041.048-.059z"
    />
    <radialGradient id="b" cx={24} cy={22.5} r={9.5} gradientUnits="userSpaceOnUse">
      <stop offset={0.177} />
      <stop offset={1} stopOpacity={0} />
    </radialGradient>
    <circle cx={24} cy={22.5} r={9.5} fill="url(#b)" />
    <circle cx={24} cy={22} r={8} fill="#f9f9f9" />
    <radialGradient id="c" cx={23.842} cy={43.905} r={13.637} gradientUnits="userSpaceOnUse">
      <stop offset={0.177} />
      <stop offset={1} stopOpacity={0} />
    </radialGradient>
    <path
      fill="url(#c)"
      d="M24 30c-4.747 0-8.935 2.368-11.467 5.982l10.052 10.052a2 2 0 0 0 2.828 0l10.052-10.052C32.935 32.368 28.747 30 24 30z"
    />
    <path
      fill="#de490d"
      d="M24 32c-4.196 0-7.884 2.157-10.029 5.42l8.615 8.615a2 2 0 0 0 2.828 0l8.615-8.615C31.884 34.157 28.196 32 24 32z"
    />
  </svg>
);

export default PinIcon;
