import { Config } from 'icerockdev-admin-toolkit';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { DEFAULT_THEME } from '~/config/theme';
import auth from '../auth';
import users from '../pages/users';
import { MainLayout } from '~/layouts';
import serviceRequests from '~/pages/service-requests';
import withdrawRequest from '~/pages/withdraw-request';
import profile from '~/pages/profile';
import qrCodes from '~/pages/qr-codes';
import transactions from '~/pages/transactions';
import exchangeRequests from '~/pages/exchange-requests';
import transferRequest from '~/pages/transfer-request';
import configuration from '~/pages/configuration';
import bankTransfers from '~/pages/bank-transfers';
import shops from '~/pages/shops';
import category from '~/pages/category';
import reports from '~/pages/reports';
import review from '~/pages/review';
import information from '~/pages/information';

export default new Config({
  host: process.env.REACT_APP_BASE_URL,
  logo: '/images/logo.svg',
  auth: auth,
  pages: [
    users,
    serviceRequests,
    withdrawRequest,
    exchangeRequests,
    transferRequest,
    qrCodes,
    transactions,
    profile,
    bankTransfers,
    configuration,
    shops,
    category,
    reports,
    review,
    information,
  ],
  i18nDefaultLanguage: 'en',
  i18nLanguages: ['en', 'ja'],
  i18nUseBrowserLanguageDetector: true,
  theme: createMuiTheme(DEFAULT_THEME),
  layout: MainLayout,
});
