import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { USER_ROLE_NAMES } from '~/utils/constants/roles.constants';
import { FormattedDate } from '~/components/common/FormattedDate';
import { ServiceRequestButton } from '~/pages/transfer-request/components/ServiceRequestButton';
import { EditingAmount } from './components/EditingAmount';
import { ColoredStatus } from '~/components/common/ColoredStatus';
import {
  TRANSFER_REQUEST_STATUS_NAMES,
  TRANSFER_REQUEST_STATUS_NAME_COLORS,
} from '~/utils/constants/requests.constants';

export const TRANSFER_REQUEST_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'createdAt',
    label: 'Created date',
    sortable: true,
    hideInCreate: true,
    component: props => <FormattedDate {...props} withTime />,
  },
  {
    type: 'string',
    name: 'userName',
    label: 'User',
    hideInCreate: true,
  },
  {
    type: 'select',
    name: 'role',
    label: 'Role',
    options: USER_ROLE_NAMES,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'clientId',
    label: 'Member ID',
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: 'amount',
    label: 'Transfer amount',
    component: EditingAmount,
  },
  {
    type: 'custom',
    name: 'serviceRequestId',
    label: ' ',
    component: ServiceRequestButton,
    hideInCreate: true,
    hideInView: true,
  },
  {
    type: 'custom',
    name: 'status',
    label: 'Status',
    component: props => (
      <ColoredStatus
        {...props}
        statusNames={TRANSFER_REQUEST_STATUS_NAMES}
        statusNameColors={TRANSFER_REQUEST_STATUS_NAME_COLORS}
        rejectReason={{
          rejectStatusName: TRANSFER_REQUEST_STATUS_NAMES.REJECTED,
          reasonFieldName: 'rejectReason',
        }}
      />
    ),
    hideInCreate: true,
  },
];
