import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '~/components/icons/CloseIcon';
import { WhitdrawRequestEntity } from '../WhitdrawRequestEntity';
import { WithdrawRequestStatus } from '~/utils/constants/requests.constants';
import { UserRole } from '~/utils/constants/roles.constants';
import useDebounce from '~/utils/hooks/useDebounce';
import useOnChange from '~/utils/hooks/useOnChange';

interface IProps {
  isLoading: boolean;
  data: Record<string, string>[];
  entity: WhitdrawRequestEntity;
}

const HeadButtons: FC<IProps> = observer(({ data, entity, isLoading }) => {
  const { t } = useTranslation();
  const selectedRequests = entity.selected;

  const selectedEntity = selectedRequests.map(id => data.find(entity => id === entity.id));
  const isNotApproved = selectedEntity.some(entity => entity?.status !== WithdrawRequestStatus.Approved);
  const isNotPending = selectedEntity.some(entity => entity?.status !== WithdrawRequestStatus.Pending);

  const isShow = entity.parent?.auth?.userRole !== UserRole.Business;

  const [rejectionReasonModal, setRejectionReasonModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const [searchValue, setSearchValue] = useState<string>('');

  const searchQueryThrottle: string = useDebounce(searchValue, 500);

  const onSearchWithdrawRequestThrottleChange = useCallback(() => {
    entity.onSearchWithdrawRequest(searchQueryThrottle);
  }, [entity, searchQueryThrottle]);

  useOnChange(onSearchWithdrawRequestThrottleChange, searchQueryThrottle);

  const onChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleModal = (): void => {
    setRejectionReasonModal(prevState => !prevState);
  };

  const handleApprove = useCallback(() => {
    if (!window.confirm(t('messages:Do you really want the withdraw request to be approved?'))) return;
    entity.updateRequest(selectedRequests, WithdrawRequestStatus.Approved);
  }, [entity, selectedRequests, t]);

  const handleCashOut = useCallback(() => {
    if (!window.confirm(t('messages:Do you really want the withdraw request to be cash out?'))) return;
    entity.updateRequest(selectedRequests, WithdrawRequestStatus.Cashout);
  }, [entity, selectedRequests, t]);

  const handleRejectionReason = useCallback(() => {
    if (!comment) return;
    entity.updateRequest(selectedRequests, WithdrawRequestStatus.Declined, comment);
    setComment('');
    setRejectionReasonModal(false);
  }, [comment, entity, selectedRequests]);

  if (!isShow) {
    return null;
  }

  return (
    <>
      <div className={styles.wrap}>
        <TextField
          variant="outlined"
          placeholder={t('common:Search requests by user name, memberId')}
          className={styles.search__input}
          onChange={e => setSearchValue(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          disableElevation
          onClick={handleApprove}
          disabled={isLoading || !selectedRequests.length || isNotPending}
        >
          {t('buttons:Approve')}
        </Button>
        <Button
          variant="outlined"
          color="default"
          className={styles.button}
          onClick={handleModal}
          disabled={isLoading || !selectedRequests.length || isNotPending}
        >
          {t('buttons:Reject')}
        </Button>
        <Button
          variant="outlined"
          color="default"
          className={styles.button}
          onClick={handleCashOut}
          disabled={isLoading || !selectedRequests.length || isNotApproved}
        >
          {t('buttons:Cash out')}
        </Button>
      </div>

      <Dialog open={rejectionReasonModal} onClose={handleModal} aria-labelledby="form-dialog-title">
        <div className={styles.modal__wrap}>
          <div className={styles.modal__head}>
            <h2>{t('common:Rejection reason')}</h2>
            <Button onClick={handleModal} className={styles.button__close}>
              <CloseIcon />
            </Button>
          </div>
          <TextField
            label={t('common:Comment')}
            variant="outlined"
            value={comment}
            onChange={onChangeComment}
            rows={3}
            multiline
            autoFocus
            fullWidth
          />
          <div className={styles.modal__foot}>
            <Button onClick={handleRejectionReason} variant="contained" color="primary">
              {t('buttons:Send')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
});

export { HeadButtons };
