import React, { useCallback } from 'react';
import {
  CircularProgress,
  Paper,
  withStyles,
  WithStyles,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import Switch from 'react-switch';
import styles from './styles';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ConfigurationField, ConfigurationGroup } from '../ConfigurationEntity';
import {
  CONFIGURATION_FIELD_NAME,
  CONFIGURATION_GROUP_NAME,
  CONFIGURATION_PAYMENT_SYSTEM_NAME,
  CONFIGURATION_SWITCHER_NAME,
  ConfigurationFieldKey,
  ConfigurationSwitcherKey,
} from '~/utils/constants/configuration.constants';

type IProps = WithStyles<typeof styles> & {
  url?: string;
  id?: string;
  isEditing?: boolean;
  isLoading?: boolean;
  data: ConfigurationGroup[];
  creditCardSwitcher: boolean;
  btcPaySwitcher: boolean;
  bankTransferSwitcher: boolean;
  isSwitcherLoading: boolean;
  viewable?: boolean;

  setEditorData: (data: ConfigurationGroup[]) => void;
  getItem?: (id: any) => void;
  withToken?: (req: any, args: any) => any;
  onUpdateSwitcher: (id: string, value: string) => void;
};

const INPUTS_TYPE_NUMBER = [
  ConfigurationFieldKey.CYBERSOURCE_MINIMAL_BUY_CP_AMOUNT,
  ConfigurationFieldKey.VIRTUALPAY_MINIMAL_BUY_CP_AMOUNT,
  ConfigurationFieldKey.BTC_PAY_MINIMAL_BUY_CP_AMOUNT,
  ConfigurationFieldKey.CP_MINIMAL_AMOUNT_TO_BANK_TRANSFER,
  ConfigurationFieldKey.CP_TO_JPY_CONVERSION_RATE,
  ConfigurationFieldKey.SP_MINIMAL_AMOUNT_TO_WITHDRAW,
];

const INPUTS_TYPE_TEXTAREA = [ConfigurationFieldKey.VIRTUALPAY_PRIVATE_KEY, ConfigurationFieldKey.BUSINESS_REQUISITES];

const ConfigurationViewer = withStyles(styles)(
  observer(
    ({
      classes,
      id,
      isLoading,
      data,
      creditCardSwitcher,
      btcPaySwitcher,
      bankTransferSwitcher,
      isSwitcherLoading,
      setEditorData,
      withToken,
      isEditing,
      onUpdateSwitcher,
    }: IProps) => {
      const { t } = useTranslation();

      const onFieldChange = useCallback(
        (value: string, item: ConfigurationGroup, field: ConfigurationField) => {
          const changedData = data.map(group =>
            group.category === item.category
              ? { ...group, settings: group.settings.map(x => (x.name === field.name ? { ...x, value } : x)) }
              : group,
          );

          setEditorData(changedData);
        },
        [data, setEditorData],
      );

      if (isLoading) {
        return (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        );
      }

      return (
        <div className={classes.wrap}>
          {data &&
            data.map(item => (
              <div key={item.category}>
                <div className={classes.header__wrap}>
                  <h2>{t(`common:${CONFIGURATION_GROUP_NAME[item.category]}`)}</h2>
                  {item.settings
                    .filter(field => Object.keys(ConfigurationSwitcherKey).includes(field.id))
                    .map(field => {
                      const checkedSwitch = (id: string) => {
                        switch (id) {
                          case ConfigurationFieldKey.CREDIT_CARD_SWITCHER: {
                            return creditCardSwitcher;
                          }
                          case ConfigurationFieldKey.BTC_PAY_SWITCHER: {
                            return btcPaySwitcher;
                          }
                          case ConfigurationFieldKey.BANK_TRANSFER_SWITCHER: {
                            return bankTransferSwitcher;
                          }
                          default: {
                            return false;
                          }
                        }
                      };

                      return (
                        <div key={field.id} className="switcher__wrap">
                          {t(`fields:${CONFIGURATION_SWITCHER_NAME[field.name]}`)}
                          <Switch
                            onChange={checked => onUpdateSwitcher(field.id, String(checked))}
                            checked={checkedSwitch(field.id)}
                            disabled={isSwitcherLoading}
                          />
                        </div>
                      );
                    })}
                </div>
                <Paper>
                  <div className={classes.grid} style={{ flexWrap: 'wrap' }}>
                    {item.settings
                      .filter(field => !Object.keys(ConfigurationSwitcherKey).includes(field.id))
                      .map(field => (
                        <div className={classes.field__wrap} key={field.id}>
                          {!isEditing ? (
                            <>
                              <div className="label">{t(`fields:${CONFIGURATION_FIELD_NAME[field.name]}`)}</div>
                              <div className="fieldText">{field.value}</div>
                            </>
                          ) : (
                            <div className="field">
                              {field.id === ConfigurationFieldKey.CREDIT_CARD_PAYMENT_SYSTEM ? (
                                <FormControl variant="outlined">
                                  <InputLabel id="payment-system-label">
                                    {t(`fields:${CONFIGURATION_FIELD_NAME[field.name]}`)}
                                  </InputLabel>
                                  <Select
                                    labelId="payment-system-label"
                                    id="payment-system-select"
                                    value={field.value}
                                    label={t(`fields:${CONFIGURATION_FIELD_NAME[field.name]}`)}
                                    onChange={e => onFieldChange(e.target.value as string, item, field)}
                                  >
                                    {Object.keys(CONFIGURATION_PAYMENT_SYSTEM_NAME).map(name => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                <TextField
                                  variant="outlined"
                                  label={t(`fields:${CONFIGURATION_FIELD_NAME[field.name]}`)}
                                  value={field.value}
                                  type={INPUTS_TYPE_NUMBER.includes(field.id) ? 'number' : 'string'}
                                  onChange={e => onFieldChange(e.target.value, item, field)}
                                  multiline={INPUTS_TYPE_TEXTAREA.includes(field.id)}
                                  rowsMax={6}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </Paper>
              </div>
            ))}
        </div>
      );
    },
  ),
);

export { ConfigurationViewer };
