import { createItemsFn, fetchItemsFn, getItemsFn, updateItemsFn } from '~/utils/api';
import { ShopRole, UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';
import ReviewEntity from '~/pages/review/Entity';
import { REVIEW_FIELDS } from '~/pages/review/fields';

export default new ReviewEntity({
  title: 'Review',
  viewable: true,
  editable: false,
  creatable: false,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.reviewList, method: 'get' },
    get: { url: API_ROUTES.review, method: 'get' },
    create: { url: API_ROUTES.review, method: 'post' },
    update: { url: API_ROUTES.review, method: 'put' },
    delete: { url: API_ROUTES.review, method: 'delete' },
  },
  menu: {
    label: 'Review',
    url: '/review',
    enabled: true,
  },
  fields: REVIEW_FIELDS,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
  createItemsFn,
  roles: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business, ShopRole.Site_Planner_A],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business, ShopRole.Site_Planner_A],
  },
});
