import React, { FC } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import styles from './styles.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { IUrlItem, UrlActionsEnum } from '~/utils/types/shops.types';

interface IProps {
  id: string;
  title: string;
  link: string;
  isHide: boolean;
  onEditClick: (id: string) => void;
  onDeleteClick: (action: UrlActionsEnum, item: IUrlItem) => void;
  isViewPage?: boolean;
  onChangeCheckbox: (id: string) => void;
}

const TableRowLink: FC<IProps> = ({
  id,
  onEditClick,
  onDeleteClick,
  isViewPage,
  onChangeCheckbox,
  title,
  isHide,
  link,
}) => {
  return (
    <tr className={styles.row}>
      <td>{title}</td>
      <td>{link}</td>
      <td>
        <Checkbox
          style={{ width: '18px', height: '18px' }}
          checked={isHide}
          color="primary"
          disabled={isViewPage}
          onChange={() => onChangeCheckbox(id)}
        />
      </td>
      <td className={styles.icons}>
        {!isViewPage && (
          <IconButton
            aria-label="delete"
            style={{ width: '30px', height: '30px', padding: '0px' }}
            onClick={() => onEditClick(id)}
          >
            <EditIcon />
          </IconButton>
        )}
      </td>
      <td>
        {!isViewPage && (
          <IconButton
            aria-label="delete"
            style={{ width: '30px', height: '30px', padding: '0px' }}
            onClick={() => onDeleteClick(UrlActionsEnum.DELETE, { isHide, id, title, link })}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </td>
    </tr>
  );
};

export { TableRowLink };
