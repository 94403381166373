import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import i18n from 'i18next';

import { CustomEntityHead } from '~/components/entity/CustomEntityHead';
import {
  blockCustomerItem,
  deleteItemFn,
  replenishDeposit,
  unBlockCustomerItem,
  uploadFileFn,
} from '~/pages/users/api';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import { HeadButtons } from '../HeadButtons';
import { EntityViewer } from '../EntityViewer';
import { getErrorMessage } from '~/utils/axios';
import { ListHeadButtons } from '../ListHeadButtons';
import { fetchItemsFn } from '~/utils/api';

class UserEntity extends Entity {
  @observable isImportUsersLoading: boolean = false;
  @observable isLoading: boolean = false;
  @observable data: Record<string, string>[] = [];

  @action
  blockItem = async (id: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    try {
      await this.parent.auth.withToken(blockCustomerItem, {
        url: `${this?.api?.update.url}/${id}`,
        data: this.editorData,
      });
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
    }
    this.getItem(id);

    this.isLoading = false;
  };

  @action
  unBlockItem = async (id: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    try {
      await this.parent.auth.withToken(unBlockCustomerItem, {
        url: `${this?.api?.update.url}/${id}`,
        data: this.editorData,
      });
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
    }
    this.getItem(id);

    this.isLoading = false;
  };

  @action
  addPoints = async (userId: string, amount: string, currency: string) => {
    if (!this.parent?.auth?.withToken) return;

    try {
      await this.parent.auth.withToken(replenishDeposit, {
        url: `${this?.api?.create.url}/${userId}/deposit`,
        data: {
          currency,
          amount: Number(amount),
        },
      });

      this.getItem(userId);
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
    }
  };

  @action
  onImportUsers = async (file: File): Promise<string> => {
    this.isImportUsersLoading = true;
    try {
      const result = await this.parent?.auth?.withToken(uploadFileFn, {
        url: this.api?.importUsers.url,
        file,
      });
      this.parent?.notifications.showSuccess(
        i18n.t('messages:Users are loading, after loading you will be notified by email'),
      );
      return result;
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
    } finally {
      this.isImportUsersLoading = false;
    }
    return '';
  };

  @action
  onSearchUsers = async (query?: string) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    try {
      const result = await this.parent.auth.withToken(fetchItemsFn, {
        url: this.api?.list.url,
        filter: [{ name: 'query', value: query }],
      });

      this.data = result?.data?.list;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isLoading = false;
  };

  @action
  onDeletehUser = async (id: string) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    try {
      const result = await this.parent.auth.withToken(deleteItemFn, {
        url: `${this.api?.delete.url}/${id}`,
      });

      if (result.success) {
        this.parent?.notifications.showSuccess(i18n.t('messages:The user account deleted successfully'));
        this.parent?.history.push('/users');
      }
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isLoading = false;
    this.fetchItems();
  };

  @computed
  get ListHeadButtons() {
    return observer(() => {
      return <ListHeadButtons entity={this} data={this.data} isLoading={this.isLoading} />;
    });
  }

  @computed
  get ListHead() {
    return observer(() => (
      <CustomEntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListBody() {
    return observer(() => {
      return (
        <CustomEntityList
          fields={this.fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable && this.canView}
          canEdit={this.editable && this.canEdit}
          canSelect={this.selectable}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: string }) => <HeadButtons id={id} />);
  }

  @computed
  get EditorBody() {
    return observer(({ id }: { id: string }) => (
      <EntityViewer
        id={id}
        fields={this.fields}
        url={this.menu.url}
        errors={this.editorFieldErrors}
        onSave={this.updateItem}
        onCancel={this.onEditCancel}
        onResetFieldError={this.resetFieldError}
        isEditing
        isLoading={this.isLoading}
        setEditorData={this.setEditorData}
        data={this.editorData}
        getItem={this.getItem}
        cancelGetItem={this.getItemsCancel}
        withToken={this.parent?.auth?.withToken}
        viewable={this.viewable}
        entity={this}
      />
    ));
  }

  @computed
  get CreatorBody() {
    return observer(() => (
      <EntityViewer
        fields={this.fields}
        url={this.menu.url}
        errors={this.editorFieldErrors}
        onSave={this.createItem}
        onCancel={this.onEditCancel}
        onResetFieldError={this.resetFieldError}
        isEditing
        isLoading={this.isLoading}
        setEditorData={this.setEditorData}
        data={this.editorData}
        getItem={this.createEmptyItem}
        cancelGetItem={this.getItemsCancel}
        withToken={this.parent?.auth?.withToken}
        viewable={this.viewable}
        entity={this}
      />
    ));
  }
}

export { UserEntity };
