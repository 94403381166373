import React, { FC } from 'react';
import styles from './styles.module.scss';
import { FormControl, FormHelperText } from '@material-ui/core';
import Picker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type IProps = {
  label?: string;
  labelSeparate?: string;
  required?: boolean;
  value: Date | null;
  placeholder?: string;
  dateFormat?: string;
  error?: string;
  onChange: (date: Date) => any;
  disabled?: boolean;
};

const DatePicker: FC<IProps> = ({
  label,
  labelSeparate,
  required,
  value,
  placeholder,
  error,
  onChange,
  dateFormat,
  disabled,
}: IProps) => {
  return (
    <FormControl variant="outlined" fullWidth>
      {!!labelSeparate && (
        <div className={`${styles.label} ${error && styles.label__error}`}>
          {labelSeparate}
          {required && ' *'}
        </div>
      )}
      <Picker
        className={`${styles.picker} ${error && styles.picker__error}`}
        placeholderText={placeholder}
        selected={value}
        onChange={onChange}
        disabled={disabled}
        dateFormat={!!dateFormat ? dateFormat : 'yyyy.MM.dd'}
        popperModifiers={[
          {
            name: 'arrow',
            options: {
              padding: ({ popper, reference, placement }) => ({
                right: Math.min(popper.width, reference.width) - 24,
              }),
            },
          },
        ]}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
};

export { DatePicker };
