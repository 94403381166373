import { IEntityUpdateFunctionProps, IEntityUpdateFunctionResult } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const updateBankTransferRequest = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(`${url}`, data, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};
