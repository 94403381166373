import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface IProps {
  children: ReactNode;
  toggleModalVisibility: () => void;
  disabledAddingButton: boolean;
  isViewPage?: boolean;
}

const CategoryMainInfoBlock: FC<IProps> = observer(
  ({ children, toggleModalVisibility, disabledAddingButton, isViewPage }) => {
    const { t } = useTranslation();

    const toggle = () => {
      toggleModalVisibility();
    };

    return (
      <div className={styles.container}>
        <div className={styles.head}>
          <h2 className={styles.title}>{t('fields:Category (max count - 4)')}</h2>
          {!isViewPage && (
            <Button
              className={styles.button}
              variant="outlined"
              disableElevation
              onClick={toggle}
              disabled={disabledAddingButton}
            >
              {t('buttons:Add')}
            </Button>
          )}
        </div>
        <div className={styles.body}>{children}</div>
      </div>
    );
  },
);

export { CategoryMainInfoBlock };
