import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { IInputField, IRadio } from '~/utils/types/shops.types';
import { BlockContainer } from '~/pages/shops/components/BlockContainer';
import {
  BANK_INFO_DATA,
  BUSINESS_DATA,
  COMPANY_DATA,
  NO_PUBLIC_TEXTAREA_DATA,
  RADIO_DATA,
  TOP_NO_PUBLIC_BLOCK,
} from '~/pages/shops/shops-constants';
import { RadioButtonContainer } from '~/pages/shops/components/RadioButtonContainer';
import { RadioButtonItem } from '~/pages/shops/components/RadioButtonContainer/RadioButtonItem';
import { FieldsContainer } from '~/pages/shops/components/FieldsContainer';
import { TextAreaContainer } from '~/pages/shops/components/TextareaContainer';
import { createMapData, noPublicFinalData } from '~/pages/shops/helper';

export class NoPublicInfoEntity extends Entity {
  @observable radioBlockData: Map<string, Omit<IRadio, 'field'>> = createMapData<IRadio>(RADIO_DATA);
  @observable bankInfoData: Map<string, Omit<IInputField, 'field'>> = createMapData<IInputField>(BANK_INFO_DATA);
  @observable businessData: Map<string, Omit<IInputField, 'field'>> = createMapData<IInputField>(BUSINESS_DATA);
  @observable companyData: Map<string, Omit<IInputField, 'field'>> = createMapData<IInputField>(COMPANY_DATA);
  @observable textareaData: Map<string, Omit<IInputField, 'field'>> =
    createMapData<IInputField>(NO_PUBLIC_TEXTAREA_DATA);
  @observable topData: Map<string, Omit<IInputField, 'field'>> = createMapData<IInputField>(TOP_NO_PUBLIC_BLOCK);

  @observable errorCounter: number = 0;

  noPublicFields = [
    this.radioBlockData,
    this.bankInfoData,
    this.businessData,
    this.companyData,
    this.textareaData,
    this.topData,
  ];

  @action
  validateEachField = (object: Map<any, any>) => {
    object.forEach((mapItem, id) => {
      if (mapItem.required && mapItem.value?.length === 0) {
        object.set(id, { ...mapItem, error: 'common:Fill in the field' });
        this.errorCounter++;
      } else {
        object.set(id, { ...mapItem, error: '' });
      }
    });
  };

  @action
  validateAllFields() {
    this.errorCounter = 0;
    this.noPublicFields.map(groupFields => {
      this.validateEachField(groupFields);
    });
  }

  @action
  prepareFields() {
    const data = this.noPublicFields.map(item => noPublicFinalData(item));
    const finalObj = {};
    data.forEach(item => Object.assign(finalObj, item));
    return finalObj;
  }

  @action
  distributeResponseData(data: any) {
    RADIO_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IRadio, 'field'>>, string>(this.radioBlockData, field, data[field]);
    });

    BANK_INFO_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(this.bankInfoData, field, data[field]);
    });

    BUSINESS_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(this.businessData, field, data[field]);
    });

    COMPANY_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(this.companyData, field, data[field]);
    });

    NO_PUBLIC_TEXTAREA_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(this.textareaData, field, data[field]);
    });

    TOP_NO_PUBLIC_BLOCK.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(this.topData, field, data[field]);
    });
  }

  @action
  setMapData = <T extends Map<string, any>, K>(mapObj: T, field: string, value: K): void => {
    const obj = mapObj.get(field);
    if (obj) {
      obj.value = value || '';
      mapObj.set(field, obj);
    }
  };

  @action
  clearData() {
    // shorten later
    TOP_NO_PUBLIC_BLOCK.map(({ field, ...rest }) => {
      this.topData.set(field, rest);
    });
    RADIO_DATA.map(({ field, ...rest }) => {
      this.radioBlockData.set(field, rest);
    });
    BANK_INFO_DATA.map(({ field, ...rest }) => {
      this.bankInfoData.set(field, rest);
    });
    BUSINESS_DATA.map(({ field, ...rest }) => {
      this.businessData.set(field, rest);
    });
    COMPANY_DATA.map(({ field, ...rest }) => {
      this.companyData.set(field, rest);
    });
    NO_PUBLIC_TEXTAREA_DATA.map(({ field, ...rest }) => {
      this.textareaData.set(field, rest);
    });
  }

  @action
  onChangeFieldValue = (object: Map<string, any>): ((field: string, value: string) => void) => {
    return (field: string, value: string) => {
      const fieldData = object.get(field);
      if (!fieldData) {
        throw Error('There is no corresponding field');
      }
      fieldData.value = value;
      object.set(field, fieldData);
    };
  };

  @computed
  get RadioBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <RadioButtonContainer>
          {Array.from(this.radioBlockData.entries()).map(([field, data], index, arr) => {
            return (
              <RadioButtonItem
                key={field}
                field={field}
                ariaId={field}
                isViewPage={isViewPage}
                {...data}
                onChangeFieldValue={this.onChangeFieldValue(this.radioBlockData)}
              />
            );
          })}
        </RadioButtonContainer>
      );
    });
  }

  @computed
  get BusinessBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer isButtonShown={false}>
          <FieldsContainer
            fields={this.businessData}
            onChangeFieldValue={this.onChangeFieldValue(this.businessData)}
            isViewPage={isViewPage}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get BankInfoBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer isButtonShown={false}>
          <FieldsContainer
            fields={this.bankInfoData}
            onChangeFieldValue={this.onChangeFieldValue(this.bankInfoData)}
            isViewPage={isViewPage}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get TopNoPublicBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer isButtonShown={false}>
          <FieldsContainer
            fields={this.topData}
            onChangeFieldValue={this.onChangeFieldValue(this.topData)}
            isViewPage={isViewPage}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get NoPublicTextareaBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer isButtonShown={false}>
          <TextAreaContainer
            fields={this.textareaData}
            onChangeFieldValue={this.onChangeFieldValue(this.textareaData)}
            isViewPage={isViewPage}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get CompanyBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer isButtonShown={false}>
          <FieldsContainer
            fields={this.companyData}
            onChangeFieldValue={this.onChangeFieldValue(this.companyData)}
            isViewPage={isViewPage}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get NoPublicInfoBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <>
          <this.TopNoPublicBlock isViewPage={isViewPage} />
          <this.BusinessBlock isViewPage={isViewPage} />
          <this.CompanyBlock isViewPage={isViewPage} />
          <this.RadioBlock isViewPage={isViewPage} />
          <this.NoPublicTextareaBlock isViewPage={isViewPage} />
          <this.BankInfoBlock isViewPage={isViewPage} />
        </>
      );
    });
  }
}
