import { IEntityField } from 'icerockdev-admin-toolkit';

export const SHOP_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    required: true,
    hideInList: false,
  },
  {
    type: 'string',
    name: 'directorName',
    label: 'Director Name',
    required: true,
    hideInList: false,
  },
  {
    type: 'number',
    name: 'number',
    label: 'Number',
    required: true,
    hideInList: false,
  },
  {
    type: 'string',
    name: 'address',
    label: 'Address',
    hideInEdit: true,
    sortable: true,
    required: true,
    hideInList: false,
  },
  {
    type: 'string',
    name: 'branch',
    label: 'Branch',
    required: true,
    hideInList: false,
  },
];
