import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { WhitdrawRequestEntity } from '~/pages/withdraw-request/components/WhitdrawRequestEntity';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import { useTranslation } from 'react-i18next';

interface IProps extends IEntityFieldProps {
  entity: WhitdrawRequestEntity;
}

export const BankAccountButton: FC<IProps> = ({ data, entity }) => {
  const { t } = useTranslation();

  return (
    <Link to={`profile/${data?.user?.id}`} className={styles.link}>
      <div className={styles.body__wrap}>
        <AccountBoxOutlinedIcon style={{ color: '#DC9B2D' }} />
        <span>{t('buttons:Profile')}</span>
      </div>
    </Link>
  );
};
