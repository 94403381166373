import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { CustomInput } from '~/components/common/CustomInput';
import { IInputField } from '~/utils/types/shops.types';
import { observer } from 'mobx-react';
import { placeHolderText } from '~/pages/shops/helper';

interface IProps {
  fields: Map<string, Omit<IInputField, 'field'>>;
  onChangeFieldValue: (field: string, value: string) => void;
  isViewPage?: boolean;
}

const BottomMainInfoContainer: FC<IProps> = observer(({ fields, onChangeFieldValue, isViewPage }) => {
  const { t } = useTranslation();

  const onChange = (field: string, value: string, limit: number) => {
    if (value.length > limit) {
      const editedString = value.slice(0, limit);
      return onChangeFieldValue(field, editedString);
    }
    onChangeFieldValue(field, value);
  };

  return (
    <div className={styles.container}>
      {Array.from(fields.entries()).map(([field, data]) => {
        return (
          <div className={styles.textarea} key={field}>
            <CustomInput
              labelSeparate={data.labelSeparate.includes(':') ? t(data.labelSeparate) : data.labelSeparate}
              placeholder={placeHolderText(data.placeholder, isViewPage)}
              isMultiline={true}
              minTextareaHeight={'60px'}
              rows={data.rows}
              required={data.required}
              error={data.error.includes(':') ? t(data.error) : data.error}
              value={data.value}
              disabled={isViewPage}
              onChange={e => onChange(field, e.target.value, data.limit)}
              maxLength={data.limit}
            />
          </div>
        );
      })}
    </div>
  );
});

export { BottomMainInfoContainer };
