import { createWithdrawRequest, fetchWihdrawRequests, updateWithdrawRequest } from './api';
import { WITHDRAW_REQUEST_FIELDS } from './fields';
import { WhitdrawRequestEntity } from './components/WhitdrawRequestEntity';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';

export default new WhitdrawRequestEntity({
  title: 'Withdraw request',
  viewable: false,
  editable: false,
  creatable: true,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.withdrawRequests, method: 'get' },
    create: { url: API_ROUTES.withdrawRequests, method: 'post' },
    update: { url: API_ROUTES.withdrawRequests, method: 'put' },
  },
  menu: {
    label: 'Withdraw request',
    url: '/withdraw-request',
    enabled: true,
  },
  fields: WITHDRAW_REQUEST_FIELDS,
  fetchItemsFn: fetchWihdrawRequests,
  createItemsFn: createWithdrawRequest,
  updateItemsFn: updateWithdrawRequest,
  roles: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business],
    update: [UserRole.Admin, UserRole.SuperAdmin],
    create: [UserRole.Business],
  },
});
