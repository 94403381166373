import React, { useCallback, useEffect, useMemo } from 'react';
import { CircularProgress, Paper, withStyles, WithStyles } from '@material-ui/core';
import styles from './styles';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Entity, EntityField, IEntityField } from 'icerockdev-admin-toolkit';
import { ServiceRequestDynamicFields } from '~/utils/constants/requests.constants';
import { FormattedJsonDate } from '~/components/common/FormattedJsonDate';
import { TextField, Checkbox, FormControlLabel, RadioGroup, Radio, Select } from '@material-ui/core';
import { isValid, parseISO, formatISO } from 'date-fns';
import { DatePicker } from '~/components/common/DatePicker';
import { ImagesAttachment } from '../ImagesAttachment';
import { CustomStepper } from '~/components/common/CustomStepper';

type IProps = WithStyles<typeof styles> & {
  url: string;
  id?: string;
  fields: IEntityField[];
  errors: Record<string, string>;
  isEditing: boolean;
  isLoading: boolean;
  data: Record<string, any>;
  viewable: boolean;
  entity: Entity;

  setEditorData: (data: Record<string, any>) => void;
  getItem: (id: any) => void;
  cancelGetItem: () => void;
  onSave: () => void;
  onCancel: () => void;
  onResetFieldError: (field: string) => void;
  withToken?: (req: any, args: any) => any;
};

const EntityViewer = withStyles(styles)(
  observer(
    ({
      classes,
      id,
      fields,
      errors,
      onSave,
      onCancel,
      onResetFieldError,
      isLoading,
      data,
      setEditorData,
      getItem,
      cancelGetItem,
      withToken,
      isEditing,
      entity,
    }: IProps) => {
      const { t } = useTranslation();
      const isCreating = useMemo(() => typeof id === 'undefined', [id]);

      const visibleFields = useMemo(
        () =>
          fields.filter(
            field =>
              (isEditing && !isCreating && !field.hideInEdit) ||
              (isCreating && !field.hideInCreate) ||
              (!isEditing && !isCreating && !field.hideInView),
          ),
        [fields, isEditing, isCreating],
      );

      const onFieldChange = useCallback(
        f => (value: any) => {
          if (errors[f]) {
            onResetFieldError(f);
          }

          setEditorData({ ...data, [f]: value });
        },
        [errors, setEditorData, data, onResetFieldError],
      );

      const onSubmit = useCallback(
        event => {
          event.preventDefault();
          onSave();
        },
        [onSave],
      );

      const onChangeDynamicFields = useCallback(
        (value, fieldId) => {
          setEditorData({
            ...data,
            // eslint-disable-next-line no-useless-computed-key
            ['fields']: data.fields.map(i => (i.id === fieldId ? (i = { ...i, value: value }) : i)),
          });
        },
        [data, setEditorData],
      );

      useEffect(() => {
        getItem(id);
        return () => cancelGetItem();
      }, [cancelGetItem, getItem, id]);

      if (isLoading) {
        return (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        );
      }

      return (
        <div className={classes.wrap}>
          {data && (
            <form onSubmit={onSubmit}>
              <Paper>
                <div className={classes.grid} style={{ flexWrap: 'wrap' }}>
                  {visibleFields.map(field =>
                    field.name === 'fields' ? (
                      <div key={field.name}>
                        {data[field.name]?.map(item => (
                          <div
                            className={classes.field}
                            key={item.id}
                            style={isEditing ? { flexDirection: 'column' } : undefined}
                          >
                            <div className="label">{t(`fields:${item.title}`)}</div>
                            <div className="field">
                              <FieldInputType
                                item={item}
                                isEditing={isEditing}
                                handler={value => onChangeDynamicFields(value, item.id)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div
                        className={classes.field}
                        key={field.name}
                        style={isEditing ? { flexDirection: 'column' } : undefined}
                      >
                        {!isEditing && (
                          <div className="label">{field.label ? t(`fields:${field.label}`) : field.name}</div>
                        )}
                        <div className="field">
                          <EntityField
                            name={field.name}
                            data={data}
                            fields={fields}
                            isEditing={isEditing}
                            error={errors[field.name]}
                            handler={onFieldChange(field.name)}
                            withToken={withToken}
                            entity={entity}
                          />
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </Paper>
            </form>
          )}
        </div>
      );
    },
  ),
);

export { EntityViewer };

const FieldInputType = ({
  item,
  isEditing,
  handler,
}: {
  item: any;
  isEditing: boolean;
  handler: (value: any) => void;
}) => {
  const { t } = useTranslation();

  switch (item.type) {
    case ServiceRequestDynamicFields.Date: {
      const dateObject = JSON.parse(item.value);
      const isFixed = dateObject?.isFixed;
      const parsedDate = parseISO(dateObject?.date);
      const isValidDate = isValid(parsedDate);
      const date = isValidDate ? parsedDate : null;

      const onChangeDate = (value: Date) => {
        const newDateObject = {
          date: formatISO(value),
          isFixed,
        };

        handler(JSON.stringify(newDateObject));
      };

      const onChangeFixed = (checked: boolean) => {
        const newDateObject = {
          date,
          isFixed: !checked,
        };

        handler(JSON.stringify(newDateObject));
      };

      return isEditing ? (
        <>
          <DatePicker value={date} onChange={onChangeDate} />
          <FormControlLabel
            control={<Checkbox checked={!isFixed} onChange={e => onChangeFixed(e.target.checked)} />}
            label={t('Date is not fixed yet')}
          />
        </>
      ) : (
        <FormattedJsonDate value={item.value} />
      );
    }
    case ServiceRequestDynamicFields.Time: {
      return isEditing ? (
        <TextField
          id={item.id}
          variant="outlined"
          type="time"
          value={item.value}
          onChange={e => handler(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
        />
      ) : (
        <>{item.value}</>
      );
    }
    case ServiceRequestDynamicFields.Input: {
      return isEditing ? (
        <TextField value={item.value} variant="outlined" onChange={e => handler(e.target.value)} />
      ) : (
        <>{item.value}</>
      );
    }
    case ServiceRequestDynamicFields.Number: {
      return isEditing ? (
        <TextField type="number" value={item.value} variant="outlined" onChange={e => handler(e.target.value)} />
      ) : (
        <>{item.value}</>
      );
    }
    case ServiceRequestDynamicFields.Radio: {
      return isEditing ? (
        <RadioGroup aria-label="gender" name={item.id} value={item.value} onChange={e => handler(e.target.value)}>
          {item?.extraData?.map(field => (
            <FormControlLabel key={field.id} value={field.value} control={<Radio />} label={field.value} />
          ))}
        </RadioGroup>
      ) : (
        <>{item.value}</>
      );
    }
    case ServiceRequestDynamicFields.Select: {
      return isEditing ? (
        <Select variant="outlined" fullWidth native value={item.value} onChange={e => handler(e.target.value)}>
          {item?.extraData?.map(field => (
            <option key={field.id} value={field.value}>
              {field.value}
            </option>
          ))}
        </Select>
      ) : (
        <>{item.value}</>
      );
    }
    case ServiceRequestDynamicFields.Stepper: {
      return isEditing ? (
        <CustomStepper
          value={Number(item.value)}
          onChange={handler}
          minValue={item.extraData.minVal}
          maxValue={item.extraData.maxValue}
        />
      ) : (
        <>{item.value}</>
      );
    }
    case ServiceRequestDynamicFields.Image: {
      return <ImagesAttachment isEditing={isEditing} value={item.value} handler={handler} />;
    }
    default:
      return null;
  }
};
