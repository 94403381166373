import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import PinIcon from '~/components/common/GoogleMaps/PinIcon';
import { useTranslation } from 'react-i18next';

interface IProps {
  setCoordinates: (lat: number, lng: number, address?: any) => void;
  coordinate: Map<'lat' | 'lng', number>;
  address: string;
  isViewPage?: boolean;
}

const Marker: FC<any> = () => (
  <div
    style={{
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'translate(-50%, -100%)',
    }}
  >
    <PinIcon />
  </div>
);

const GoogleMaps: FC<IProps> = observer(({ setCoordinates, coordinate, address, isViewPage }) => {
  const { t } = useTranslation();
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_KEY);
  Geocode.setLanguage('en');

  useEffect(() => {
    //  3 - just because when you start texting an address google maps points in the wrong position at first
    if (address.length > 3) {
      Geocode.fromAddress(address).then(
        response => {
          const { lat, lng } = response.results[0].geometry.location;
          setCoordinates(lat, lng);
        },
        error => {
          console.error(error);
        },
      );
    }
  }, [address]);

  const defaultProps = {
    center: {
      lat: Number(process.env.REACT_APP_MAP_LATITUDE),
      lng: Number(process.env.REACT_APP_MAP_LONGITUDE),
    },
    zoom: 10,
  };

  const onMapClickHandler = e => {
    if (isViewPage) return;
    const latitude = e.lat;
    const longitude = e.lng;

    Geocode.fromLatLng(latitude, longitude).then(
      response => {
        const address = response.results[0].formatted_address;
        setCoordinates(latitude, longitude, address);
      },
      error => {
        console.error(error);
      },
    );
  };

  return (
    <div className={styles.container}>
      <GoogleMapReact
        draggable={isViewPage ? false : true}
        gestureHandling={isViewPage ? 'none' : 'auto'}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        defaultCenter={defaultProps.center}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={defaultProps.zoom}
        onClick={onMapClickHandler}
        center={{
          lat: coordinate.get('lat') || Number(process.env.REACT_APP_MAP_LATITUDE),
          lng: coordinate.get('lng') || Number(process.env.REACT_APP_MAP_LONGITUDE),
        }}
      >
        <Marker lat={coordinate.get('lat')} lng={coordinate.get('lng')} />
      </GoogleMapReact>
      {coordinate.get('lat') && (
        <div className={styles.information}>
          <p>
            {t('fields:Latitude')}: {coordinate.get('lat')}
          </p>
          <p>
            {t('fields:Longitude')}: {coordinate.get('lng')}
          </p>
        </div>
      )}
    </div>
  );
});

export { GoogleMaps };
