import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { MAX_LENGTH_REVIEW_DESCRIPTION } from '~/pages/review/constants';

const DescriptionComponent: FC<IEntityFieldProps> = ({ value, data }) => {
  if (!value) return null;
  const isDetailPage = window.location.href.endsWith(`/${data?.id}`);

  if (isDetailPage) {
    return <span>{value}</span>;
  }

  return (
    <span>
      {value.slice(0, MAX_LENGTH_REVIEW_DESCRIPTION)}
      {value.length > MAX_LENGTH_REVIEW_DESCRIPTION ? '...' : ''}
    </span>
  );
};

export default DescriptionComponent;
