import { Button, Paper, TextField, Dialog, CircularProgress } from '@material-ui/core';
import { IEntityFieldProps, useConfig, useEntity } from 'icerockdev-admin-toolkit';
import { observer } from 'mobx-react';
import React, { ChangeEvent, FC, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import cn from 'classnames';
import { ColoredStatus } from '../ColoredStatus';
import { FormattedDate } from '~/components/common/FormattedDate';
import AdminAvatarIcon from '~/components/icons/AdminAvatarIcon';
import SendIcon from '~/components/icons/SendIcon';
import UserAvatarIcon from '~/components/icons/UserAvatarIcon';
import {
  ServiceRequestMessageContentType,
  ServiceRequestTypeMessage,
  TRANSFER_REQUEST_STATUS_NAMES,
  TRANSFER_REQUEST_STATUS_NAME_COLORS,
} from '~/utils/constants/requests.constants';
import { UserRole } from '~/utils/constants/roles.constants';
import useEndlessRepeater from '~/utils/hooks/useEndlessRepeater';
import { IMessage } from '~/utils/types/requests.types';
import { ServiceRequestEntity } from '../ServiceRequestEntity';
import styles from './styles.module.scss';
import AttachIcon from '~/components/icons/AttachIcon';
import MicIcon from '~/components/icons/MicIcon';
import StopIcon from '~/components/icons/StopIcon';
import CloseIcon from '~/components/icons/CloseIcon';
import { useTranslation } from 'react-i18next';
import { useRecorderPermission } from '~/utils/hooks/useRecorderPermissions';
import { checkUrlsOnText, formatUrlsOnText } from '~/utils/utils';
import { useIntersectionObserver } from '~/utils/hooks/useIntersectionObserver';

type IProps = IEntityFieldProps;

const Chat: FC<any> = observer(({ id, data, newMyMessage, isLoading, isMessagesLoading }) => {
  const config = useConfig();
  const { t } = useTranslation();
  const entity = useEntity<ServiceRequestEntity>();
  const acceptRolesChatInput = [UserRole.Admin, UserRole.SuperAdmin];
  const isAdmins = acceptRolesChatInput.includes(config.auth?.userRole as UserRole);

  const authUserId = config.auth?.user.id;

  const messages: IMessage[] = data;
  const [inputValue, setInputValue] = useState<string>('');

  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [imageData, setImageData] = useState<{ url: string; alt: string }>();

  const [fileImage, setFileImage] = useState<File>();
  const [previewImage, setPreviewImage] = useState<string>();

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [blobAudio, setBlobAudio] = useState<Blob>();
  const [blobUrl, setBlobUrl] = useState<string>();

  const [page, setPage] = useState(0);

  const bottomRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(topRef, {});
  const isVisible = entry && entry.isIntersecting;

  const recorder = useRecorderPermission('audio');

  // Initial fetch message, and autoscroll to last message
  useEffect(() => {
    entity.fetchMessages(id, page, true);
  }, [entity, id, page]);

  // Clear message after unmount
  useEffect(() => {
    return function () {
      entity.listMessages = [];
    };
  }, [entity]);

  // Autoscroll atfter i send message
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [bottomRef, newMyMessage]);

  // Infinite scroll, pagination
  useEffect(() => {
    if (isVisible) {
      setPage(prevState => prevState + 1);
    }
  }, [isVisible]);

  // Repeater for get new message every 5s
  useEndlessRepeater(5000, () => entity.fetchMessages(id, 0, false));

  const startRecording = async () => {
    setIsRecording(true);

    try {
      await recorder.startRecording();
    } catch (e) {
      entity.parent?.notifications.showError(t('messages:Microphone permission not allowed'));
    }
  };

  const stopRecording = async () => {
    setIsRecording(false);

    try {
      await recorder.stopRecording();
      const blob = await recorder.getBlob();
      setBlobAudio(blob);

      const blobURL = URL.createObjectURL(blob);
      setBlobUrl(blobURL);
    } catch (e) {
      entity.parent?.notifications.showError(t('messages:Save record error'));
      console.error(e);
    }
  };

  const handleModal = (url: string, alt: string): void => {
    setIsImageModalOpen(prevState => !prevState);
    setImageData({ url, alt });
  };

  const addFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileImage(e.target.files?.[0]);
      setPreviewImage(URL.createObjectURL(e.target.files?.[0]));
    }
  };

  const removeFile = () => {
    setFileImage(undefined);
    setPreviewImage(undefined);
  };

  const removeAudio = () => {
    setBlobUrl(undefined);
    setBlobAudio(undefined);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.shiftKey) return;
      if (e.key === 'Enter') {
        e.preventDefault();
        onSendMessage();
      }
    },
    [inputValue],
  );

  const onSendMessage = useCallback(() => {
    if (fileImage) {
      entity.onUploadFileOnChat(fileImage, id, inputValue);
      removeFile();
      setInputValue('');
    } else if (blobAudio) {
      entity.onUploadFileOnChat(blobAudio, id, inputValue);
      removeAudio();
      setInputValue('');
    } else if (inputValue.trim()) {
      entity.sendMessage(id, inputValue);
      setInputValue('');
    }
  }, [entity, fileImage, blobAudio, id, inputValue]);

  return (
    <>
      <div className={styles.wrap}>
        <Paper className={styles.paper}>
          <div className={styles.chat}>
            <div className={styles.messages}>
              <div ref={bottomRef} />
              {!!messages.length &&
                messages.map(item => {
                  const isPlain = item.type === ServiceRequestTypeMessage.Plain;

                  if (!isPlain) {
                    return (
                      <div key={item.id} className={styles.message__wrap__system}>
                        <div className={styles.message__block}>
                          <div className={styles.message}>{item.messageText}</div>
                          {!!item.extraData?.status && (
                            <ColoredStatus
                              value={item.extraData?.status}
                              statusNames={TRANSFER_REQUEST_STATUS_NAMES}
                              statusNameColors={TRANSFER_REQUEST_STATUS_NAME_COLORS}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }

                  const currentUser = item.author.id === authUserId || item.author.role === UserRole.Concierge;
                  const isContentTypeImage = item.messageContentType === ServiceRequestMessageContentType.Image;
                  const isContentTypeAudio = item.messageContentType === ServiceRequestMessageContentType.Audio;

                  return (
                    <div
                      key={item.id}
                      className={currentUser ? styles.message__wrap__currentUser : styles.message__wrap}
                    >
                      <div className={styles.avatar}>{currentUser ? <AdminAvatarIcon /> : <UserAvatarIcon />}</div>
                      <div className={styles.message__block}>
                        <div className={styles.name}>{(!currentUser || isAdmins) && item.author.firstName}</div>

                        {item.messageText &&
                          (checkUrlsOnText(item.messageText) ? (
                            <div
                              className={currentUser ? styles.message__currentUser : styles.message}
                              dangerouslySetInnerHTML={{ __html: formatUrlsOnText(item.messageText) }}
                            ></div>
                          ) : (
                            <div className={currentUser ? styles.message__currentUser : styles.message}>
                              {item.messageText}
                            </div>
                          ))}

                        {isContentTypeImage
                          ? item.attachments.map((attach, idx) => (
                              <div
                                key={attach.meta.originalName + idx}
                                onClick={() => handleModal(attach.attachmentUrl, attach.meta.originalName)}
                                className={styles.image__wrapper}
                              >
                                <img src={attach.attachmentUrl} alt={attach.meta.originalName} />
                              </div>
                            ))
                          : null}

                        {isContentTypeAudio
                          ? item.attachments.map((attach, idx) => (
                              <ReactAudioPlayer
                                key={attach.meta.originalName + idx}
                                src={attach.attachmentUrl}
                                controls
                                volume={0.3}
                              />
                            ))
                          : null}

                        <div className={styles.date}>
                          <FormattedDate value={item.dateTime} withTime />
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div ref={topRef} />

              {isMessagesLoading && (
                <div className={styles.loader__wrap}>
                  <CircularProgress size={28} />
                </div>
              )}
            </div>

            {!isAdmins && (
              <>
                <div className={styles.send__message__wrap}>
                  <div className={styles.buttons__wrap}>
                    <Button className={styles.button} component="label">
                      <AttachIcon />
                      <input type="file" accept="image/*" onChange={addFile} hidden />
                    </Button>

                    <Button
                      className={cn(styles.button, {
                        [styles.button__rec]: isRecording,
                      })}
                      component="label"
                      onClick={!isRecording ? startRecording : stopRecording}
                    >
                      {!isRecording ? <MicIcon /> : <StopIcon color="white" />}
                    </Button>
                  </div>

                  <TextField
                    value={inputValue}
                    onChange={onChange}
                    variant="outlined"
                    onKeyDown={onKeyDown}
                    fullWidth
                    multiline
                    rowsMax={4}
                    size="small"
                    autoFocus
                  />

                  <Button onClick={onSendMessage} className={styles.button}>
                    <SendIcon />
                  </Button>
                </div>

                {previewImage && (
                  <div className={styles.preview__wrap}>
                    <img className={styles.previewImage} src={previewImage} alt="" />
                    <span>{fileImage?.name}</span>
                    <Button onClick={removeFile} className={styles.button}>
                      <CloseIcon />
                    </Button>
                  </div>
                )}

                {blobUrl && (
                  <div className={styles.preview__wrap}>
                    <ReactAudioPlayer src={blobUrl} controls volume={0.3} />
                    <Button onClick={removeAudio} className={styles.button}>
                      <CloseIcon />
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </Paper>
      </div>

      <Dialog
        open={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        aria-labelledby="lightbox-image"
        maxWidth="md"
      >
        <img src={imageData?.url} alt={imageData?.alt} />
      </Dialog>
    </>
  );
});

export { Chat };
