import { Entity } from 'icerockdev-admin-toolkit';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { updateReviewFn } from '~/pages/review/api';
import { CustomEntityHead } from '~/components/entity/CustomEntityHead';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import i18n from 'i18next';

class ReviewEntity extends Entity {
  @action
  updateReview = async (id: number, value: boolean) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    try {
      const response = await this.parent.auth.withToken(updateReviewFn, {
        url: `${this?.api?.update.url}/${id}`,
        value,
      });
      if (response?.success) {
        this.parent?.notifications.showSuccess(i18n.t('messages:The review updated successfully'));
      }
    } catch (e) {
      this.parent?.notifications.showError(e.message.toString());
    }
    this.fetchItems();
    this.parent?.history.push(this.menu.url);
    this.isLoading = false;
  };

  @computed
  get ListHead() {
    return observer(() => (
      <CustomEntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListBody() {
    return observer(() => {
      return (
        <CustomEntityList
          fields={this.fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable && this.canView}
          canEdit={this.editable && this.canEdit}
          canSelect={this.selectable}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }
}

export default ReviewEntity;
