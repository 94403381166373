import React, { FC } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';

interface IProps {}

const RadioButtonContainer: FC<IProps> = observer(({ children }) => {
  return <div className={styles.container}>{children}</div>;
});

export { RadioButtonContainer };
