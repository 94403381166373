import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const deleteQrCode = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.delete(url, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};

export const downloadQrCode = async ({
  url,
  data,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(`${url}/download`, data, {
    headers: { authorization: token },
    responseType: 'blob',
  });

  return {
    data: result.data,
  };
};
