import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { FormattedDate } from '~/components/common/FormattedDate';
import {
  BANK_TRANSFERS_STATUS_NAME,
  BANK_TRANSFERS_STATUS_NAME_COLORS,
} from '~/utils/constants/bank-transfer.constants';
import { ColoredStatus } from '~/components/common/ColoredStatus';
import { FormattedNumber } from '~/components/common/FormattedNumber';

export const BANK_TRANSFERS_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'createdAt',
    label: 'Created date',
    component: props => <FormattedDate {...props} withTime />,
    sortable: true,
  },
  {
    type: 'string',
    name: 'transferCode',
    label: 'Transfer Code',
    filterable: true,
  },
  {
    type: 'string',
    name: 'requisites',
    label: 'Requisites',
  },
  {
    type: 'custom',
    name: 'amount',
    label: 'Amount',
    component: props => <FormattedNumber value={props.value} />,
  },
  {
    type: 'custom',
    name: 'received',
    label: 'Received',
    component: props => <FormattedNumber value={props.value} />,
  },
  {
    type: 'string',
    name: 'currency',
    label: 'Currency',
  },
  {
    type: 'custom',
    name: 'status',
    label: 'Status',
    component: props => (
      <ColoredStatus
        {...props}
        statusNames={BANK_TRANSFERS_STATUS_NAME}
        statusNameColors={BANK_TRANSFERS_STATUS_NAME_COLORS}
      />
    ),
    sortable: true,
  },
];
