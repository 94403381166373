import React, { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TransactionsEntity } from '../TransactionsEntity';
import useDebounce from '~/utils/hooks/useDebounce';
import useOnChange from '~/utils/hooks/useOnChange';

interface IProps {
  isLoading: boolean;
  data: Record<string, string>[];
  entity: TransactionsEntity;
}

const ListHeadButtons: FC<IProps> = observer(({ data, entity, isLoading }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>('');

  const searchQueryThrottle: string = useDebounce(searchValue, 500);

  const onSearchTransactionsThrottleChange = useCallback(() => {
    entity.onSearchTransactions(searchQueryThrottle);
  }, [entity, searchQueryThrottle]);

  useOnChange(onSearchTransactionsThrottleChange, searchQueryThrottle);

  return (
    <>
      <div className={styles.wrap}>
        <TextField
          variant="outlined"
          placeholder={t('common:Search transactions by user name, memberId')}
          className={styles.search__input}
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
    </>
  );
});

export { ListHeadButtons };
