import React, { FC } from 'react';
import styles from './styles.module.scss';
import { FormControl, FormHelperText, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

type IProps = {
  ariaId?: string;
  label?: string;
  fields: { id: string; value: string }[] | null;
  required?: boolean;
  value?: string;

  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
};

const CustomRadio: FC<IProps> = ({
  ariaId,
  label,
  fields,
  required,
  value,
  error,
  onChange,
  className,
  disabled,
}: IProps) => {
  return (
    <FormControl variant="outlined" fullWidth className={className}>
      {!!label && (
        <div className={`${styles.label} ${error && styles.label__error}`}>
          {label}
          {required && ' *'}
        </div>
      )}
      <RadioGroup aria-label={ariaId} value={value} onChange={onChange}>
        {fields?.map(radio => (
          <FormControlLabel
            key={radio.id}
            value={radio.id}
            control={<Radio disabled={disabled} />}
            label={radio.value}
          />
        ))}
      </RadioGroup>
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
};

export { CustomRadio };
