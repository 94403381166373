import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '~/components/icons/CloseIcon';
import { UserEntity } from '../UserEntity';
import useDebounce from '~/utils/hooks/useDebounce';
import useOnChange from '~/utils/hooks/useOnChange';
import { useConfig } from 'icerockdev-admin-toolkit';

interface IProps {
  isLoading: boolean;
  data: Record<string, string>[];
  entity: UserEntity;
}

const ListHeadButtons: FC<IProps> = observer(({ data, entity, isLoading }) => {
  const { t } = useTranslation();
  const config = useConfig();
  const userRole = config.auth?.user.role;
  const isAccessRole = entity.permissions.create?.includes(userRole);

  const [uploadFileModal, setUploadFileModal] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [searchValue, setSearchValue] = useState<string>('');

  const searchQueryThrottle: string = useDebounce(searchValue, 500);

  const onSearchUsersThrottleChange = useCallback(() => {
    entity.onSearchUsers(searchQueryThrottle);
  }, [entity, searchQueryThrottle]);

  useOnChange(onSearchUsersThrottleChange, searchQueryThrottle);

  const handleModal = (): void => {
    setUploadFileModal(prevState => !prevState);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }

    entity.onImportUsers(file);
    setUploadFileModal(false);
  };

  return (
    <>
      <div className={styles.wrap}>
        <TextField
          variant="outlined"
          placeholder={t('common:Search users by user name, memberId')}
          className={styles.search__input}
          onChange={e => setSearchValue(e.target.value)}
        />

        {isAccessRole && (
          <Button variant="outlined" color="default" className={styles.button} onClick={handleModal}>
            {t('buttons:Import')}
          </Button>
        )}
      </div>

      <Dialog open={uploadFileModal} onClose={handleModal} aria-labelledby="form-dialog-title">
        <div className={styles.modal__wrap}>
          <div className={styles.modal__head}>
            <h2>{t('common:Import new Users')}</h2>
            <Button onClick={handleModal} className={styles.button__close}>
              <CloseIcon />
            </Button>
          </div>

          <TextField variant="outlined" type="file" onChange={handleFileChange} inputProps={{ accept: 'text/csv' }} />

          <div className={styles.modal__foot}>
            <Button onClick={handleUploadClick} variant="contained" color="primary" disabled={!file}>
              {t('buttons:Upload')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
});

export { ListHeadButtons };
