import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import { CustomSelect } from '~/components/common/CustomSelect';
import { COUNTRIES } from '~/utils/constants/profile.constants';

interface Option {
  label: string;
  value: string;
}

type IProps = IEntityFieldProps;

const SelectCountry: FC<IProps> = observer(({ label, value, handler, isEditing, error, data }) => {
  const onSelectConcierge = useCallback(
    (option: Option) => {
      if (handler) {
        handler(option.value);
      }
    },
    [handler],
  );

  return (
    <>
      {!isEditing ? (
        <>{COUNTRIES.find(country => country.value === value)?.label}</>
      ) : (
        <div className={styles.wrap}>
          <CustomSelect
            options={COUNTRIES}
            label={label}
            value={COUNTRIES.find(country => country.value === value)}
            onChange={onSelectConcierge}
            error={error}
          />
        </div>
      )}
    </>
  );
});

export { SelectCountry };
