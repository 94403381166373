import * as React from 'react';

interface PropTypes {
  size?: number;
}

const Component: React.FC<PropTypes> = ({ size = 24, ...props }: PropTypes) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.34 9.322l-14-7.002a3 3 0 00-4.08 3.901l2.4 5.372a1.06 1.06 0 010 .82l-2.4 5.372A3.002 3.002 0 005 22.005c.468-.004.93-.113 1.35-.32l14-7.001a3 3 0 000-5.362h-.01zm-.89 3.571l-14 7.002a1 1 0 01-1.35-1.3l2.39-5.372c.03-.072.057-.145.08-.22h6.89a1 1 0 000-2H6.57a2.004 2.004 0 00-.08-.22L4.1 5.41a1 1 0 011.35-1.3l14 7.002a1 1 0 010 1.78z"
      fill="#AEA69E"
    />
  </svg>
);

export default Component;
