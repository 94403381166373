import { fetchTransferRequests, updateTransferRequest } from './api';
import { TRANSFER_REQUEST_FIELDS } from './fields';
import { TransferRequestEntity } from './components/TransferRequestEntity';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';

export default new TransferRequestEntity({
  title: 'Transfer requests',
  viewable: false,
  editable: false,
  creatable: false,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.transferRequests, method: 'get' },
    create: { url: API_ROUTES.transferRequests, method: 'post' },
    update: { url: API_ROUTES.transferRequests, method: 'put' },
  },
  menu: {
    label: 'Transfer requests',
    url: '/transfer-requests',
    enabled: true,
  },
  fields: TRANSFER_REQUEST_FIELDS,
  fetchItemsFn: fetchTransferRequests,
  updateItemsFn: updateTransferRequest,
  roles: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge],
    update: [UserRole.Admin, UserRole.SuperAdmin],
  },
});
