import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { TypePoints } from '~/utils/constants/transactions.constants';
import localizeAmount from '~/utils/localization/amount';

type IProps = IEntityFieldProps;

const WithdrawSpWalletField: FC<IProps> = ({ data, value }: IProps) => {
  const spWallet = value?.wallets.find(item => item?.pointType === TypePoints.SP);

  return <div>{spWallet ? `${localizeAmount(spWallet?.amount)} ${spWallet?.pointType}` : ''}</div>;
};
export { WithdrawSpWalletField };
