import React, { FC } from 'react';
import styles from './styles.module.scss';
import { FormControl, FormHelperText } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateType } from '~/utils/types/reports.types';

type IProps = {
  onChange: (date: DateType) => void;
  label?: string;
  labelSeparate?: string;
  required?: boolean;
  value: DateType;
  placeholder?: string;
  dateFormat?: string;
  error?: string;
  disabled?: boolean;
};

const RangeDatePicker: FC<IProps> = ({
  labelSeparate,
  required,
  value,
  placeholder,
  error,
  onChange,
  dateFormat,
  disabled,
}: IProps) => {
  const [dateStart, dateEnd] = value;
  return (
    <FormControl variant="outlined" fullWidth>
      {!!labelSeparate && (
        <div className={`${styles.label} ${error && styles.label__error}`}>
          {labelSeparate}
          {required && ' *'}
        </div>
      )}
      <DatePicker
        className={`${styles.picker} ${error && styles.picker__error}`}
        selectsRange={true}
        startDate={dateStart}
        endDate={dateEnd}
        placeholderText={placeholder}
        onChange={onChange}
        withPortal
        disabled={disabled}
        popperModifiers={[
          {
            name: 'arrow',
            options: {
              padding: ({ popper, reference }) => ({
                right: Math.min(popper.width, reference.width) - 24,
              }),
            },
          },
        ]}
        dateFormat={!!dateFormat ? dateFormat : 'yyyy.MM.dd'}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
};

export { RangeDatePicker };
