import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

interface IProps extends IEntityFieldProps {
  statusNames: { [key: string]: string };
  statusNameColors: { [key: string]: string };
  rejectReason?: {
    rejectStatusName: string;
    reasonFieldName: string;
  };
}

const ColoredStatus: FC<IProps> = ({
  data,
  value,
  name,
  label,
  error,
  handler,
  statusNames,
  statusNameColors,
  rejectReason,
  isEditing,
}) => {
  const { t } = useTranslation('fields');
  const ref = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  const isRejectedStatus = statusNames[value] === rejectReason?.rejectStatusName;
  const rejectReasonTitle = data && rejectReason && data[rejectReason?.reasonFieldName];

  const onChange = useCallback(
    event => {
      if (!handler) return;

      handler(event.target.value);
    },
    [handler],
  );

  useEffect(() => {
    setLabelWidth((ref.current && ref.current.clientWidth) || 0);
  }, [ref]);

  return isEditing ? (
    <FormControl variant="outlined">
      <InputLabel htmlFor={name} error={!!error} style={{ whiteSpace: 'nowrap' }} ref={ref}>
        {label}
      </InputLabel>
      <Select
        variant="outlined"
        id={label}
        name={name}
        value={!value ? '' : value}
        onChange={onChange}
        error={!!error}
        inputProps={{ className: 'select' }}
        labelWidth={labelWidth}
        style={{ minWidth: labelWidth + 40 }}
      >
        <MenuItem value="">...</MenuItem>

        {statusNames &&
          Object.keys(statusNames).map(item => (
            <MenuItem key={item} value={item} style={{ color: statusNameColors[item] }}>
              {t(statusNames[item])}
            </MenuItem>
          ))}
      </Select>

      <FormHelperText error={!!error}>{error}</FormHelperText>
    </FormControl>
  ) : (
    <div style={{ color: statusNameColors[value], display: 'flex', gap: '4px', alignItems: 'center' }}>
      {t(`fields:${statusNames[value]}`)}
      {isRejectedStatus && rejectReasonTitle && (
        <Tooltip title={rejectReasonTitle} placement="top" arrow>
          <InfoIcon fontSize="small" style={{ cursor: 'pointer' }} />
        </Tooltip>
      )}
    </div>
  );
};

export { ColoredStatus };
