import React from 'react';
import { action, computed, flow, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { CustomEntityHead } from '~/components/entity/CustomEntityHead';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import {
  BreadcrumbsEnum,
  FieldsEnum,
  IInputField,
  IItemData,
  IResponseShopData,
  IShopThumbnail,
  ItemEnum,
  IUrlItem,
  ModalEnum,
  ModalTypes,
  ShopFieldType,
  UrlActionsEnum,
} from '~/utils/types/shops.types';
import { v4 as uuidv4 } from 'uuid';
import { BlockContainer } from '~/pages/shops/components/BlockContainer';
import { ThumbnailBlock } from '~/pages/shops/components/ThumbnailBlock';
import debounce from 'lodash.debounce';
import {
  BOTTOM_MAIN_INFO_DATA,
  BREADCRUMBS_ITEMS,
  CONTACT_INFO_DATA,
  ItemTable,
  PRICE_INFO_DATA,
  PRODUCTS_SUMMARY_DESCRIPTION_DATA,
  SHOP_THUMBNAIL_DATA,
  TOP_MAIN_INFO_DATA,
  UrlTable,
} from '~/pages/shops/shops-constants';
import ImageUploader from '~/components/common/ImageLoader';
import { TopMainInfoContainer } from '~/pages/shops/components/MainInfo/TopMainInfoBlock';
import { BottomMainInfoContainer } from '~/pages/shops/components/MainInfo/BottomMainInfoContainer';
import { MiddleMainInfoContainer } from '~/pages/shops/components/MainInfo/MiddleMainInfoBlock';
import { CategoryMainInfoBlock } from '~/pages/shops/components/MainInfo/MiddleMainInfoBlock/CategoryMainInfoBlock';
import { TagMainInfoBlock } from '~/pages/shops/components/MainInfo/MiddleMainInfoBlock/TagMainInfoBlock';
import { CategoryItem } from '~/pages/shops/components/MainInfo/MiddleMainInfoBlock/CategoryMainInfoBlock/CategoryItem';
import Modal from '~/components/common/Modal';
import { Price } from '~/pages/shops/components/Price';
import { API_ROUTES } from '~/utils/constants/api.constants';
import {
  addItemFn,
  createShopFn,
  deleteItemFn,
  fetchItemsFn,
  getCategories,
  getCommitment,
  getProduct,
  updateItemFn,
  updateShopFn,
  uploadFileFn,
} from '~/pages/shops/api';
import { Breadcrumbs } from '~/components/common/Breadcrumbs';
import { NoPublicInfoEntity } from '~/pages/shops/components/Entity/NoPublicInfoEntity';
import { HeadButton } from '~/pages/shops/components/HeadButton';
import { FieldsContainer } from '~/pages/shops/components/FieldsContainer';
import { GoogleMaps } from '~/components/common/GoogleMaps';
import {
  createMapData,
  finalCategoryData,
  finalItemData,
  finalShopThumbnailsData,
  finalUrlArray,
  isThereSomethingAfterSlashFunction,
  noPublicFinalData,
} from '~/pages/shops/helper';
import { ModalContent } from '~/pages/shops/components/ModalContent';
import { TableRowItem } from '~/pages/shops/components/TableRowItem';
import { Table } from '~/pages/shops/components/Table';
import {
  ENTITY_ERRORS,
  ENTITY_REFERENCE_FIELDS,
  IEntityFetchFunctionResult,
  IEntityGetFunctionResult,
} from 'icerockdev-admin-toolkit';
import { CategoryModalContentCard } from '~/pages/shops/components/MainInfo/MiddleMainInfoBlock/CategoryMainInfoBlock/CategoryModalContentCard';
import { CategoryType } from '~/utils/types/category.types';
import i18n from 'i18next';
import { PageContainer } from '~/pages/shops/components/PageContainer';
import { UrlModalContent } from '~/pages/shops/components/UrlModalContent';
import { TableRowLink } from '~/pages/shops/components/TableRowLink';
import { ShopVisibleCheckboxContainer } from '~/pages/shops/components/isShopVisibleContainer';
import { DeleteButton } from '~/pages/shops/components/DeleteButton';
import { ShopRole } from '~/utils/constants/roles.constants';
import { ShopListInteractionBlock } from '~/pages/shops/components/SearchField';
import { CustomFieldsModalContent } from '~/pages/shops/components/CustomFieldsModalContent';
import { getErrorMessage } from '~/utils/axios';

class ShopEntity extends NoPublicInfoEntity {
  @observable bottomMainInfoData: Map<string, Omit<IInputField, 'field'>> =
    createMapData<IInputField>(BOTTOM_MAIN_INFO_DATA);
  @observable topMainInfoData: Map<string, Omit<IInputField, 'field'>> = createMapData<IInputField>(TOP_MAIN_INFO_DATA);
  @observable contactInfoData: Map<string, Omit<IInputField, 'field'>> = createMapData<IInputField>(CONTACT_INFO_DATA);
  @observable priceData: Map<string, Omit<IInputField, 'field'>> = createMapData<IInputField>(PRICE_INFO_DATA);
  @observable productsDescriptionData: Map<string, Omit<IInputField, 'field'>> = createMapData<IInputField>(
    PRODUCTS_SUMMARY_DESCRIPTION_DATA,
  );
  @observable shopThumbnails: Map<string, IShopThumbnail> = new Map();
  @observable categoryMainInfoItems: Map<string, CategoryType> = new Map();
  @observable shopCommitmentData: Map<string, Omit<IItemData, 'id'>> = new Map();
  @observable productData: Map<string, Omit<IItemData, 'id'>> = new Map();
  @observable selectedItemData: IItemData | IUrlItem | null = null;
  @observable isModalVisible: ModalTypes = observable({
    categoryModal: false,
    shopCommitmentModal: false,
    productModal: false,
    urlModal: false,
    fieldsModal: false,
  });
  @observable tagValue: string = '';
  @observable activeBreadcrumbs: BreadcrumbsEnum = BreadcrumbsEnum.publicInfo;
  @observable coordinate: Map<'lat' | 'lng', number> = new Map().set('lat', '').set('lng', '');
  @observable urls: Map<string, Omit<IUrlItem, 'id'>> = new Map();
  @observable isHideShop: boolean = false;

  @observable publicData: Map<string, any>[] = [
    this.topMainInfoData,
    this.bottomMainInfoData,
    this.productsDescriptionData,
    this.priceData,
    this.contactInfoData,
  ];
  @observable currentShopId: string | undefined = undefined;
  @observable categoryOptions: CategoryType[] = [];
  @observable categoriesInput: string = '';
  @observable searchShopInputValue: string = '';
  @observable customListFields: ShopFieldType = {};

  constructor(fields: Partial<NoPublicInfoEntity>) {
    super();
    if (fields) {
      Object.assign(this, fields);
    }

    this.fields.forEach(field => {
      // hideInList = false? We assign to customListFields true to display checkbox as checked.
      // hideInList = true we do nothing because it's hidden
      if (!field.hideInList) {
        this.customListFields[field.label!] = true;
      }
    });

    this.applyCustomFieldsVisibility();

    this.clearData = this.clearData.bind(this);

    const debouncedUploadCategories = debounce(this.uploadCategories.bind(this), 500);

    reaction(() => [this.isModalVisible.categoryModal, this.categoriesInput], debouncedUploadCategories);
    reaction(() => this.searchShopInputValue, debounce(this.fetchItems, 500));
  }

  @action
  clearData() {
    super.clearData();
    this.shopThumbnails.clear();
    TOP_MAIN_INFO_DATA.map(({ field, ...rest }) => {
      this.topMainInfoData.set(field, rest);
    });
    BOTTOM_MAIN_INFO_DATA.map(({ field, ...rest }) => {
      this.bottomMainInfoData.set(field, rest);
    });
    CONTACT_INFO_DATA.map(({ field, ...rest }) => {
      this.contactInfoData.set(field, rest);
    });
    PRICE_INFO_DATA.map(({ field, ...rest }) => {
      this.priceData.set(field, rest);
    });
    PRODUCTS_SUMMARY_DESCRIPTION_DATA.map(({ field, ...rest }) => {
      this.productsDescriptionData.set(field, rest);
    });
    this.categoryMainInfoItems.clear();
    this.shopCommitmentData.clear();
    this.productData.clear();
    this.selectedItemData = null;
    this.tagValue = '';
    this.currentShopId = undefined;
    this.activeBreadcrumbs = BreadcrumbsEnum.publicInfo;
    this.errorCounter = 0;
    this.coordinate = new Map().set('lat', '').set('lng', '');
    this.urls.clear();
    this.isHideShop = false;
  }

  @action
  distributeResponseData(data: IResponseShopData) {
    super.distributeResponseData(data.nonPublic);
    data.public.images.forEach(item => {
      const newObj = { ...SHOP_THUMBNAIL_DATA };
      Object.assign(newObj, { value: item.description, imageUrl: item.imageUrl, imageKey: item.imageKey });
      this.shopThumbnails.set(uuidv4(), newObj);
    });

    this.tagValue = data.public.tag;
    this.coordinate.set('lat', data.public.coordinate?.lat);
    this.coordinate.set('lng', data.public.coordinate?.lng);
    this.isHideShop = data.public.isHide;

    data.categories.forEach(item => {
      const newObj: CategoryType = { label: item.name, value: String(item.id) };
      this.categoryMainInfoItems.set(String(item.id), newObj);
    });

    data.urls.forEach(url => {
      const newObj: Omit<IUrlItem, 'id'> = { isHide: url.isHide, title: url.title, link: url.link };
      this.urls.set(String(url.id), newObj);
    });

    TOP_MAIN_INFO_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(this.topMainInfoData, field, data.public[field]);
    });
    BOTTOM_MAIN_INFO_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(
        this.bottomMainInfoData,
        field,
        data.public[field],
      );
    });
    CONTACT_INFO_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(this.contactInfoData, field, data.public[field]);
    });
    PRICE_INFO_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(this.priceData, field, data.public[field]);
    });
    PRODUCTS_SUMMARY_DESCRIPTION_DATA.map(item => item.field).map(field => {
      this.setMapData<Map<string, Omit<IInputField, 'field'>>, string>(
        this.productsDescriptionData,
        field,
        data.public[field],
      );
    });

    data.commitments.forEach(({ id, ...rest }) => {
      this.shopCommitmentData.set(id, rest);
    });

    data.products.forEach(({ id, ...rest }) => {
      this.productData.set(id, rest);
    });
  }

  @action
  toggleShopVisibility = () => {
    this.isHideShop = !this.isHideShop;
  };

  @action
  getItem = (id: any) => {
    this.getItemsInstance = flow(function* (this: ShopEntity) {
      this.isLoading = true;
      this.error = '';

      if (Object.keys(this.editorFieldErrors).length) {
        this.editorFieldErrors = {};
      }

      try {
        if (!this.api?.get?.url || !this.getItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const shopResult: IEntityGetFunctionResult = yield this.parent?.auth?.withToken(this.getItemsFn, {
          id,
          url: this.api?.get?.url,
        });
        this.currentShopId = shopResult.data.public?.id;

        if (
          window.location.pathname.endsWith('/edit') ||
          isThereSomethingAfterSlashFunction(window.location.pathname)
        ) {
          const commitment: IEntityGetFunctionResult = yield this.parent?.auth?.withToken(getCommitment, {
            id: this.currentShopId,
            url: this.api?.getCommitment?.url,
          });
          const product: IEntityGetFunctionResult = yield this.parent?.auth?.withToken(getProduct, {
            id: this.currentShopId,
            url: this.api?.getProduct?.url,
          });
          shopResult.data.commitments = commitment;
          shopResult.data.products = product;
        }

        if (!shopResult || shopResult.error)
          throw new Error(shopResult?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        if (
          window.location.pathname.endsWith('/edit') ||
          isThereSomethingAfterSlashFunction(window.location.pathname)
        ) {
          this.distributeResponseData(shopResult.data as IResponseShopData);
        }
        // this.editorData = result.data;
        this.isLoading = false;
      } catch (e) {
        this.parent?.notifications.showError(getErrorMessage(e));
        this.parent?.history.push(this.menu.url);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  fetchItems = () => {
    this.fetchItemsCancel();

    this.fetchItemsInstance = flow(function* (this: ShopEntity) {
      this.isLoading = true;
      this.error = '';
      this.selected = [];

      try {
        // loading entity
        if (!this.api?.list?.url || !fetchItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const filter = this.getFilters();

        const result: IEntityFetchFunctionResult = yield this.parent?.auth?.withToken(fetchItemsFn, {
          url: this.api?.list?.url || '',
          filter,
          page: this.page,
          count: this.items,
          sortBy: this.sortBy,
          sortDir: this.sortDir,
          query: this.searchShopInputValue,
        });

        if (!result || result.error) throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.data = result?.data?.list || [];
        this.filterData = result?.filterData || {};
        this.totalCount = result?.data?.totalCount || 0;

        // Loading references (if any)
        const references = this.fields
          .filter(
            field =>
              field.type &&
              Object.prototype.hasOwnProperty.call(ENTITY_REFERENCE_FIELDS, field.type) &&
              this.references[field.name]?.getMany,
          )
          .map(async field => ({
            [field.name]: await this.references[field.name].getMany(this),
          }));

        const refResults = yield Promise.all(references);

        this.referenceData = refResults.reduce(
          (obj: Record<string, any>, res: Record<string, any>) => ({
            ...obj,
            ...res,
          }),
          {},
        );

        // updating field reference data
        this.fields = this.fields.map(field =>
          this.referenceData[field.name]
            ? {
                ...field,
                options: { referenceData: this.referenceData[field.name] },
              }
            : field,
        );

        // finished
        this.isLoading = false;
      } catch (e) {
        this.parent?.notifications.showError(getErrorMessage(e));
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  onChangeSearchInputValue = (value: string): void => {
    this.searchShopInputValue = value;
  };

  @action
  changeCheckboxRowValue = (object: Map<string, any>, block: ItemEnum) => {
    return async (id: string) => {
      const fieldData = object.get(id);
      if (!fieldData) {
        throw Error('There is no corresponding object');
      }
      fieldData.checkbox = !fieldData.checkbox;
      if (this.currentShopId) {
        try {
          const response = await this.updateItemOnBackend({ ...fieldData, id }, block);
          if (response?.status === 200) {
            this.parent?.notifications.showSuccess(i18n.t('messages:The item updated successfully'));
          } else {
            throw Error(response.message);
          }
        } catch (e) {
          this.parent?.notifications.showError(getErrorMessage(e));
          return;
        }
      }
      object.set(id, fieldData);
    };
  };

  @action
  changeCategoriesIn = (value: string) => {
    this.categoriesInput = value;
  };

  @action
  async uploadCategories() {
    if (this.isModalVisible?.categoryModal) {
      this.isLoading = true;
      try {
        const result = await this.parent?.auth?.withToken(getCategories, {
          url: API_ROUTES.category,
          name: this.categoriesInput,
        });

        this.categoryOptions = result;
      } catch (e) {
        this.parent?.notifications.showError(getErrorMessage(e));
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    }
  }

  @action
  onShopSave = async (data): Promise<void> => {
    this.isLoading = true;
    try {
      let result;
      if (this.currentShopId) {
        result = await this.parent?.auth?.withToken(updateShopFn, {
          url: `${API_ROUTES.shop}/${this.currentShopId}`,
          data,
        });
        if (result.data.status) {
          this.parent?.notifications.showSuccess(i18n.t('messages:Shop updated successfully'));
        }
      } else {
        result = await this.parent?.auth?.withToken(createShopFn, {
          url: API_ROUTES.shop,
          data,
        });
        if (result.data.status) {
          this.parent?.notifications.showSuccess(i18n.t('messages:Shop created successfully'));
        }
      }

      this.parent?.history.push('/shops');
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
    } finally {
      this.isLoading = false;
      this.fetchItems();
    }
  };

  @action
  onUploadImage = async (file: File): Promise<string> => {
    this.isLoading = true;
    try {
      const result = await this.parent?.auth?.withToken(uploadFileFn, {
        url: API_ROUTES.uploadFile,
        file,
      });
      this.parent?.notifications.showSuccess(i18n.t('messages:Image uploaded successfully'));
      return result;
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
    } finally {
      this.isLoading = false;
    }
    return '';
  };

  @action
  gatheringAllData = () => {
    const finalShopObject = {
      [FieldsEnum.TAG]: this.tagValue,
      [FieldsEnum.PRODUCT_DESCRIPTION]: this.productsDescriptionData.get('productDescription')?.value.trim(),
      [FieldsEnum.COORDINATE]: {
        lat: String(this.coordinate.get('lat')),
        lng: String(this.coordinate.get('lng')),
      },
      [FieldsEnum.IMAGES]: finalShopThumbnailsData(this.shopThumbnails),
      [FieldsEnum.IS_HIDE]: this.isHideShop,
    };

    Object.assign(finalShopObject, this.prepareFields());

    return {
      shop: finalShopObject,
      categories: finalCategoryData(this.categoryMainInfoItems),
      products: finalItemData(this.productData),
      commitments: finalItemData(this.shopCommitmentData),
      urls: finalUrlArray(this.urls),
    };
  };

  @action
  validateAllFields() {
    super.validateAllFields();
    this.publicData.map(groupFields => {
      this.validateEachField(groupFields);
    });
    this.shopThumbnails.forEach(value => {
      if (!value.imageKey) {
        this.errorCounter++;
        value.error = 'common:Fill in the field';
      }
    });
  }

  prepareFields(): {} {
    const noPublicFieldsData = super.prepareFields();
    const finalObj = {};
    const data = this.publicData.map(item => noPublicFinalData(item));
    data.forEach(item => Object.assign(finalObj, item));
    return Object.assign(finalObj, noPublicFieldsData);
  }

  @action
  onCreateButtonClick = async () => {
    this.validateAllFields();
    if (this.errorCounter === 0) {
      const data = this.gatheringAllData();
      await this.onShopSave(data);
    } else {
      this.parent?.notifications.showError(i18n.t('messages:Please fill in all fields'));
    }
  };

  @action
  setCoordinates = (lat: number, lng: number, address?: string) => {
    this.coordinate.set('lat', lat);
    this.coordinate.set('lng', lng);
    const obj = this.contactInfoData.get('address');
    if (address && obj) {
      obj.value = address;
      this.contactInfoData.set('address', obj);
    }
  };

  @action
  resetSelectedItemData = () => {
    this.selectedItemData = null;
  };

  @action
  toggleModalVisibility = (modal: ModalEnum): (() => void) => {
    return () => {
      this.isModalVisible[modal] = !this.isModalVisible[modal];
    };
  };

  @action
  onChangeFieldValue = (object: Map<string, any>) => {
    return (field: string, value: string) => {
      const fieldData = object.get(field);
      if (!fieldData) {
        throw Error('There is no corresponding field');
      }
      fieldData.value = value;
      fieldData.error = '';
      object.set(field, fieldData);
    };
  };

  @action
  addItem = (
    mapObj: Map<string, Omit<IItemData, 'id'>>,
    modal: ModalEnum,
    block?: ItemEnum,
  ): ((obj: IItemData) => void) => {
    return async obj => {
      if (this.currentShopId && block && !this.selectedItemData) {
        const response = await this.addItemToBackend(obj, block);
        if (response?.status !== 200) return;
      }

      if (this.currentShopId && block && this.selectedItemData) {
        const response = await this.updateItemOnBackend(obj, block);
        if (response?.status !== 200) return;
      }
      const { id, ...rest } = obj;
      mapObj.set(id, rest);
      this.toggleModalVisibility(modal)();
    };
  };

  @action
  urlActions = (
    mapObj: Map<string, Omit<IUrlItem, 'id'>>,
    modal: ModalEnum,
  ): ((action: UrlActionsEnum, item: IUrlItem) => void) => {
    return async (action, { id, ...restUrlITem }) => {
      if (action === UrlActionsEnum.CREATE) {
        mapObj.set(id, restUrlITem);
        this.toggleModalVisibility(modal)();
      }
      if (action === UrlActionsEnum.EDIT) {
        mapObj.set(id, restUrlITem);
        this.toggleModalVisibility(modal)();
      }
      if (action === UrlActionsEnum.DELETE) {
        mapObj.delete(id);
      }
    };
  };

  @action
  changeCheckboxUrlValue = (object: Map<string, Omit<IUrlItem, 'id'>>) => {
    return (id: string) => {
      const fieldData = object.get(id);
      if (!fieldData) {
        throw Error('There is no corresponding object');
      }
      fieldData.isHide = !fieldData.isHide;
      object.set(id, fieldData);
    };
  };

  @action
  deleteItemFromBackend = async (id: string, block: ItemEnum) => {
    this.isLoading = true;

    let url;
    if (block === ItemEnum.PRODUCT) {
      url = `${API_ROUTES.product}/${id}`;
    } else if (block === ItemEnum.COMMITMENT) {
      url = `${API_ROUTES.commitment}/${id}`;
    }

    try {
      const response = await this.parent?.auth?.withToken(deleteItemFn, {
        url,
      });

      if (!response || response.error) {
        throw new Error(response.error);
      }
      this.parent?.notifications.showSuccess(i18n.t('messages:The item deleted successfully'));
      return response;
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  deleteItem = (map: Map<string, any>, block?: ItemEnum) => {
    return async (id: string) => {
      if (this.currentShopId && block) {
        const response = await this.deleteItemFromBackend(id, block);
        if (response.status !== 200) return;
      }

      map.delete(id);
    };
  };

  @action
  changeActiveBreadcrumbs = (activeBreadcrumbs: BreadcrumbsEnum) => {
    this.activeBreadcrumbs = activeBreadcrumbs;
  };

  @action
  onEditClickHandler = (map: Map<string, any>, modal: ModalEnum) => {
    return id => {
      const selectedData = map.get(id);
      if (selectedData) {
        selectedData.id = id;
        this.selectedItemData = selectedData;
        this.toggleModalVisibility(modal)();
      }
    };
  };

  @action
  addShopThumbnailBlock = () => {
    this.shopThumbnails.set(uuidv4(), SHOP_THUMBNAIL_DATA);
  };

  @action
  addShopThumbnailImage = async (id: string, file: File): Promise<void> => {
    const shopThumbnailObj = this.shopThumbnails.get(id);
    if (shopThumbnailObj) {
      shopThumbnailObj.imageKey = await this.onUploadImage(file);
      shopThumbnailObj.error = '';
    }
  };

  @action
  onChangeShopThumbnailDescription = (id: string, value: string): void => {
    const mapObj = this.shopThumbnails.get(id);
    if (!mapObj) {
      throw Error('There is no corresponding id');
    }
    mapObj.error = '';
    mapObj.value = value;
    this.shopThumbnails.set(id, mapObj);
  };

  @action
  onSaveBase64 = (id: string, base64: string) => {
    const shopThumbnailObj = this.shopThumbnails.get(id);
    if (shopThumbnailObj) {
      shopThumbnailObj.imageBase64 = base64;
      this.shopThumbnails.set(id, shopThumbnailObj);
    }
  };

  @action
  deleteImage = id => {
    const shopThumbnailObj = this.shopThumbnails.get(id);
    if (shopThumbnailObj) {
      shopThumbnailObj.imageBase64 = '';
      shopThumbnailObj.imageKey = '';
      shopThumbnailObj.imageUrl = '';
    }
  };

  @action
  onTagValueChange = (value: string) => {
    this.tagValue = value;
  };

  @action
  addCategoryItem = (obj: CategoryType) => {
    this.categoryMainInfoItems.set(uuidv4(), obj);
    this.toggleModalVisibility(ModalEnum.categoryModal)();
  };

  @action
  updateItemOnBackend = async ({ id, ...data }: IItemData, block: ItemEnum) => {
    this.isLoading = true;
    let url;
    if (block === ItemEnum.PRODUCT) {
      url = `${API_ROUTES.product}/${id}`;
    } else if (block === ItemEnum.COMMITMENT) {
      url = `${API_ROUTES.commitment}/${id}`;
    }

    Object.assign(data, { shopId: this.currentShopId });
    try {
      const response = await this.parent?.auth?.withToken(updateItemFn, {
        url,
        data,
      });

      if (!response || response.error) {
        throw new Error(response.error);
      }
      this.parent?.notifications.showSuccess(i18n.t('messages:The item updated successfully'));
      return response;
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  addItemToBackend = async (data: IItemData, block: ItemEnum) => {
    this.isLoading = true;

    let url;
    if (block === ItemEnum.PRODUCT) {
      url = API_ROUTES.product;
    } else if (block === ItemEnum.COMMITMENT) {
      url = API_ROUTES.commitment;
    }

    Object.assign(data, { shopId: this.currentShopId });

    try {
      const response = await this.parent?.auth?.withToken(addItemFn, {
        url,
        data,
      });

      if (!response || response.error) {
        throw new Error(response.error);
      }

      this.parent?.notifications.showSuccess(i18n.t('messages:The item uploaded successfully'));
      return response;
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  changeCustomListFields = (data?: ShopFieldType) => {
    const objectValue = data ? data : JSON.parse(localStorage.getItem('custom-fields') || '{}');
    Object.assign(this.customListFields, objectValue);
  };

  @action
  applyCustomFieldsVisibility = (selectedFieldsData?: ShopFieldType) => {
    this.changeCustomListFields(selectedFieldsData);

    this.fields = this.fields.map(item => {
      if (selectedFieldsData) {
        item.hideInList = !selectedFieldsData[item.label!];
      } else {
        item.hideInList = !this.customListFields[item.label!];
      }
      return item;
    });

    // If the modal is open then we add to localStorage the data otherwise - don't(constructor executes)
    if (this.isModalVisible[ModalEnum.fieldsModal] && selectedFieldsData) {
      this.toggleModalVisibility(ModalEnum.fieldsModal)();
      localStorage.setItem('custom-fields', JSON.stringify(selectedFieldsData));
    }
  };

  @computed
  get ThumbnailsBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <>
          {Array.from(this.shopThumbnails.entries()).map(([id, shopThumbnails]: [string, IShopThumbnail]) => (
            <ThumbnailBlock
              onChangeValue={this.onChangeFieldValue(this.shopThumbnails)}
              key={id}
              id={id}
              isViewPage={isViewPage}
              {...shopThumbnails}
            >
              <ImageUploader
                location={`shop-thumbnails-${id}`}
                imagePxSize={112}
                id={id}
                error={shopThumbnails.error}
                onUploadImage={this.addShopThumbnailImage}
                onSaveBase64={this.onSaveBase64}
                deleteImage={this.deleteImage}
                isViewPage={isViewPage}
                imageData={shopThumbnails.imageBase64 || shopThumbnails.imageUrl}
              />
            </ThumbnailBlock>
          ))}
        </>
      );
    });
  }

  @computed
  get Thumbnails() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer
          headerTitle={'fields:Shop thumbnails (max count - 4)'}
          onAddClickFn={isViewPage ? undefined : this.addShopThumbnailBlock}
          isButtonShown={!isViewPage}
          disabledButton={isViewPage ? undefined : this.shopThumbnails.size >= 4}
        >
          <this.ThumbnailsBlock isViewPage={isViewPage} />
        </BlockContainer>
      );
    });
  }

  @computed
  get TopMainInfoBlock() {
    return observer(({ isViewPage }: { isViewPage: boolean | undefined }) => {
      return (
        <BlockContainer headerTitle={'fields:Main info'} isButtonShown={false}>
          <TopMainInfoContainer
            isViewPage={isViewPage}
            fields={this.topMainInfoData}
            onChangeFieldValue={this.onChangeFieldValue(this.topMainInfoData)}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get CategoryBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <CategoryMainInfoBlock
          toggleModalVisibility={this.toggleModalVisibility(ModalEnum.categoryModal)}
          disabledAddingButton={this.categoryMainInfoItems.size >= 4}
          isViewPage={isViewPage}
        >
          {Array.from(this.categoryMainInfoItems.entries()).map(([id, category]: [string, any]) => {
            return (
              <CategoryItem
                key={id}
                id={id}
                name={category.label}
                isViewPage={isViewPage}
                deleteCategoryItemFn={this.deleteItem(this.categoryMainInfoItems)}
              />
            );
          })}
          {this.isModalVisible[ModalEnum.categoryModal] && (
            <Modal onCloseButtonClickFn={this.toggleModalVisibility(ModalEnum.categoryModal)}>
              <CategoryModalContentCard
                addCategory={this.addCategoryItem}
                options={this.categoryOptions}
                loadingCategoryByValue={this.changeCategoriesIn}
              />
            </Modal>
          )}
        </CategoryMainInfoBlock>
      );
    });
  }

  @computed
  get TagBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <TagMainInfoBlock
          onTagValueChange={this.onTagValueChange}
          mainInfoTagValue={this.tagValue}
          isViewPage={isViewPage}
        />
      );
    });
  }

  @computed
  get MiddleMainInfoBlock() {
    return observer(({ isViewPage }: { isViewPage: boolean | undefined }) => {
      return (
        <BlockContainer isButtonShown={false}>
          <MiddleMainInfoContainer>
            <this.CategoryBlock isViewPage={isViewPage} />
            <this.TagBlock isViewPage={isViewPage} />
          </MiddleMainInfoContainer>
        </BlockContainer>
      );
    });
  }

  @computed
  get BottomMainInfoBlock() {
    return observer(({ isViewPage }: { isViewPage: boolean | undefined }) => {
      return (
        <BlockContainer isButtonShown={false}>
          <BottomMainInfoContainer
            isViewPage={isViewPage}
            fields={this.bottomMainInfoData}
            onChangeFieldValue={this.onChangeFieldValue(this.bottomMainInfoData)}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get MainInfoBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <>
          <this.TopMainInfoBlock isViewPage={isViewPage} />
          <this.MiddleMainInfoBlock isViewPage={isViewPage} />
          <this.BottomMainInfoBlock isViewPage={isViewPage} />
        </>
      );
    });
  }

  @computed
  get ShopCommitmentBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer
          isButtonShown={!isViewPage}
          headerTitle={isViewPage ? '' : 'fields:Shop commitment'}
          onAddClickFn={isViewPage ? undefined : this.toggleModalVisibility(ModalEnum.shopCommitmentModal)}
        >
          <Table headers={ItemTable}>
            {Array.from(this.shopCommitmentData.entries()).map(
              ([id, shopCommitment]: [string, Omit<IItemData, 'id'>]) => (
                <TableRowItem
                  key={id}
                  id={id}
                  {...shopCommitment}
                  isViewPage={isViewPage}
                  onChangeCheckbox={this.changeCheckboxRowValue(this.shopCommitmentData, ItemEnum.COMMITMENT)}
                  onDeleteClick={this.deleteItem(this.shopCommitmentData, ItemEnum.COMMITMENT)}
                  onEditClick={this.onEditClickHandler(this.shopCommitmentData, ModalEnum.shopCommitmentModal)}
                />
              ),
            )}
          </Table>
          {this.isModalVisible[ModalEnum.shopCommitmentModal] && (
            <Modal onCloseButtonClickFn={this.toggleModalVisibility(ModalEnum.shopCommitmentModal)}>
              <ModalContent
                title={`${this.selectedItemData ? 'Edit' : 'Create'} Shop Commitment`}
                selectedItem={this.selectedItemData as IItemData}
                addItem={this.addItem(this.shopCommitmentData, ModalEnum.shopCommitmentModal, ItemEnum.COMMITMENT)}
                onUploadImage={this.onUploadImage}
                cleanSelectedItem={this.resetSelectedItemData}
              />
            </Modal>
          )}
        </BlockContainer>
      );
    });
  }

  @computed
  get Products() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <>
          <BlockContainer
            isButtonShown={!isViewPage}
            headerTitle={isViewPage ? '' : 'fields:Products'}
            onAddClickFn={isViewPage ? undefined : this.toggleModalVisibility(ModalEnum.productModal)}
          >
            <FieldsContainer
              style={{ gridTemplateColumns: ' 1fr' }}
              fields={this.productsDescriptionData}
              onChangeFieldValue={this.onChangeFieldValue(this.productsDescriptionData)}
              isViewPage={isViewPage}
            />
          </BlockContainer>
          <BlockContainer isButtonShown={false}>
            <Table headers={ItemTable}>
              {Array.from(this.productData.entries()).map(([id, product]: [string, Omit<IItemData, 'id'>]) => (
                <TableRowItem
                  key={id}
                  id={id}
                  {...product}
                  isViewPage={isViewPage}
                  onChangeCheckbox={this.changeCheckboxRowValue(this.productData, ItemEnum.PRODUCT)}
                  onDeleteClick={this.deleteItem(this.productData, ItemEnum.PRODUCT)}
                  onEditClick={this.onEditClickHandler(this.productData, ModalEnum.productModal)}
                />
              ))}
            </Table>
          </BlockContainer>
          {this.isModalVisible[ModalEnum.productModal] && (
            <Modal onCloseButtonClickFn={this.toggleModalVisibility(ModalEnum.productModal)}>
              <ModalContent
                title={`${this.selectedItemData ? 'Edit' : 'Create'} Product`}
                selectedItem={this.selectedItemData ? (this.selectedItemData as IItemData) : null}
                addItem={this.addItem(this.productData, ModalEnum.productModal, ItemEnum.PRODUCT)}
                onUploadImage={this.onUploadImage}
                cleanSelectedItem={this.resetSelectedItemData}
              />
            </Modal>
          )}
        </>
      );
    });
  }

  @computed
  get ContactInfoBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer headerTitle={'fields:Contact info'} isButtonShown={false}>
          <FieldsContainer
            isViewPage={isViewPage}
            fields={this.contactInfoData}
            onChangeFieldValue={this.onChangeFieldValue(this.contactInfoData)}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get PriceBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer headerTitle={'fields:Price'} isButtonShown={false}>
          <Price
            fields={this.priceData}
            onChangeFieldValue={this.onChangeFieldValue(this.priceData)}
            isViewPage={isViewPage}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get BreadcrumbsBlock() {
    return observer(() => {
      return (
        <Breadcrumbs
          crumbItems={BREADCRUMBS_ITEMS}
          active={this.activeBreadcrumbs}
          onClick={this.changeActiveBreadcrumbs}
        />
      );
    });
  }

  get GoogleMapsBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer isButtonShown={false}>
          <GoogleMaps
            isViewPage={isViewPage}
            setCoordinates={this.setCoordinates}
            coordinate={this.coordinate}
            address={this.contactInfoData.get('address')?.value || ''}
          />
        </BlockContainer>
      );
    });
  }

  @computed
  get LinkBlock() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <BlockContainer
          isButtonShown={!isViewPage}
          headerTitle={'fields:Links'}
          onAddClickFn={isViewPage ? undefined : this.toggleModalVisibility(ModalEnum.urlModal)}
        >
          <Table headers={UrlTable}>
            {Array.from(this.urls.entries()).map(([id, shopCommitment]: [string, Omit<IUrlItem, 'id'>]) => (
              <TableRowLink
                key={id}
                id={id}
                {...shopCommitment}
                isViewPage={isViewPage}
                onChangeCheckbox={this.changeCheckboxUrlValue(this.urls)}
                onDeleteClick={this.urlActions(this.urls, ModalEnum.urlModal)}
                onEditClick={this.onEditClickHandler(this.urls, ModalEnum.urlModal)}
              />
            ))}
          </Table>

          {this.isModalVisible[ModalEnum.urlModal] && (
            <Modal onCloseButtonClickFn={this.toggleModalVisibility(ModalEnum.urlModal)}>
              <UrlModalContent
                title={`${this.selectedItemData ? 'Edit' : 'Create'} Url Link`}
                selectedItem={this.selectedItemData as IUrlItem}
                addItem={this.urlActions(this.urls, ModalEnum.urlModal)}
                cleanSelectedItem={this.resetSelectedItemData}
              />
            </Modal>
          )}
        </BlockContainer>
      );
    });
  }

  @computed
  get IsShopVisibleCheckbox() {
    return observer(({ isViewPage }: { isViewPage?: boolean }) => {
      return (
        <ShopVisibleCheckboxContainer
          toggleCheckbox={this.toggleShopVisibility}
          checkboxValue={this.isHideShop}
          isViewPage={isViewPage ?? false}
        />
      );
    });
  }

  @computed
  get CreatorBody() {
    return observer(() => {
      if (!this.canView) return this.forbiddenPlaceholder;

      return (
        <PageContainer cleanStore={this.clearData} isLoading={this.isLoading}>
          <HeadButton onButtonClick={this.onCreateButtonClick} />
          <this.BreadcrumbsBlock />
          {this.activeBreadcrumbs === BreadcrumbsEnum.publicInfo && (
            <>
              <this.IsShopVisibleCheckbox />
              <this.Thumbnails />
              <this.MainInfoBlock />
              <this.ShopCommitmentBlock />
              <this.Products />
              <this.PriceBlock />
              <this.ContactInfoBlock />
              <this.LinkBlock />
              <this.GoogleMapsBlock />
            </>
          )}
          {this.activeBreadcrumbs === BreadcrumbsEnum.noPublicInfo && <this.NoPublicInfoBlock />}
        </PageContainer>
      );
    });
  }

  @action
  deleteShopById = async (id: string) => {
    this.isLoading = true;
    try {
      let result;
      result = await this.parent?.auth?.withToken(deleteItemFn, {
        url: `${API_ROUTES.shop}/${id}`,
      });

      if (result.status) {
        this.parent?.notifications.showSuccess(i18n.t('messages:Shop deleted successfully'));
      }

      this.parent?.history.push('/shops');
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
    } finally {
      this.isLoading = false;
      this.fetchItems();
    }
  };

  @computed
  get Viewer() {
    return observer(({ id }: { id: string }) => (
      <PageContainer getItem={this.getItem} id={id} cleanStore={this.clearData} isLoading={this.isLoading}>
        <this.BreadcrumbsBlock />
        {this.activeBreadcrumbs === BreadcrumbsEnum.publicInfo && (
          <>
            {this.parent?.auth?.user?.role !== ShopRole.Affiliate_Planner &&
              this.parent?.auth?.user?.role !== ShopRole.Business_Owner && (
                <DeleteButton onButtonClick={() => this.deleteShopById(id)} />
              )}
            <this.IsShopVisibleCheckbox isViewPage={true} />
            <this.Thumbnails isViewPage={true} />
            <this.MainInfoBlock isViewPage={true} />
            <this.ShopCommitmentBlock isViewPage={true} />
            <this.Products isViewPage={true} />
            <this.PriceBlock isViewPage={true} />
            <this.ContactInfoBlock isViewPage={true} />
            <this.LinkBlock isViewPage />
            <this.GoogleMapsBlock isViewPage={true} />
          </>
        )}
        {this.activeBreadcrumbs === BreadcrumbsEnum.noPublicInfo && <this.NoPublicInfoBlock isViewPage={true} />}
      </PageContainer>
    ));
  }

  @computed
  get EditorBody() {
    return observer(({ id }: { id: string }) => {
      if (!this.canEdit) return this.forbiddenPlaceholder;

      return (
        <PageContainer getItem={this.getItem} id={id} cleanStore={this.clearData} isLoading={this.isLoading}>
          <HeadButton onButtonClick={this.onCreateButtonClick} isEdit={true} />
          <this.BreadcrumbsBlock />
          {this.activeBreadcrumbs === BreadcrumbsEnum.publicInfo && (
            <>
              <this.IsShopVisibleCheckbox />
              <this.Thumbnails />
              <this.MainInfoBlock />
              <this.ShopCommitmentBlock />
              <this.Products />
              <this.PriceBlock />
              <this.ContactInfoBlock />
              <this.LinkBlock />
              <this.GoogleMapsBlock />
            </>
          )}
          {this.activeBreadcrumbs === BreadcrumbsEnum.noPublicInfo && <this.NoPublicInfoBlock />}
        </PageContainer>
      );
    });
  }

  @computed
  get ListHead() {
    return observer(() => (
      <CustomEntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListBody() {
    return observer(() => {
      return (
        <>
          {this.isModalVisible[ModalEnum.fieldsModal] && (
            <Modal onCloseButtonClickFn={this.toggleModalVisibility(ModalEnum.fieldsModal)}>
              <CustomFieldsModalContent
                fields={this.fields}
                applyCustomFieldsVisibilityFn={this.applyCustomFieldsVisibility}
                customListFields={this.customListFields}
              />
            </Modal>
          )}
          <ShopListInteractionBlock
            onChangeFieldValue={this.onChangeSearchInputValue}
            value={this.searchShopInputValue}
            onOpenModalButton={this.toggleModalVisibility(ModalEnum.fieldsModal)}
            placeholder={i18n.t('placeholder:Business ID, Company Name, Address, Shop Name, Planner ID, Category')}
          />
          <CustomEntityList
            fields={this.fields}
            data={this.data}
            extra={this.ListExtra}
            isLoading={this.isLoading}
            url={this.menu.url}
            selected={this.selected}
            sortBy={this.sortBy}
            sortDir={this.sortDir}
            canView={this.viewable && this.canView}
            canEdit={this.editable && this.canEdit}
            canSelect={this.selectable}
            setSelected={this.setSelected}
            onSortChange={this.setSort}
            withToken={this.parent?.auth?.withToken}
            entity={this}
          />
        </>
      );
    });
  }
}

export { ShopEntity };
