import { fetchItemsFn, updateItemsFn } from '~/utils/api';
import { BANK_TRANSFERS_FIELDS } from './fields';
import { BankTransfersEntity } from '~/pages/bank-transfers/components/BankTransfersEntity';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';

export default new BankTransfersEntity({
  title: 'Bank transfers',
  selectable: true,
  items: 50,
  api: {
    list: { url: API_ROUTES.bankTransfers, method: 'get' },
    update: { url: API_ROUTES.bankTransfers, method: 'put' },
  },
  menu: {
    label: 'Bank transfers',
    url: '/bank-transfers',
    enabled: true,
  },
  fields: BANK_TRANSFERS_FIELDS,
  fetchItemsFn,
  updateItemsFn,
  roles: [UserRole.Admin, UserRole.SuperAdmin],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin],
  },
});
