import * as React from 'react';

interface PropTypes {
  size?: number;
  className?: string;
}

const Component: React.FC<PropTypes> = ({ className, size = 24, ...props }: PropTypes) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.537 19.567A9.962 9.962 0 0112 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c0 2.136-.67 4.116-1.81 5.74L17 12h3a8 8 0 10-2.46 5.772l.997 1.795z"
      fill="#AEA69E"
    />
  </svg>
);

export default Component;
