import { createItemsFn, fetchItemsFn, getItemsFn, updateItemsFn } from '~/utils/api';
import { QR_CODES_FIELDS } from './fields';
import { QrCodesEntity } from './components/QrCodesEntity';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';

export default new QrCodesEntity({
  title: 'QR Codes',
  viewable: false,
  editable: true,
  creatable: true,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.qrCodes, method: 'get' },
    get: { url: API_ROUTES.qrCodes, method: 'get' },
    create: { url: API_ROUTES.qrCodes, method: 'post' },
    update: { url: API_ROUTES.qrCodes, method: 'put' },
    delete: { url: API_ROUTES.qrCodes, method: 'delete' },
  },
  menu: {
    label: 'QR Codes',
    url: '/qr-codes',
    enabled: true,
  },
  fields: QR_CODES_FIELDS,
  fetchItemsFn,
  getItemsFn,
  createItemsFn,
  updateItemsFn,
  roles: [UserRole.Business, UserRole.Admin, UserRole.SuperAdmin],
  permissions: {
    list: [UserRole.Business, UserRole.Admin, UserRole.SuperAdmin],
    create: [UserRole.Business, UserRole.Admin, UserRole.SuperAdmin],
    update: [UserRole.Business, UserRole.Admin, UserRole.SuperAdmin],
    view: [UserRole.Business, UserRole.Admin, UserRole.SuperAdmin],
  },
});
