import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { useTranslation } from 'react-i18next';
import {
  TRANSFER_REQUEST_STATUS_NAMES,
  TRANSFER_REQUEST_STATUS_NAME_COLORS,
  WITHDRAW_REQUEST_STATUS_NAMES,
  WITHDRAW_REQUEST_STATUS_NAME_COLORS,
} from '~/utils/constants/requests.constants';

type IProps = IEntityFieldProps;

const TransactionColoredStatus: FC<IProps> = observer(({ data }) => {
  const { t } = useTranslation();

  const withdraw = data?.withdrawRequest;
  const transfer = data?.transferRequest;

  if (withdraw) {
    return (
      <div style={{ color: WITHDRAW_REQUEST_STATUS_NAME_COLORS[withdraw.status] }}>
        {t(`fields:${WITHDRAW_REQUEST_STATUS_NAMES[withdraw.status]}`)}
      </div>
    );
  }

  if (transfer) {
    return (
      <div style={{ color: TRANSFER_REQUEST_STATUS_NAME_COLORS[transfer.status] }}>
        {t(`fields:${TRANSFER_REQUEST_STATUS_NAMES[transfer.status]}`)}
      </div>
    );
  }
  return <></>;
});

export { TransactionColoredStatus };
