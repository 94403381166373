import { IEntityFetchFunctionProps } from 'icerockdev-admin-toolkit';

export interface IShopThumbnail {
  value: string;
  imageBase64: string;
  imageKey: string;
  imageUrl: string;
  error: string;
  required: boolean;
  limit: number;
}

export interface ICharacterLimit {
  name: number;
  description: number;
  tag: number;
}

export interface IUrlLimit {
  title: number;
  link: number;
}

type additionalFieldType = {
  label: string;
  value: string;
};

export interface IInputField {
  field: FieldsEnum;
  value: string;
  error: string;
  required: boolean;
  limit: number;
  type: string | number;
  labelSeparate: string;
  placeholder: string;
  regexp?: string;
  additionalFields?: additionalFieldType[];
  rows?: number;
}

export enum ModalEnum {
  categoryModal = 'categoryModal',
  shopCommitmentModal = 'shopCommitmentModal',
  productModal = 'productModal',
  urlModal = 'urlModal',
  fieldsModal = 'fieldsModal',
}

export type ModalTypes = {
  [ModalEnum.categoryModal]: boolean;
  [ModalEnum.shopCommitmentModal]: boolean;
  [ModalEnum.productModal]: boolean;
  [ModalEnum.urlModal]: boolean;
  [ModalEnum.fieldsModal]: boolean;
};

export interface IItemData {
  id: string;
  imageBase64: string;
  imageKey: string;
  name: string;
  description: string;
  checkbox: boolean;
  imageUrl: string;
}

export enum BreadcrumbsEnum {
  publicInfo = 'Public info',
  noPublicInfo = 'No public info',
}

export type BreadcrumbsType = {
  name: BreadcrumbsEnum;
};

export interface IUploadFileFn {
  (options: { url: string; file: File; token: string }): Promise<string> | undefined;
}

export interface IRadio {
  field: string;
  value: string;
  error: string;
  labelSeparate: string;
  additionalFields: IRadioFields[];
}

export type IRadioFields = {
  id: string;
  value: string;
};

export enum InputTypesEnum {
  phone = 'PHONE',
  selector = 'SELECTOR',
  textInput = 'TEXT_INPUT',
  numberInput = 'NUMBER_INPUT',
  date = 'DATE',
  textareaInput = 'TEXTAREA_INPUT',
  checkbox = 'CHECKBOX',
}

export type ImagesType = { description: string; imageKey: string };
export type ProductsType = { description: string; imageKey: string; name: string; isHide: boolean };

export interface IResponseShopData {
  public: any;
  nonPublic: any;
  commitments: IItemData[];
  products: IItemData[];
  urls: {
    id: number;
    shopId: number;
    title: string;
    link: string;
    isHide: boolean;
    createdAt: string;
  }[];
  categories: {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
  }[];
}

export interface IItemsData {
  imageKey: string;
  name: string;
  description: string;
  isHide: boolean;
}

export interface CreateShopRequest {
  shop: ShopRequestData;
  categories: number[];
  products?: IItemsData[]; // null
  commitments?: IItemsData[]; // null
}

export interface IFirstObject {
  [FieldsEnum.IMAGES]: { key: string; description: string }[];
  [FieldsEnum.TAG]?: string;
  [FieldsEnum.PRODUCT_DESCRIPTION]?: string;
  [FieldsEnum.COORDINATE]?: { lat: string; lng: string };
}

export interface INoPublicData {
  [FieldsEnum.MEMBERSHIP_BENEFITS]: string; // null
}

export interface ShopRequestData {
  [FieldsEnum.NAME]: string;
  [FieldsEnum.IMAGES]: { key: string; description: string }[]; // min1 max4
  [FieldsEnum.SHORT_DESCRIPTION]?: string; // null
  [FieldsEnum.DESCRIPTION]?: string; // null
  [FieldsEnum.REFERENCE_PRICE]?: string; // null
  [FieldsEnum.ACCESS]?: string; // null
  [FieldsEnum.IS_HIDE]?: boolean; // null
  [FieldsEnum.BUDGET]?: string; // null
  [FieldsEnum.POST_CODE]?: string; // null
  [FieldsEnum.PREFECTURE]?: string; // null
  [FieldsEnum.SHOP_CONTACT_PERSON]?: string; // null
  [FieldsEnum.MEMBERSHIP_BENEFITS]?: string; // null
  [FieldsEnum.SHOP_ATMOSPHERE]?: string; // null
  [FieldsEnum.OUTLINE_SHOP_ATMOSPHERE]?: string; // null
  [FieldsEnum.PRODUCT_DESCRIPTION]?: string; // null
  [FieldsEnum.PRICE_DESCRIPTION]?: string; // null
  [FieldsEnum.ADDRESS]?: string; // null
  [FieldsEnum.COORDINATE]?: { lat: string; lng: string };
  [FieldsEnum.PHONE]?: string; // null
  [FieldsEnum.BUSINESS_HOURS]?: string; // null
  [FieldsEnum.WEBSITE]?: string; // null
  [FieldsEnum.STORE_REPRESENTATIVE_NAME]?: string; // null
  [FieldsEnum.TAG]?: string; // null
  [FieldsEnum.SALES_PLANNER_ID]: number;
  [FieldsEnum.SITE_PLANNER_A_ID]?: number; // null
  [FieldsEnum.SITE_PLANNER_B_ID]?: number; // null
  [FieldsEnum.AFFILIATE_PLANNER_ID]?: number; // null
  [FieldsEnum.BUSINESS_OWNER_ID]?: number; // null
  [FieldsEnum.BUSINESS_ID]?: number; // null
  [FieldsEnum.BUSINESS_NAME]?: string; // null
  [FieldsEnum.ESTABLISHMENT_DATE]: string;
  [FieldsEnum.SHOP_VISIT_DATE]?: string; // null
  [FieldsEnum.CAPITAL]?: number; // null
  [FieldsEnum.COMPANY_NAME]: string;
  [FieldsEnum.COMPANY_NAME_KANA]: string;
  [FieldsEnum.SHOP_NAME]: string;
  [FieldsEnum.SHOP_NAME_KANA]: string;
  [FieldsEnum.SHOP_REPRESENTATIVE_NAME]: string;
  [FieldsEnum.SHOP_REPRESENTATIVE_NAME_KANA]: string;
  [FieldsEnum.SHOP_REPRESENTATIVE_GENDER]?: number; // null
  [FieldsEnum.SHOP_ADDRESS]?: string; // null
  [FieldsEnum.FAX]?: string; // null
  [FieldsEnum.SHOP_MANAGER]?: string; // null
  [FieldsEnum.SHOP_MANAGER_KANA]?: string; // null
  [FieldsEnum.BUSINESS_CATEGORY]?: number; // null
  [FieldsEnum.BRANCH]?: string; // null
  [FieldsEnum.MULTIPLE_SHOPS]?: boolean; // false default
  [FieldsEnum.MULTIPLE_BRANCHES]?: boolean; // false default
  [FieldsEnum.IS_PARTNER_ALLOWED]?: boolean; // false default
  [FieldsEnum.RECOMMENDATION_REASON]?: string; // null
  [FieldsEnum.BUSINESS_DESCRIPTION]?: string; // null
  [FieldsEnum.BANK_NAME]: string;
  [FieldsEnum.BANK_CODE]: string;
  [FieldsEnum.BRANCH_NAME]: string;
  [FieldsEnum.BRANCH_CODE]: string;
  [FieldsEnum.BANK_TYPE]: number;
  [FieldsEnum.ACCOUNT_NUMBER]: string;
  [FieldsEnum.ACCOUNT_HOLDER_NAME]: string;
  [FieldsEnum.INDIVIDUAL_WITHDRAWAL_FEE]?: string; // null
}

export enum FieldsEnum {
  ID = 'id',
  NUMBER = 'number',
  NAME = 'name',
  SHORT_DESCRIPTION = 'shortDescription',
  DESCRIPTION = 'description',
  REFERENCE_PRICE = 'referencePrice',
  BUDGET = 'budget',
  ACCESS = 'access',
  IS_HIDE = 'isHide',
  MEMBERSHIP_BENEFITS = 'membershipBenefits',
  SHOP_ATMOSPHERE = 'shopAtmosphere',
  OUTLINE_SHOP_ATMOSPHERE = 'outlineShopAtmosphere',
  PRODUCT_DESCRIPTION = 'productDescription',
  PRICE_DESCRIPTION = 'priceDescription',
  ADDRESS = 'address',
  PREFECTURE = 'prefecture',
  POST_CODE = 'postCode',
  SHOP_CONTACT_PERSON = 'shopContactPerson',
  COORDINATE = 'coordinate',
  PHONE = 'phone',
  IMAGES = 'images',
  BUSINESS_HOURS = 'businessHours',
  WEBSITE = 'website',
  STORE_REPRESENTATIVE_NAME = 'storeRepresentativeName',
  TAG = 'tag',

  // NON-PUBLIC
  SHOP_ID = 'shopID',
  SALES_PLANNER_ID = 'salesPlannerId',
  SITE_PLANNER_A_ID = 'sitePlannerAId',
  SITE_PLANNER_B_ID = 'sitePlannerBId',
  AFFILIATE_PLANNER_ID = 'affiliatePlannerId',
  BUSINESS_OWNER_ID = 'businessOwnerId',

  //
  BUSINESS_ID = 'businessId',
  BUSINESS_NAME = 'businessName',
  BUSINESS_DESCRIPTION = 'businessDescription',
  ESTABLISHMENT_DATE = 'establishmentDate',
  SHOP_VISIT_DATE = 'shopVisitDate',
  CAPITAL = 'capital',
  COMPANY_NAME = 'companyName',
  COMPANY_NAME_KANA = 'companyNameKana',
  SHOP_ADDRESS = 'shopAddress',
  SHOP_NAME = 'shopName',
  SHOP_NAME_KANA = 'shopNameKana',
  SHOP_REPRESENTATIVE_NAME = 'shopRepresentativeName',
  SHOP_REPRESENTATIVE_NAME_KANA = 'shopRepresentativeNameKana',
  SHOP_REPRESENTATIVE_GENDER = 'shopRepresentativeGender',
  FAX = 'fax',
  SHOP_MANAGER = 'shopManager',
  SHOP_MANAGER_KANA = 'shopManagerKana',
  BUSINESS_CATEGORY = 'businessCategory',
  BRANCH = 'branch',
  MULTIPLE_SHOPS = 'multipleShops',
  MULTIPLE_BRANCHES = 'multipleBranches',
  RECOMMENDATION_REASON = 'recommendationReason',

  BANK_NAME = 'bankName',
  BANK_CODE = 'bankCode',
  BRANCH_NAME = 'branchName',
  BRANCH_CODE = 'branchCode',
  BANK_TYPE = 'bankType',
  ACCOUNT_NUMBER = 'accountNumber',
  IS_PARTNER_ALLOWED = 'isPartnerAllowed',
  ACCOUNT_HOLDER_NAME = 'accountHolderName',
  INDIVIDUAL_WITHDRAWAL_FEE = 'individualWithdrawalFee',
}

export interface IEntityCreateShopProps {
  url: string;
  token?: string;
  data: CreateShopRequest;
}

export interface IEntityCreateShopResult {
  data: Record<string, any>;
  error?: string;
}

export interface IEntityCreateItemProps {
  url: string;
  token?: string;
  data: IItemData & { shopId: string };
}

export interface IEntityCreateItemResult {
  data: Record<string, any>;
  error?: string;
}

export enum ItemEnum {
  PRODUCT = 'product',
  COMMITMENT = 'commitment',
}

export interface IUrlItem {
  id: string;
  title: string;
  link: string;
  isHide: boolean;
}

export enum UrlActionsEnum {
  DELETE = 'delete',
  CREATE = 'create',
  EDIT = 'edit',
}

export interface IEntityShopFetchFunctionProps extends IEntityFetchFunctionProps {
  query: string;
}

export interface IEntityFetchShopFunctionResult {
  data: {
    list: Record<string, any>[];
    totalCount?: number;
  };
  filterData?: Record<string, any>;
  error?: string;
}

export type IEntityShopFetchFunction = (
  props: IEntityShopFetchFunctionProps,
) => Promise<IEntityFetchShopFunctionResult>;

export type ShopFieldType = {
  [key: string]: boolean;
};
