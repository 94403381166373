import * as React from 'react';

interface PropTypes {
  size?: number;
}

const Component: React.FC<PropTypes> = ({ size = 24, ...props }: PropTypes) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636l4.95 4.95z"
      fill="#AEA69E"
    />
  </svg>
);

export default Component;
