import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { TransferRequestStatus } from '~/utils/constants/requests.constants';
import localizeAmount from '~/utils/localization/amount';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { Button, Dialog, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import CloseIcon from '~/components/icons/CloseIcon';
import { TransferRequestEntity } from '../TransferRequestEntity';
import { ITransferRequest } from '~/utils/types/requests.types';

interface IProps extends IEntityFieldProps {
  data: ITransferRequest;
  entity: TransferRequestEntity;
}

const EditingAmount: FC<IProps> = ({ value, data, entity }) => {
  const { t } = useTranslation();

  const [editTransferModal, setEditTransferModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');

  const handleModal = (): void => {
    setEditTransferModal(prevState => !prevState);
  };

  const onChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const onEditAmount = useCallback(() => {
    if (!amount) return;
    entity.editAmountRequest(data.id, data.serviceRequestId, Number(amount), t('messages:Editing transfer amount'));
  }, [amount, data, entity]);

  return (
    <>
      {data.status === TransferRequestStatus.Pending || data.status === TransferRequestStatus.ApprovedByAdmin ? (
        <div className={styles.wrapper}>
          <span>{value ? localizeAmount(value) : ''}</span>
          <Button variant="outlined" size="small" color="primary" onClick={handleModal}>
            {t('buttons:Edit')}
          </Button>
        </div>
      ) : (
        <>{value ? localizeAmount(value) : ''}</>
      )}

      <Dialog open={editTransferModal} onClose={handleModal} aria-labelledby="form-dialog-title">
        <div className={styles.modal__wrap}>
          <div className={styles.modal__head}>
            <h2>{t('common:Edit transfer request')}</h2>
            <Button onClick={handleModal} className={styles.button__close}>
              <CloseIcon />
            </Button>
          </div>
          <TextField
            label={t('fields:New amount')}
            type="number"
            variant="outlined"
            value={amount}
            onChange={onChangeAmount}
            autoFocus
            fullWidth
          />
          <div className={styles.modal__foot}>
            <Button onClick={onEditAmount} variant="contained" color="primary">
              {t('buttons:Save')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export { EditingAmount };
