import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Box } from '@material-ui/core';

type IProps = {
  labelSeparate?: string;
  value: number;
  maxValue: number;
  minValue: number;
  onChange: (value: number) => void;
  required?: boolean;
  error?: string;
};

const CustomStepper: FC<IProps> = ({ labelSeparate, value, maxValue, minValue, onChange, required, error }: IProps) => {
  const [counter, setCounter] = useState<number>(value);

  useEffect(() => {
    onChange(counter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <div className={styles.wrap}>
      {!!labelSeparate && (
        <div className={`${styles.label__separate} ${error && styles.label__separate__error}`}>
          {labelSeparate}
          {required && ' *'}
        </div>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button
          children="-"
          variant="outlined"
          onClick={() => setCounter(prev => prev - 1)}
          disabled={counter <= minValue}
        />
        {counter}
        <Button
          children="+"
          variant="outlined"
          onClick={() => setCounter(prev => prev + 1)}
          disabled={counter >= maxValue}
        />
      </Box>
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export { CustomStepper };
