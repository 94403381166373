import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useEntity } from 'icerockdev-admin-toolkit';
import { ServiceRequestEntity } from '../ServiceRequestEntity';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

interface IProps {
  isEditing: boolean;
  value?: string;
  label?: string;
  handler: (value: string) => void;
}

interface AttachmentImage {
  url: string;
  key: string;
  isLoading: boolean;
}

export const ImagesAttachment: FC<IProps> = ({ isEditing, value, label, handler }) => {
  const { t } = useTranslation();

  const entity = useEntity<ServiceRequestEntity>();

  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const refs = [inputRef1, inputRef2, inputRef3];

  const currentUrls = value ? JSON.parse(value) : [];
  const attachmentKeys = currentUrls.map((item: string) => item.split('/').slice(4).join('/'));

  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [attachments, setAttachments] = useState<AttachmentImage[]>([
    { url: '', key: '', isLoading: false },
    { url: '', key: '', isLoading: false },
    { url: '', key: '', isLoading: false },
  ]);

  useEffect(() => {
    if (isEditing) {
      const currentAttachments = attachments.map((item, i) => ({
        ...item,
        url: currentUrls[i] || '',
        key: currentUrls[i] ? currentUrls[i].split('/').slice(4).join('/') : '',
      }));
      setAttachments(currentAttachments);
      handler(JSON.stringify(attachmentKeys));
    }

    return () => {
      handler(value || '[]');
    };
  }, [isEditing]);

  const onPreviewImage = (url: string) => {
    setImageUrl(url);
    setIsImageModalOpen(true);
  };

  const onAddFile = (index: number) => {
    const ref = refs[index];
    setSelectedIndex(index);

    if (ref) {
      ref?.current?.click();
    }
  };

  const handleRemovePhoto = (index: number) => {
    const filteredAttachments = attachments.map((item, i) => (i === index ? { ...item, url: '', key: '' } : item));
    const filteredKeys = filteredAttachments.filter(item => item.key).map(item => item.key);

    setAttachments(filteredAttachments);
    handler(JSON.stringify(filteredKeys));
  };

  const handleChoosePhoto = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];

    if (!file) return;

    const updatedAttachments = attachments.map((item, i) =>
      i === selectedIndex ? { ...item, url: URL.createObjectURL(file), key: '', isLoading: true } : item,
    );
    setAttachments(updatedAttachments);

    const response = await entity.onUploadFile(file);

    if (response) {
      const newAttachments = attachments.map((item, i) =>
        i === selectedIndex ? { ...item, url: URL.createObjectURL(file), key: response, isLoading: false } : item,
      );
      const newKeys = newAttachments.filter(item => item.key).map(item => item.key);

      setAttachments(newAttachments);
      handler(JSON.stringify(newKeys));
    } else {
      const notUpdatedAttachments = attachments.map((item, i) =>
        i === selectedIndex ? { ...item, url: '', key: '', isLoading: false } : item,
      );
      setAttachments(notUpdatedAttachments);
    }
  };

  return isEditing ? (
    <div className={styles.wrapper}>
      {label && <label className={styles.label}>{label}</label>}

      <Box display="flex" style={{ gap: 6 }}>
        {attachments.map(({ url, isLoading }, i) =>
          url ? (
            <Box
              display="flex"
              className={styles.image_container}
              key={`image_${i}`}
              onClick={() => (isLoading ? null : handleRemovePhoto(i))}
            >
              <div className={styles.icon_wrapper}>{isLoading ? <CircularProgress size={32} /> : <DeleteIcon />}</div>
              <img className={styles.preview_edit_image} src={url} alt="" />
            </Box>
          ) : (
            <Box display="flex" className={styles.image_container} key={`image_${i}`} onClick={() => onAddFile(i)}>
              <div className={styles.icon_wrapper}>
                <AddIcon />
              </div>
              <input ref={refs[i]} type="file" accept="image/*" onChange={handleChoosePhoto} hidden />
            </Box>
          ),
        )}
      </Box>
    </div>
  ) : (
    <>
      <Box display="flex" style={{ gap: 6 }}>
        {currentUrls?.length
          ? currentUrls.map((url: string) => (
              <img key={url} className={styles.preview_image} src={url} alt="" onClick={() => onPreviewImage(url)} />
            ))
          : t('fields:No attachments')}
      </Box>

      <Dialog
        open={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        aria-labelledby="lightbox-image"
        maxWidth="md"
      >
        <img src={imageUrl} alt="" />
      </Dialog>
    </>
  );
};
