import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { IRadio } from '~/utils/types/shops.types';
import { observer } from 'mobx-react';
import { CustomRadio } from '~/components/common/CustomRadio';

interface IProps extends IRadio {
  onChangeFieldValue: (field: string, value: string) => void;
  ariaId: string;
  isViewPage?: boolean;
}

const RadioButtonItem: FC<IProps> = observer(
  ({ onChangeFieldValue, field, labelSeparate, value, error, additionalFields, isViewPage }) => {
    const { t } = useTranslation();

    const onChange = (field: string, value: string) => {
      onChangeFieldValue(field, value);
    };

    return (
      <div className={styles.container}>
        <div>
          <CustomRadio
            key={field}
            label={t(labelSeparate)}
            ariaId={field}
            value={value}
            disabled={isViewPage}
            error={error.includes(':') ? t(error) : error}
            fields={additionalFields}
            onChange={e => onChange(field, e.target.value)}
          />
        </div>
      </div>
    );
  },
);

export { RadioButtonItem };
