export enum ConfigurationFieldKey {
  CYBERSOURCE_SECRET_KEY = 'CYBERSOURCE_SECRET_KEY',
  VIRTUALPAY_API_KEY = 'VIRTUALPAY_API_KEY',
  VIRTUALPAY_MERCHANT_ID = 'VIRTUALPAY_MERCHANT_ID',
  EXCHANGE_API_TOKEN = 'EXCHANGE_API_TOKEN',
  CP_TO_JPY_CONVERSION_RATE = 'CP_TO_JPY_CONVERSION_RATE',
  SP_MINIMAL_AMOUNT_TO_WITHDRAW = 'SP_MINIMAL_AMOUNT_TO_WITHDRAW',
  VIRTUALPAY_CURRENCY = 'VIRTUALPAY_CURRENCY',
  VIRTUALPAY_CHECKOUT_URL = 'VIRTUALPAY_CHECKOUT_URL',
  VIRTUALPAY_MINIMAL_BUY_CP_AMOUNT = 'VIRTUALPAY_MINIMAL_BUY_CP_AMOUNT',
  VIRTUALPAY_PRIVATE_KEY = 'VIRTUALPAY_PRIVATE_KEY',
  BTC_PAY_STORE_ID = 'BTC_PAY_STORE_ID',
  BTC_PAY_WEBHOOK_SECRET = 'BTC_PAY_WEBHOOK_SECRET',
  BTC_PAY_CURRENCY = 'BTC_PAY_CURRENCY',
  BTC_PAY_AUTH_HEADER_VALUE = 'BTC_PAY_AUTH_HEADER_VALUE',
  BTC_PAY_MINIMAL_BUY_CP_AMOUNT = 'BTC_PAY_MINIMAL_BUY_CP_AMOUNT',
  CYBERSOURCE_MINIMAL_BUY_CP_AMOUNT = 'CYBERSOURCE_MINIMAL_BUY_CP_AMOUNT',
  CYBERSOURCE_API_URL = 'CYBERSOURCE_API_URL',
  CYBERSOURCE_KEY_ID = 'CYBERSOURCE_KEY_ID',
  CYBERSOURCE_MERCHANT_ID = 'CYBERSOURCE_MERCHANT_ID',
  BTC_PAY_API_URL = 'BTC_PAY_API_URL',
  CP_MINIMAL_AMOUNT_TO_BANK_TRANSFER = 'CP_MINIMAL_AMOUNT_TO_BANK_TRANSFER',
  CREDIT_CARD_PAYMENT_SYSTEM = 'CREDIT_CARD_PAYMENT_SYSTEM',
  BUSINESS_REQUISITES = 'BUSINESS_REQUISITES',
  CREDIT_CARD_SWITCHER = 'CREDIT_CARD_SWITCHER',
  BTC_PAY_SWITCHER = 'BTC_PAY_SWITCHER',
  BANK_TRANSFER_SWITCHER = 'BANK_TRANSFER_SWITCHER',
}

export enum ConfigurationFieldNameKey {
  CYBERSOURCE_SECRET_KEY = 'settings_cybersource_secret_key',
  VIRTUALPAY_API_KEY = 'settings_virtualpay_api_key',
  VIRTUALPAY_MERCHANT_ID = 'settings_virtualpay_merchant_id',
  EXCHANGE_API_TOKEN = 'settings_exchange_api_token',
  CP_TO_JPY_CONVERSION_RATE = 'settings_cp_to_jpy_conversion_rate',
  SP_MINIMAL_AMOUNT_TO_WITHDRAW = 'settings_sp_minimal_amount_to_withdraw',
  VIRTUALPAY_CURRENCY = 'settings_virtualpay_currency',
  VIRTUALPAY_CHECKOUT_URL = 'settings_virtualpay_checkout_url',
  VIRTUALPAY_MINIMAL_BUY_CP_AMOUNT = 'settings_virtualpay_minimal_buy_cp_amount',
  VIRTUALPAY_PRIVATE_KEY = 'settings_virtualpay_private_key',
  BTC_PAY_STORE_ID = 'settings_btc_pay_store_id',
  BTC_PAY_WEBHOOK_SECRET = 'settings_btc_pay_webhook_secret',
  BTC_PAY_CURRENCY = 'settings_btc_pay_currency',
  BTC_PAY_AUTH_HEADER_VALUE = 'settings_btc_pay_auth_header_value',
  BTC_PAY_MINIMAL_BUY_CP_AMOUNT = 'settings_btc_pay_minimal_buy_cp_amount',
  CYBERSOURCE_MINIMAL_BUY_CP_AMOUNT = 'settings_cybersource_minimal_buy_cp_amount',
  CYBERSOURCE_API_URL = 'settings_cybersource_api_url',
  CYBERSOURCE_KEY_ID = 'settings_cybersource_key_id',
  CYBERSOURCE_MERCHANT_ID = 'settings_cybersource_merchant_id',
  BTC_PAY_API_URL = 'settings_btc_pay_api_url',
  CP_MINIMAL_AMOUNT_TO_BANK_TRANSFER = 'settings_cp_minimal_amount_to_jpy_bank_transfer',
  CREDIT_CARD_PAYMENT_SYSTEM = 'settings_credit_card_payment_system',
  BUSINESS_REQUISITES = 'settings_business_requisites',
  CREDIT_CARD_SWITCHER = 'credit_card_switcher',
  BTC_PAY_SWITCHER = 'btc_pay_switcher',
  BANK_TRANSFER_SWITCHER = 'settings_bank_transfer_switcher',
}

export const CONFIGURATION_FIELD_NAME = {
  [ConfigurationFieldNameKey.CYBERSOURCE_SECRET_KEY]: 'Cybersource secret key',
  [ConfigurationFieldNameKey.VIRTUALPAY_API_KEY]: 'Virtualpay API key',
  [ConfigurationFieldNameKey.VIRTUALPAY_MERCHANT_ID]: 'Virtualpay merchant ID',
  [ConfigurationFieldNameKey.EXCHANGE_API_TOKEN]: 'Exchange API token',
  [ConfigurationFieldNameKey.CP_TO_JPY_CONVERSION_RATE]: 'CP to JPY conversion rate',
  [ConfigurationFieldNameKey.SP_MINIMAL_AMOUNT_TO_WITHDRAW]: 'SP minimal amount to withdraw',
  [ConfigurationFieldNameKey.VIRTUALPAY_CURRENCY]: 'Virtualpay currency',
  [ConfigurationFieldNameKey.VIRTUALPAY_CHECKOUT_URL]: 'Virtualpay checkout URL',
  [ConfigurationFieldNameKey.VIRTUALPAY_MINIMAL_BUY_CP_AMOUNT]: 'Virtualpay minimal buy CP amount',
  [ConfigurationFieldNameKey.VIRTUALPAY_PRIVATE_KEY]: 'Virtualpay private key',
  [ConfigurationFieldNameKey.BTC_PAY_STORE_ID]: 'BTC pay store ID',
  [ConfigurationFieldNameKey.BTC_PAY_WEBHOOK_SECRET]: 'BTC pay webhook secret',
  [ConfigurationFieldNameKey.BTC_PAY_CURRENCY]: 'BTC pay currency',
  [ConfigurationFieldNameKey.BTC_PAY_AUTH_HEADER_VALUE]: 'BTC pay auth header value',
  [ConfigurationFieldNameKey.BTC_PAY_MINIMAL_BUY_CP_AMOUNT]: 'BTC pay minimal buy CP amount',
  [ConfigurationFieldNameKey.CYBERSOURCE_MINIMAL_BUY_CP_AMOUNT]: 'Cybersource minimal buy CP amount',
  [ConfigurationFieldNameKey.CYBERSOURCE_API_URL]: 'Cybersource API URL',
  [ConfigurationFieldNameKey.CYBERSOURCE_KEY_ID]: 'Cybersource key ID',
  [ConfigurationFieldNameKey.CYBERSOURCE_MERCHANT_ID]: 'Cybersource merchant ID',
  [ConfigurationFieldNameKey.BTC_PAY_API_URL]: 'BTC pay API URL',
  [ConfigurationFieldNameKey.CP_MINIMAL_AMOUNT_TO_BANK_TRANSFER]: 'CP minimal amount to JPY bank transfer',
  [ConfigurationFieldNameKey.CREDIT_CARD_PAYMENT_SYSTEM]: 'Credit card payment system',
  [ConfigurationFieldNameKey.BUSINESS_REQUISITES]: 'Business requisites',
  [ConfigurationFieldNameKey.CREDIT_CARD_SWITCHER]: 'Credit card switcher',
  [ConfigurationFieldNameKey.BTC_PAY_SWITCHER]: 'BTC pay switcher',
  [ConfigurationFieldNameKey.BANK_TRANSFER_SWITCHER]: 'Bank transfer switcher',
};

export enum ConfigurationPaymentSystem {
  CYBERSOURCE = 'CYBERSOURCE',
  VIRTUALPAY = 'VIRTUALPAY',
}

export const CONFIGURATION_PAYMENT_SYSTEM_NAME = {
  [ConfigurationPaymentSystem.CYBERSOURCE]: 'Cybersource',
  [ConfigurationPaymentSystem.VIRTUALPAY]: 'Virtualpay',
};

export enum ConfigurationGroupKey {
  CREDIT_CARD = 'CREDIT_CARD',
  BTC_PAY = 'BTC_PAY',
  BANK_TRANSFER = 'BANK_TRANSFER',
  GENERAL = 'GENERAL',
}

export const CONFIGURATION_GROUP_NAME = {
  [ConfigurationGroupKey.CREDIT_CARD]: 'Credit card',
  [ConfigurationGroupKey.BTC_PAY]: 'BTC Pay',
  [ConfigurationGroupKey.BANK_TRANSFER]: 'Bank transfer',
  [ConfigurationGroupKey.GENERAL]: 'General',
};

export enum ConfigurationSwitcherKey {
  CREDIT_CARD_SWITCHER = 'CREDIT_CARD_SWITCHER',
  BTC_PAY_SWITCHER = 'BTC_PAY_SWITCHER',
  BANK_TRANSFER_SWITCHER = 'BANK_TRANSFER_SWITCHER',
}

export enum ConfigurationSwitcherNameKey {
  CREDIT_CARD_SWITCHER = 'credit_card_switcher',
  BTC_PAY_SWITCHER = 'btc_pay_switcher',
  BANK_TRANSFER_SWITCHER = 'settings_bank_transfer_switcher',
}

export const CONFIGURATION_SWITCHER_NAME = {
  [ConfigurationSwitcherNameKey.CREDIT_CARD_SWITCHER]: 'Credit card switcher',
  [ConfigurationSwitcherNameKey.BTC_PAY_SWITCHER]: 'BTC pay switcher',
  [ConfigurationSwitcherNameKey.BANK_TRANSFER_SWITCHER]: 'Bank transfer switcher',
};
