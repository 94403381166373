import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { TransactionAmount } from './components/TransactionAmount';
import { FormattedDate } from '~/components/common/FormattedDate';
import { TRANSACTION_STATUS_NAMES, TRANSACTION_STATUS_NAME_COLORS } from '~/utils/constants/transactions.constants';
import { ColoredStatus } from '~/components/common/ColoredStatus';
import { TransactionOwnerWallet } from './components/TransactionOwnerWallet';
import { TransactionColoredStatus } from './components/TransactionColoredStatus';

export const TRANSACTIONS_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'createdAt',
    label: 'Created date',
    sortable: true,
    component: props => <FormattedDate {...props} withTime />,
  },
  {
    type: 'custom',
    name: 'type',
    label: 'Type',
    component: props => (
      <ColoredStatus
        {...props}
        statusNames={TRANSACTION_STATUS_NAMES}
        statusNameColors={TRANSACTION_STATUS_NAME_COLORS}
      />
    ),
    filterable: true,
  },
  {
    type: 'custom',
    name: 'amount',
    label: 'Amount',
    component: props => <TransactionAmount {...props} />,
  },
  {
    type: 'custom',
    name: 'fromWallet',
    label: 'From',
    component: props => <TransactionOwnerWallet {...props} />,
  },
  {
    type: 'custom',
    name: 'toWallet',
    label: 'To',
    component: props => <TransactionOwnerWallet {...props} />,
  },
  {
    type: 'custom',
    name: 'transferRequest',
    label: 'Status',
    component: props => <TransactionColoredStatus {...props} />,
  },
  {
    type: 'string',
    name: 'description',
    label: 'Description',
  },
];
