import { fetchRequests, getRequest } from './api';
import { SERVICE_REQUEST_FIELDS } from './fields';
import { ServiceRequestEntity } from './components/ServiceRequestEntity';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';
import { createItemsFn } from '~/utils/api';

export default new ServiceRequestEntity({
  title: 'Service requests',
  viewable: true,
  creatable: true,
  items: 50,
  api: {
    list: { url: API_ROUTES.serviceRequests, method: 'get' },
    get: { url: API_ROUTES.serviceRequests, method: 'get' },
    create: { url: API_ROUTES.serviceRequests, method: 'post' },
    update: { url: API_ROUTES.serviceRequests, method: 'put' },
    usersList: { url: API_ROUTES.users, method: 'get' },
    requestTypes: { url: API_ROUTES.requestTypes, method: 'get' },
    transferRequests: { url: API_ROUTES.transferRequests, method: 'get' },
    createTransferRequest: { url: API_ROUTES.transferRequests, method: 'post' },
    uploadFile: { url: API_ROUTES.uploadFile, method: 'post' },
  },
  menu: {
    label: 'Service requests',
    url: '/service-requests',
    enabled: true,
  },
  fields: SERVICE_REQUEST_FIELDS,
  fetchItemsFn: fetchRequests,
  getItemsFn: getRequest,
  createItemsFn,
  roles: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge],
    view: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge],
    create: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge],
  },
});
