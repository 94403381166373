import React, { FC } from 'react';
import { format, isValid, parseISO } from 'date-fns';

type IProps = {
  value: string;
  withTime?: boolean;
};

const FormattedDate: FC<IProps> = ({ value, withTime }: IProps) => {
  const parsedDate = parseISO(value);
  const isValidDate = isValid(parsedDate);
  const date = isValidDate ? format(parsedDate, withTime ? 'yyyy.MM.dd HH:mm' : 'yyyy.MM.dd') : value;

  return <div>{date}</div>;
};
export { FormattedDate };
