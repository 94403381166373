import {
  IEntityGetFunction,
  IEntityGetFunctionProps,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

const getCustomerItemData = async ({ url, id, token }: IEntityGetFunctionProps) =>
  axios.get(`${url}`, {
    headers: {
      authorization: token,
    },
  });

export const getConfiguration: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getCustomerItemData({ url, id, token });
  const data = result?.data?.data;

  return {
    data,
    error: '',
  };
};

export const putConfiguration = async ({
  url,
  id,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(`${url}`, data, {
    headers: { authorization: token },
  });

  const responseData = result?.data?.data;

  return {
    data: responseData,
  };
};

export const updateConfigurationById = async ({
  url,
  id,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(`${url}/${id}`, data, {
    headers: { authorization: token },
  });
  const responseData = result?.data?.data;

  return {
    data: responseData,
    error: '',
  };
};
