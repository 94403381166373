import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { USER_ROLE_NAMES } from '~/utils/constants/roles.constants';
import { FormattedDate } from '~/components/common/FormattedDate';
import { BankAccountButton } from '~/pages/withdraw-request/components/BankAccountButton';
import { FormattedNumber } from '~/components/common/FormattedNumber';
import { ColoredStatus } from '~/components/common/ColoredStatus';
import {
  WITHDRAW_REQUEST_STATUS_NAMES,
  WITHDRAW_REQUEST_STATUS_NAME_COLORS,
} from '~/utils/constants/requests.constants';
import { WithdrawSpWalletField } from './components/WithdrawSpWalletField';

export const WITHDRAW_REQUEST_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'createdAt',
    label: 'Created date',
    sortable: true,
    hideInCreate: true,
    component: props => <FormattedDate {...props} withTime />,
  },
  {
    type: 'string',
    name: 'userName',
    label: 'User',
    hideInCreate: true,
  },
  {
    type: 'select',
    name: 'role',
    label: 'Role',
    options: USER_ROLE_NAMES,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'clientId',
    label: 'Member ID',
    filterable: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'pointsType',
    label: 'Type of points',
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: 'amount',
    label: 'Withdraw amount',
    hideInCreate: true,
    component: FormattedNumber,
  },
  {
    type: 'custom',
    name: 'user',
    label: 'SP Wallet',
    hideInCreate: true,
    component: props => <WithdrawSpWalletField {...props} />,
  },
  {
    type: 'custom',
    name: 'accountBank',
    label: 'Bank account number',
    component: BankAccountButton,
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: 'status',
    label: 'Status',
    sortable: true,
    component: props => (
      <ColoredStatus
        {...props}
        statusNames={WITHDRAW_REQUEST_STATUS_NAMES}
        statusNameColors={WITHDRAW_REQUEST_STATUS_NAME_COLORS}
        rejectReason={{
          rejectStatusName: WITHDRAW_REQUEST_STATUS_NAMES.DECLINED,
          reasonFieldName: 'declineReason',
        }}
      />
    ),
    hideInCreate: true,
  },
];
