import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityGetFunctionProps,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const fetchItemsFn: IEntityFetchFunction = async ({ url, token, page = 0, count, sortDir, sortBy, filter }) => {
  const offset = page && count ? page * count : 0;

  const orderBy = sortDir?.toUpperCase();

  const filters: Record<string, string> =
    filter?.reduce((acc, item) => {
      acc[item.name] = typeof item.value === 'string' ? item.value.trim() : item.value;
      return acc;
    }, {}) || {};

  const response = await axios.get(url, {
    params: {
      offset,
      limit: count,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
    },
    headers: { authorization: token },
  });

  const list = response?.data?.data || [];

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};

export const getCustomerItemData = async ({ url, id, token }: IEntityGetFunctionProps) =>
  axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  });

export const getItemsFn: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getCustomerItemData({ url, id, token });

  return {
    data: result?.data?.data || {},
    error: '',
  };
};

export const updateItemsFn = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(`${url}/${data.id}`, data, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};

export const createItemsFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(url, data, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};
