import React, { FC } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  toggleCheckbox: () => void;
  checkboxValue: boolean;
  isViewPage: boolean;
}

const ShopVisibleCheckboxContainer: FC<IProps> = observer(({ toggleCheckbox, checkboxValue, isViewPage }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.checkboxBox}>
        <Checkbox
          style={{ width: '18px', height: '18px' }}
          checked={checkboxValue}
          onChange={toggleCheckbox}
          disabled={isViewPage}
          color="primary"
        />
        <h3>{t('fields:Hide business')}</h3>
      </div>
    </div>
  );
});

export { ShopVisibleCheckboxContainer };
