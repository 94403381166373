import { createItemsFn, fetchItemsFn, getItemsFn, updateItemsFn } from '~/utils/api';
import { INFORMATION_FIELDS } from './fields';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';
import { InformationEntity } from '~/pages/information/components/Entity';

export default new InformationEntity({
  title: 'Information',
  viewable: true,
  editable: true,
  creatable: true,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.information, method: 'get' },
    get: { url: API_ROUTES.information, method: 'get' },
    create: { url: API_ROUTES.information, method: 'post' },
    update: { url: API_ROUTES.information, method: 'put' },
  },
  menu: {
    label: 'Information',
    url: '/information',
    enabled: true,
  },
  fields: INFORMATION_FIELDS,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
  createItemsFn,
  roles: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business],
  },
});
