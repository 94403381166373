import {
  IEntityGetFunction,
  IEntityGetFunctionProps,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

const getCustomerItemData = async ({ url, id, token }: IEntityGetFunctionProps) =>
  axios.get(`${url}/${id}/profile`, {
    headers: {
      authorization: token,
    },
  });

export const getUserProfile: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getCustomerItemData({ url, id, token });
  const data = result?.data?.data;

  const modifiedData = { ...data.user, ...data.bankDetails, wallets: data.wallets };

  return {
    data: modifiedData || {},
    error: '',
  };
};

export const updateUserProfile = async ({
  url,
  id,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(`${url}/${id}/profile`, data, {
    headers: { authorization: token },
  });

  const responceData = result?.data?.data;
  const modifiedData = { ...responceData.user, ...responceData.bankDetails, wallets: responceData.wallets };

  return {
    data: modifiedData,
  };
};
