import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';

interface IProps {
  onButtonClick: () => void;
}

const DeleteButton: FC<IProps> = observer(({ onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Button variant="contained" color="primary" className={styles.button} disableElevation onClick={onButtonClick}>
        {t('buttons:Delete')}
      </Button>
    </div>
  );
});

export { DeleteButton };
