import {
  BreadcrumbsEnum,
  BreadcrumbsType,
  FieldsEnum,
  ICharacterLimit,
  IInputField,
  InputTypesEnum,
  IRadio,
  IShopThumbnail,
  IUrlLimit,
} from '~/utils/types/shops.types';

export const SHOP_THUMBNAIL_DATA: IShopThumbnail = {
  value: '',
  imageBase64: '',
  imageKey: '',
  imageUrl: '',
  error: '',
  required: true,
  limit: 120,
};

export const BREADCRUMBS_ITEMS: BreadcrumbsType[] = [
  { name: BreadcrumbsEnum.publicInfo },
  { name: BreadcrumbsEnum.noPublicInfo },
];

export const CHARACTER_LIMIT: ICharacterLimit = {
  name: 255,
  description: 1000,
  tag: 80,
};

export const URL_LIMIT: IUrlLimit = {
  title: 200,
  link: 200,
};

export const TOP_MAIN_INFO_DATA: IInputField[] = [
  {
    field: FieldsEnum.NAME,
    value: '',
    error: '',
    required: true,
    limit: 50,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Shop Name',
    placeholder: 'placeholder:Expert store',
  },
  {
    field: FieldsEnum.STORE_REPRESENTATIVE_NAME,
    value: '',
    error: '',
    required: true,
    limit: 255,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Name of representative',
    placeholder: 'placeholder:Goro Tanaka',
  },
  {
    field: FieldsEnum.WEBSITE,
    value: '',
    error: '',
    required: false,
    limit: 255,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Official website URL',
    placeholder: 'placeholder:If none, write "None"',
  },
  {
    field: FieldsEnum.BUSINESS_HOURS,
    value: '',
    error: '',
    required: false,
    limit: 500,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Business hours',
    placeholder: '09.00-18.00',
  },
];

export const BOTTOM_MAIN_INFO_DATA: IInputField[] = [
  {
    field: FieldsEnum.SHORT_DESCRIPTION,
    value: '',
    error: '',
    required: false,
    limit: 340,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Short description',
    placeholder: '',
    rows: 5,
  },
  {
    field: FieldsEnum.DESCRIPTION,
    value: '',
    error: '',
    required: false,
    limit: 1000,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Detailed description',
    placeholder: '',
    rows: 11,
  },
  {
    field: FieldsEnum.MEMBERSHIP_BENEFITS,
    value: '',
    error: '',
    required: false,
    limit: 500,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Membership benefits',
    placeholder: '',
    rows: 4,
  },
  {
    field: FieldsEnum.SHOP_ATMOSPHERE,
    value: '',
    error: '',
    required: true,
    limit: 80,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Atmosphere of the shop',
    placeholder: '',
    rows: 4,
  },
];

export const CONTACT_INFO_DATA: IInputField[] = [
  {
    field: FieldsEnum.POST_CODE,
    value: '',
    error: '',
    required: true,
    limit: 8,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Post code',
    placeholder: '〒857-0831',
  },
  {
    field: FieldsEnum.ADDRESS,
    value: '',
    error: '',
    required: false,
    limit: 200,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Address',
    placeholder: '',
  },
  {
    field: FieldsEnum.SHOP_CONTACT_PERSON,
    value: '',
    error: '',
    required: false,
    limit: 60,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Shop contact person',
    placeholder: '',
  },
  {
    field: FieldsEnum.PHONE,
    value: '',
    error: '',
    required: false,
    limit: 15,
    type: InputTypesEnum.phone,
    labelSeparate: 'fields:Telephone number',
    placeholder: '',
  },
  {
    field: FieldsEnum.PREFECTURE,
    value: '',
    error: '',
    required: false,
    limit: 60,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:State/Province - prefecture',
    placeholder: '',
  },
  {
    field: FieldsEnum.ACCESS,
    value: '',
    error: '',
    required: false,
    limit: 400,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Access',
    placeholder: '',
  },
  {
    field: FieldsEnum.OUTLINE_SHOP_ATMOSPHERE,
    value: '',
    error: '',
    required: false,
    limit: 80,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Outline the shop atmosphere',
    placeholder: '',
  },
];

export const PRODUCTS_SUMMARY_DESCRIPTION_DATA: IInputField[] = [
  {
    field: FieldsEnum.PRODUCT_DESCRIPTION,
    value: '',
    error: '',
    required: true,
    limit: 1000,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Product service description',
    placeholder: '',
  },
];

export const PRICE_INFO_DATA: IInputField[] = [
  {
    field: FieldsEnum.REFERENCE_PRICE,
    value: '',
    error: '',
    required: false,
    limit: 80,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Reference price',
    placeholder: '',
  },
  {
    field: FieldsEnum.PRICE_DESCRIPTION,
    value: '',
    error: '',
    required: false,
    limit: 2000,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Summary description',
    placeholder: '',
  },
  {
    field: FieldsEnum.BUDGET,
    value: '',
    error: '',
    required: false,
    limit: 80,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Budget',
    placeholder: '',
  },
];

export const RADIO_DATA: IRadio[] = [
  {
    field: FieldsEnum.MULTIPLE_SHOPS,
    value: '0',
    error: '',
    labelSeparate: 'fields:Do you have multiple shops?',
    additionalFields: [
      { id: '1', value: 'YES' },
      { id: '0', value: 'NO' },
    ],
  },
  {
    field: FieldsEnum.MULTIPLE_BRANCHES,
    value: '0',
    error: '',
    labelSeparate: 'fields:Do you have multiple branch?',
    additionalFields: [
      { id: '1', value: 'YES' },
      { id: '0', value: 'NO' },
    ],
  },
  {
    field: FieldsEnum.IS_PARTNER_ALLOWED,
    value: '0',
    error: '',
    labelSeparate:
      'fields:May we introduce your company as a partner shop on our social networking sites or during sales?',
    additionalFields: [
      { id: '1', value: 'YES' },
      { id: '0', value: 'NO' },
    ],
  },
];

export const BANK_INFO_DATA: IInputField[] = [
  {
    field: FieldsEnum.BANK_NAME,
    value: '',
    error: '',
    required: true,
    limit: 15,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Bank name',
    placeholder: '',
  },
  {
    field: FieldsEnum.BANK_CODE,
    value: '',
    error: '',
    required: true,
    limit: 4,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Bank code',
    placeholder: '',
  },
  {
    field: FieldsEnum.BRANCH_NAME,
    value: '',
    error: '',
    required: true,
    limit: 100,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Branch name',
    placeholder: '',
  },
  {
    field: FieldsEnum.BRANCH_CODE,
    value: '',
    error: '',
    required: true,
    limit: 4,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Branch code',
    placeholder: '',
  },
  {
    field: FieldsEnum.ACCOUNT_NUMBER,
    value: '',
    error: '',
    required: true,
    limit: 10,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Account number',
    placeholder: '',
  },
  {
    field: FieldsEnum.ACCOUNT_HOLDER_NAME,
    value: '',
    error: '',
    required: true,
    limit: 40,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Account Holder Name',
    placeholder: '',
  },
  {
    field: FieldsEnum.BANK_TYPE,
    value: '',
    error: '',
    required: true,
    limit: 1,
    type: InputTypesEnum.selector,
    additionalFields: [
      { value: '1', label: 'Ordinary' },
      { value: '2', label: 'Current' },
    ],
    labelSeparate: 'fields:Bank Type',
    placeholder: '',
  },
  {
    field: FieldsEnum.INDIVIDUAL_WITHDRAWAL_FEE,
    value: '',
    error: '',
    required: false,
    limit: 4,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Individual withdrawal fee',
    placeholder: '',
  },
];

export const BUSINESS_DATA: IInputField[] = [
  {
    field: FieldsEnum.SHOP_VISIT_DATE,
    value: '',
    error: '',
    required: false,
    limit: 120,
    type: InputTypesEnum.date,
    labelSeparate: 'fields:Date of shop visit (date of business meeting)',
    placeholder: '',
  },
  {
    field: FieldsEnum.BUSINESS_NAME,
    value: '',
    error: '',
    required: false,
    limit: 120,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Business Name',
    placeholder: 'Miwako Kobayashi',
  },
  {
    field: FieldsEnum.BUSINESS_CATEGORY,
    value: '',
    error: '',
    required: true,
    limit: 120,
    type: InputTypesEnum.selector,
    labelSeparate: 'fields:Business category',
    additionalFields: [
      { label: 'Individual', value: '0' },
      { label: 'Corporation', value: '1' },
    ],
    placeholder: '',
  },
  {
    field: FieldsEnum.ESTABLISHMENT_DATE,
    value: '',
    error: '',
    required: true,
    limit: 120,
    type: InputTypesEnum.date,
    labelSeparate: 'fields:Date of establishment',
    placeholder: '',
  },
  {
    field: FieldsEnum.CAPITAL,
    value: '',
    error: '',
    required: false,
    limit: 20,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Capital',
    placeholder: "1,000,000 yen *For individual: enter 'none'",
  },
];

export const COMPANY_DATA: IInputField[] = [
  {
    field: FieldsEnum.COMPANY_NAME,
    value: '',
    error: '',
    required: true,
    limit: 200,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Company name',
    placeholder: 'placeholder:EXP Promotion Association',
  },
  {
    field: FieldsEnum.COMPANY_NAME_KANA,
    value: '',
    error: '',
    required: true,
    limit: 200,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Company name (Japanese Kana)',
    placeholder: 'placeholder:Expert Shinko Kyokai',
  },
  {
    field: FieldsEnum.SHOP_REPRESENTATIVE_NAME,
    value: '',
    error: '',
    required: true,
    limit: 80,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Name of representative',
    placeholder: 'placeholder:Goro Tanaka',
  },
  {
    field: FieldsEnum.SHOP_REPRESENTATIVE_NAME_KANA,
    value: '',
    error: '',
    required: true,
    limit: 80,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Name of representative (Japanese Kana)',
    placeholder: 'placeholder:tanaka goro',
  },
  {
    field: FieldsEnum.SHOP_NAME,
    value: '',
    error: '',
    required: true,
    limit: 50,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Name of the shop',
    placeholder: 'placeholder:Expert store',
  },
  {
    field: FieldsEnum.SHOP_NAME_KANA,
    value: '',
    error: '',
    required: true,
    limit: 50,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Name of the shop(Japanese Kana)',
    placeholder: 'placeholder:expert ten',
  },
  {
    field: FieldsEnum.SHOP_ADDRESS,
    value: '',
    error: '',
    required: true,
    limit: 200,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Shop address',
    placeholder: 'placeholder:203 Sunnyside Building, 1-1 Koshienguchi Kitamachi, Nishinomiya City, Hyogo Prefecture',
  },
  {
    field: FieldsEnum.SHOP_REPRESENTATIVE_GENDER,
    value: '',
    error: '',
    required: false,
    limit: 1,
    type: InputTypesEnum.selector,
    additionalFields: [
      { label: 'Male', value: '0' },
      { label: 'Female', value: '1' },
    ],
    labelSeparate: 'fields:Representative Gender',
    placeholder: '',
  },
  {
    field: FieldsEnum.FAX,
    value: '',
    error: '',
    required: false,
    limit: 13,
    type: InputTypesEnum.numberInput,
    labelSeparate: 'fields:Fax number',
    placeholder: '066221234',
  },
  {
    field: FieldsEnum.BRANCH,
    value: '',
    error: '',
    required: false,
    limit: 80,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Branch',
    placeholder: '',
  },
];

export const NO_PUBLIC_TEXTAREA_DATA: IInputField[] = [
  {
    field: FieldsEnum.BUSINESS_DESCRIPTION,
    value: '',
    error: '',
    required: false,
    limit: 1000,
    type: InputTypesEnum.textareaInput,
    labelSeparate: 'fields:Details of the type of business for which you selected "Other" above',
    placeholder: '',
    rows: 11,
  },
  {
    field: FieldsEnum.RECOMMENDATION_REASON,
    value: '',
    error: '',
    required: false,
    limit: 1000,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Reason of Recommendation',
    placeholder: '',
    rows: 11,
  },
];

export const TOP_NO_PUBLIC_BLOCK: IInputField[] = [
  {
    field: FieldsEnum.SALES_PLANNER_ID,
    value: '',
    error: '',
    required: true,
    limit: 8,
    type: InputTypesEnum.numberInput,
    labelSeparate: 'fields:Sales Planner ID',
    placeholder: '',
  },
  {
    field: FieldsEnum.SITE_PLANNER_A_ID,
    value: '',
    error: '',
    required: false,
    limit: 8,
    type: InputTypesEnum.numberInput,
    labelSeparate: 'fields:Site Planner A ID',
    placeholder: '1234',
  },
  {
    field: FieldsEnum.SITE_PLANNER_B_ID,
    value: '',
    error: '',
    required: false,
    limit: 8,
    type: InputTypesEnum.numberInput,
    labelSeparate: 'fields:Site Planner B ID',
    placeholder: '1234',
  },
  {
    field: FieldsEnum.SHOP_MANAGER,
    value: '',
    error: '',
    required: false,
    limit: 80,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Name of Store Manager',
    placeholder: 'placeholder:Hachiro Suzuki',
  },
  {
    field: FieldsEnum.SHOP_MANAGER_KANA,
    value: '',
    error: '',
    required: false,
    limit: 80,
    type: InputTypesEnum.textInput,
    labelSeparate: 'fields:Name of Store Manager(Japanese Kana)',
    placeholder: 'placeholder:Suzuki Hachiro',
  },
  {
    field: FieldsEnum.AFFILIATE_PLANNER_ID,
    value: '',
    error: '',
    required: false,
    limit: 8,
    type: InputTypesEnum.numberInput,
    labelSeparate: 'fields:Affiliate Planner ID',
    placeholder: '',
  },
  {
    field: FieldsEnum.BUSINESS_OWNER_ID,
    value: '',
    error: '',
    required: true,
    limit: 8,
    type: InputTypesEnum.numberInput,
    labelSeparate: 'fields:Business owner ID',
    placeholder: '',
  },
];

export const ItemTable = [
  { width: '10%', title: 'fields:Image', id: 1 },
  { width: '20%', title: 'fields:Product name', id: 2 },
  { width: '50%', title: 'fields:Description', id: 3 },
  { width: '10%', title: 'fields:Hide in front page', id: 4 },
  { width: '5%', title: '', id: 5 },
  { width: '5%', title: '', id: 6 },
];

export const UrlTable = [
  { width: '30%', title: 'fields:Title', id: 1 },
  { width: '50%', title: 'fields:Link', id: 2 },
  { width: '10%', title: 'fields:Hide in front page', id: 3 },
  { width: '5%', title: '', id: 4 },
  { width: '5%', title: '', id: 5 },
];
