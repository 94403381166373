import React from 'react';
import ReactSelect from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import styles from './styles.module.scss';

interface Option<T> {
  value: T;
  label: string;
}

interface IProps<T> {
  label?: string;
  labelSeparate?: string;
  options: Option<T>[];
  filterOption?: ((option: FilterOptionOption<Option<T>>, inputValue: string) => boolean) | null | undefined;
  value?: Option<T> | null;
  placeholder?: string;
  required?: boolean;
  onChange?: (value: any) => void;
  onInputChange?: (value: any) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  isClearable?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  error?: string;
  height?: number;
  width?: number;
}

function CustomSelect<T>({
  label,
  labelSeparate,
  options,
  filterOption,
  value,
  placeholder,
  required,
  onChange,
  onInputChange,
  onFocus,
  onBlur,
  isClearable,
  isLoading,
  isDisabled,
  error,
  height,
  width,
}: IProps<T>) {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: !!height ? `${height}px` : '56px',
      minWidth: !!width ? `${width}px` : '100%',
      border: !!error ? '1px solid #ef4444' : '',
      cursor: 'pointer',
    }),
    menu: (provided: any) => ({ ...provided, zIndex: '99' }),
    option: (provided: any) => ({ ...provided, cursor: 'pointer' }),
    placeholder: (provided: any) => ({ ...provided, paddingLeft: '4px' }),
    singleValue: (provided: any) => ({ ...provided, paddingLeft: '4px' }),
    input: (provided: any) => ({ ...provided, paddingLeft: '4px' }),
    indicatorSeparator: () => ({}),
  };

  return (
    <div
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
      }}
      className={styles.wrap}
    >
      {!!labelSeparate && (
        <div className={`${styles.label__separate} ${error && styles.label__separate__error}`}>
          {labelSeparate}
          {required && ' *'}
        </div>
      )}
      {!!label && <label className={styles.label}>{label}</label>}
      <ReactSelect
        options={options}
        filterOption={filterOption}
        value={value}
        placeholder={placeholder}
        styles={customStyles}
        onChange={onChange}
        onInputChange={onInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isClearable={isClearable}
        isLoading={isLoading}
        isDisabled={isDisabled}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F7E7CE',
            primary: '#DC9B2D',
          },
        })}
      />
      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  );
}

export { CustomSelect };
