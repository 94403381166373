import React, { FC } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { CustomInput } from '~/components/common/CustomInput';
import { useTranslation } from 'react-i18next';
import { CHARACTER_LIMIT } from '~/pages/shops/shops-constants';

interface IProps {
  mainInfoTagValue: string;
  onTagValueChange: (tag: string) => void;
  isViewPage?: boolean;
}

const TagMainInfoBlock: FC<IProps> = observer(({ mainInfoTagValue, onTagValueChange, isViewPage }) => {
  const { t } = useTranslation();

  const onChange = (value: string) => {
    if (value.length > CHARACTER_LIMIT.tag) {
      const editedString = value.slice(0, CHARACTER_LIMIT.tag);
      return onTagValueChange(editedString);
    }
    onTagValueChange(value);
  };
  return (
    <div className={styles.container}>
      <CustomInput
        labelSeparate={t('fields:Tag')}
        value={mainInfoTagValue}
        disabled={isViewPage}
        onChange={e => onChange(e.target.value)}
        maxLength={CHARACTER_LIMIT.tag}
      />
    </div>
  );
});

export { TagMainInfoBlock };
