import * as React from 'react';

interface PropTypes {
  size?: number;
}

const Component: React.FC<PropTypes> = ({ size = 20, ...props }: PropTypes) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12.051c1.64 0 2.46-.82 2.871-1.23l2.083.757a3.692 3.692 0 012.43 3.47v2.337H2.616v-2.337c0-1.553.972-2.94 2.43-3.47l2.083-.758c.41.41 1.232 1.231 2.872 1.231zm4.939.83l-1.792-.65-.04.03c-.63.487-1.624 1.021-3.107 1.021s-2.477-.534-3.107-1.02l-.04-.032-1.792.652a1.846 1.846 0 00-1.215 1.735v1.537h12.308v-1.537c0-.777-.486-1.47-1.215-1.735zM12.41 9.485c-.564.825-1.384 1.335-2.41 1.335-1.026 0-1.846-.51-2.41-1.335-.564-.825-.872-1.964-.872-3.223s.41-2.17 1.026-2.767c.615-.597 1.436-.88 2.256-.88.82 0 1.641.283 2.256.88.616.597 1.026 1.508 1.026 2.767s-.308 2.398-.872 3.223zm-1.016-.694c.392-.573.657-1.453.657-2.529 0-.978-.31-1.553-.652-1.884-.355-.345-.854-.532-1.399-.532s-1.043.187-1.4.532c-.341.331-.651.906-.651 1.884 0 1.076.265 1.956.657 2.529.37.542.835.799 1.394.799.56 0 1.024-.257 1.394-.8z"
      fill="#1F1B16"
    />
  </svg>
);

export default Component;
