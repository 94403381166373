import React, { FC } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useConfig } from 'icerockdev-admin-toolkit';
import { Link } from 'react-router-dom';
import { VerticalNavigation } from '~/components/sidebar/VerticalNavigation';
import { UserPanel } from '~/components/sidebar/UserPanel';
import { UserRole } from '~/utils/constants/roles.constants';
import { ConciergePointsBalance } from '~/components/sidebar/ConciergePointsBalance';

interface IProps {}

const MainLayout: FC<IProps> = observer(({ children }) => {
  const config = useConfig();
  const acceptRoles = [UserRole.Business];
  const currentRole = config.auth?.userRole as UserRole;
  const isShow = !!currentRole && acceptRoles.includes(currentRole);

  return (
    <div className={classNames(styles.layout, 'vertical-layout')}>
      <div className={classNames(styles.menu, 'vertical-layout__menu')}>
        <div className={classNames(styles.logo, 'vertical-layout__logo')}>
          <Link to={config.fallbackUrl}>
            <img src={config.logo} alt="Concierge" draggable={false} />
          </Link>
        </div>

        <div className={classNames(styles.navigation, 'vertical-layout__navigation')}>
          <VerticalNavigation />
        </div>

        <div className={classNames(styles.balance__wrap)}>{isShow && <ConciergePointsBalance />}</div>

        {!!config.auth && (
          <div className={classNames(styles.account, 'vertical-layout__account')}>
            {!!config.auth && <UserPanel isShowProfile={isShow} />}
          </div>
        )}
      </div>

      <div className={classNames(styles.content, 'vertical-layout__content')}>{children}</div>
    </div>
  );
});

export { MainLayout };
