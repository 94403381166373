import * as React from 'react';
import { SVGProps } from 'react';

const UploadSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M33.118 12c.729 0 1.32.597 1.32 1.333v2.343l-2.64 2.667v-3.676h-18.49v10.8l5.283-5.334 5.715 5.772-1.752 1.77-.008 5.652 5.607.008 1.757-1.774 1.754 1.772h.133v-3.677l2.641-2.667v7.678c0 .736-.591 1.333-1.32 1.333h-21.13c-.73 0-1.321-.597-1.321-1.333V13.333c0-.736.591-1.333 1.32-1.333h21.13Zm2.348 6.41 1.867 1.886-10.272 10.37-1.87-.002.003-1.883 10.272-10.37Zm-8.291-1.077c1.093 0 1.98.896 1.98 2s-.887 2-1.98 2a1.991 1.991 0 0 1-1.981-2c0-1.104.887-2 1.98-2Z"
      fill="#000"
    />
  </svg>
);

export default UploadSvg;
