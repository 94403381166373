import React, { FC, useState } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from '~/components/common/CustomSelect';
import { Button } from '@material-ui/core';
import { CategoryType } from '~/utils/types/category.types';

interface IProps {
  addCategory: (category: CategoryType) => void;
  options: { value: string; label: string }[];
  loadingCategoryByValue: (value: string) => void;
}

const CategoryModalContentCard: FC<IProps> = observer(({ addCategory, options, loadingCategoryByValue }) => {
  const { t } = useTranslation();

  const method = (e: string) => {
    loadingCategoryByValue(e);
  };
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('fields:Add category')}</h2>
      <div className={styles.selectorBlock}>
        <h3 className={styles.selectorTitle}>{t('fields:Title')}:</h3>
        <CustomSelect options={options} onChange={addCategory} onInputChange={method} />
      </div>
      <Button className={styles.bottomButton} variant="contained" color="primary" type="button" disableElevation>
        {t('buttons:Add')}
      </Button>
    </div>
  );
});

export { CategoryModalContentCard };
