import * as React from 'react';

interface PropTypes {
  size?: number;
  color?: string;
}

const Component: React.FC<PropTypes> = ({ size = 24, color, ...props }: PropTypes) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth={2}
    fill="none"
    stroke={color || 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"
    />
  </svg>
);

export default Component;
