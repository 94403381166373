import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { Button } from '@material-ui/core';
import { useEntity, useConfig } from 'icerockdev-admin-toolkit';
import { UserRole } from '~/utils/constants/roles.constants';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '~/components/icons/CloseIcon';
import { UserEntity } from '../UserEntity';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TypePoints } from '~/utils/constants/transactions.constants';

interface IProps {
  id: string;
}

const HeadButtons: FC<IProps> = observer(({ id }) => {
  const entity = useEntity<UserEntity>();
  const config = useConfig();
  const { t } = useTranslation(['buttons', 'common']);
  const acceptRoles = [UserRole.Member, UserRole.User];
  const isAccessRole = acceptRoles.includes(entity.editorData.role);
  const isProfileMember = entity.editorData.role === UserRole.Member;
  const userRole = config.auth?.user.role;
  const isAccessUpdateRole = entity.permissions.update?.includes(userRole);

  const [replenishPointsModal, setReplenishPointsModal] = useState<boolean>(false);
  const [reducePointsModal, setReducePointsModal] = useState<boolean>(false);
  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);

  const [typePoints, setTypePoints] = useState<TypePoints>(TypePoints.CP);
  const [pointsValue, setPointsValue] = useState<string>('');

  const handleChangePointsAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setPointsValue(event.target.value);
  };

  const handleChangePoints = (event: ChangeEvent<HTMLInputElement>) => {
    setTypePoints(event.target.value as TypePoints);
  };

  const handleReplenishModal = (): void => {
    setReplenishPointsModal(prevState => !prevState);
  };

  const handleReduceModal = (): void => {
    setReducePointsModal(prevState => !prevState);
  };

  const handleDeleteUserModal = (): void => {
    setDeleteUserModal(prevState => !prevState);
  };

  const handleReplenishRequestPoints = useCallback(() => {
    if (!pointsValue) return;

    entity.addPoints(id, pointsValue, typePoints).then(() => {
      setPointsValue('');
      setReplenishPointsModal(false);
    });
  }, [entity, id, pointsValue, typePoints]);

  const handleReduceRequestPoints = useCallback(() => {
    if (!pointsValue) return;

    entity.addPoints(id, `-${pointsValue}`, typePoints).then(() => {
      setPointsValue('');
      setReducePointsModal(false);
    });
  }, [entity, id, pointsValue, typePoints]);

  const handleDeleteUserAccount = useCallback(() => {
    entity.onDeletehUser(id).then(() => {
      setDeleteUserModal(false);
    });
  }, [entity, id]);

  return (
    <>
      {isAccessUpdateRole && (
        <div className={styles.wrap}>
          <Button
            variant="outlined"
            color="secondary"
            className={styles.button}
            disableElevation
            onClick={handleDeleteUserModal}
          >
            {t('Delete user')}
          </Button>

          {isAccessRole && (
            <>
              <Button
                variant="outlined"
                color="primary"
                className={styles.button}
                disableElevation
                onClick={handleReduceModal}
              >
                {t('Reduce deposit')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={styles.button}
                disableElevation
                onClick={handleReplenishModal}
              >
                {t('Replenish deposit')}
              </Button>
            </>
          )}
        </div>
      )}

      <Dialog open={replenishPointsModal} onClose={handleReplenishModal} aria-labelledby="replenish-deposit">
        <div className={styles.modal__wrap}>
          <div className={styles.modal__head}>
            <h2>{t('common:Replenish deposit')}</h2>
            <Button onClick={handleReplenishModal} className={styles.button__close}>
              <CloseIcon />
            </Button>
          </div>

          {isProfileMember && (
            <RadioGroup aria-label="PointsType" name="PointsType" value={typePoints} onChange={handleChangePoints} row>
              <FormControlLabel value={TypePoints.CP} control={<Radio />} label={TypePoints.CP} />
              <FormControlLabel value={TypePoints.SP} control={<Radio />} label={TypePoints.SP} />
            </RadioGroup>
          )}

          <TextField
            label={t(`common:Amount of ${typePoints}`)}
            type="number"
            variant="outlined"
            value={pointsValue}
            onChange={handleChangePointsAmount}
            autoFocus
            fullWidth
          />
          <div className={styles.modal__foot}>
            <Button onClick={handleReplenishRequestPoints} variant="contained" color="primary">
              {t('Replenish')}
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog open={reducePointsModal} onClose={handleReduceModal} aria-labelledby="reduce-deposit">
        <div className={styles.modal__wrap}>
          <div className={styles.modal__head}>
            <h2>{t('common:Reduce deposit')}</h2>
            <Button onClick={handleReduceModal} className={styles.button__close}>
              <CloseIcon />
            </Button>
          </div>

          {isProfileMember && (
            <RadioGroup aria-label="PointsType" name="PointsType" value={typePoints} onChange={handleChangePoints} row>
              <FormControlLabel value={TypePoints.CP} control={<Radio />} label={TypePoints.CP} />
              <FormControlLabel value={TypePoints.SP} control={<Radio />} label={TypePoints.SP} />
            </RadioGroup>
          )}

          <TextField
            label={t(`common:Amount of ${typePoints}`)}
            type="number"
            variant="outlined"
            value={pointsValue}
            onChange={handleChangePointsAmount}
            autoFocus
            fullWidth
          />
          <div className={styles.modal__foot}>
            <Button onClick={handleReduceRequestPoints} variant="contained" color="primary">
              {t('Reduce')}
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog open={deleteUserModal} onClose={handleDeleteUserModal} aria-labelledby="reduce-deposit">
        <div className={styles.modal__wrap}>
          <div className={styles.modal__head}>
            <h2>{t('common:Delete user account')}</h2>
            <Button onClick={handleDeleteUserModal} className={styles.button__close}>
              <CloseIcon />
            </Button>
          </div>
          <div>{t('common:Are you sure you want to delete the user account?')}</div>

          <div className={styles.modal__foot}>
            <Button onClick={handleDeleteUserModal} variant="outlined">
              {t('Cancel')}
            </Button>
            <Button onClick={handleDeleteUserAccount} variant="contained" color="secondary">
              {t('Delete')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
});

export { HeadButtons };
