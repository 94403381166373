import React, { FC } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

interface IProps {
  deleteCategoryItemFn: (id: string) => void;
  name: string;
  id: string;
  isViewPage?: boolean;
}

const CategoryItem: FC<IProps> = observer(({ name, id, deleteCategoryItemFn, isViewPage }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>{name}</h2>
        {!isViewPage && (
          <IconButton
            aria-label="delete"
            style={{ width: '20px', height: '20px', padding: '0px' }}
            onClick={() => deleteCategoryItemFn(id)}
          >
            <DeleteIcon style={{ width: '20px', height: '20px' }} />
          </IconButton>
        )}
      </div>
    </div>
  );
});

export { CategoryItem };
