import { useEffect, useRef } from 'react';
export default function useOnChange(onChange: () => void, dependency: any) {
  const dependencyRef = useRef(dependency);
  useEffect(() => {
    if (dependencyRef.current !== dependency) {
      dependencyRef.current = dependency;
      onChange();
    }
  }, [dependency, onChange]);
}
