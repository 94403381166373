export enum ServiceRequestStatus {
  ToDo = 'TO_DO',
  WaitPayment = 'WAIT_PAYMENT',
  Paid = 'PAID',
  Done = 'DONE',
  Declined = 'DECLINED',
}

export const SERVICE_REQUEST_STATUS_NAMES = {
  [ServiceRequestStatus.ToDo]: 'To do',
  [ServiceRequestStatus.WaitPayment]: 'Wait payment',
  [ServiceRequestStatus.Paid]: 'Paid',
  [ServiceRequestStatus.Done]: 'Done',
  [ServiceRequestStatus.Declined]: 'Declined',
};

export const SERVICE_REQUEST_STATUS_NAME_COLORS = {
  [ServiceRequestStatus.ToDo]: '#212121',
  [ServiceRequestStatus.WaitPayment]: '#DC9B2D',
  [ServiceRequestStatus.Paid]: '#1E6AB2',
  [ServiceRequestStatus.Done]: '#3A9900',
  [ServiceRequestStatus.Declined]: '#B8481F',
};

export enum ServiceRequestDynamicFields {
  Date = 'DATE',
  Time = 'TIME',
  Input = 'INPUT',
  Number = 'NUMBER',
  Radio = 'RADIO_GROUP',
  Select = 'SINGLE_SELECTION',
  Stepper = 'STEPPER',
  Image = 'ATTACHMENT_IMAGE',
}

export enum ServiceRequestTypeMessage {
  Plain = 'PLAIN',
  TransferRequest = 'TRANSFER_REQUEST',
}

export enum CustomMimeType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

export enum ServiceRequestMessageContentType {
  Text = 'TEXT',
  Image = 'IMAGE',
  Audio = 'AUDIO',
}

export enum WithdrawRequestStatus {
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Approved = 'APPROVED',
  Cashout = 'CASH_OUT',
}

export const WITHDRAW_REQUEST_STATUS_NAMES = {
  [WithdrawRequestStatus.Pending]: 'Pending',
  [WithdrawRequestStatus.Declined]: 'Declined',
  [WithdrawRequestStatus.Approved]: 'Approved',
  [WithdrawRequestStatus.Cashout]: 'Cash out',
};

export const WITHDRAW_REQUEST_STATUS_NAME_COLORS = {
  [WithdrawRequestStatus.Pending]: '#DC9B2D',
  [WithdrawRequestStatus.Declined]: '#B8481F',
  [WithdrawRequestStatus.Approved]: '#1E6AB2',
  [WithdrawRequestStatus.Cashout]: '#1E6AB2',
};

export enum ExchangeRequestStatus {
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Approved = 'APPROVED',
}

export const EXCHANGE_REQUEST_STATUS_NAMES = {
  [WithdrawRequestStatus.Pending]: 'Pending',
  [WithdrawRequestStatus.Declined]: 'Declined',
  [WithdrawRequestStatus.Approved]: 'Approved',
};

export const EXCHANGE_REQUEST_STATUS_NAME_COLORS = {
  [WithdrawRequestStatus.Pending]: '#DC9B2D',
  [WithdrawRequestStatus.Declined]: '#B8481F',
  [WithdrawRequestStatus.Approved]: '#1E6AB2',
};

export enum TransferRequestStatus {
  Pending = 'PENDING',
  ApprovedByAdmin = 'APPROVED_BY_ADMIN',
  ApprovedByUser = 'APPROVED_BY_USER',
  Rejected = 'REJECTED',
}

export const TRANSFER_REQUEST_STATUS_NAMES = {
  [TransferRequestStatus.Pending]: 'Pending',
  [TransferRequestStatus.Rejected]: 'Rejected',
  [TransferRequestStatus.ApprovedByAdmin]: 'Approved by admin',
  [TransferRequestStatus.ApprovedByUser]: 'Approved by user',
};

export const TRANSFER_REQUEST_STATUS_NAME_COLORS = {
  [TransferRequestStatus.Pending]: '#DC9B2D',
  [TransferRequestStatus.Rejected]: '#B8481F',
  [TransferRequestStatus.ApprovedByAdmin]: '#1E6AB2',
  [TransferRequestStatus.ApprovedByUser]: '#1E6AB2',
};
