import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';

type IProps = IEntityFieldProps;

const UserPasswordInput: FC<IProps> = observer(({ label, value, handler, isEditing, error }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const handlePasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!handler) return;
      handler(e.target.value);
    },
    [handler],
  );

  return (
    <div>
      {isEditing ? (
        <FormControl variant="outlined">
          <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
          <OutlinedInput
            id="standard-adornment-password"
            label={label}
            type={showPassword ? 'text' : 'password'}
            value={value ? value : ''}
            onChange={handlePasswordChange}
            error={!!error}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </FormControl>
      ) : (
        <div className={styles.input__wrap}>
          <input
            className={styles.input__pass}
            type={showPassword ? 'text' : 'password'}
            value={value ? value : ''}
            disabled
          />
          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>
      )}
    </div>
  );
});
export { UserPasswordInput };
