import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useConfig } from 'icerockdev-admin-toolkit';

interface IProps {}

const AuthLayout: FC<IProps> = observer(({ children }) => {
  const config = useConfig();
  const location = useLocation();
  const { t } = useTranslation();
  const isRestoreRoute = location.pathname === '/restore';

  return (
    <div className={styles.layout}>
      <div className={styles.left}>
        <img src={config.logo} alt="Concierge" draggable={false} className={styles.logo} />
        <div className={styles.left__wrap}>
          <div className={styles.form}>
            <div className={styles.content}>{children}</div>
            {!isRestoreRoute && (
              <div className={styles.form__addition}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox color="primary" />} label={t('buttons:Remember me')} />
                </FormGroup>
                <Link to="/restore">{t('buttons:Restore access to account')}</Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.image} />
    </div>
  );
});

export { AuthLayout };
