import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '~/components/icons/CloseIcon';
import { BankTransfersEntity } from '../BankTransfersEntity';
import { BankTransferStatus } from '~/utils/constants/bank-transfer.constants';

interface IProps {
  isLoading: boolean;
  data: Record<string, string>[];
  entity: BankTransfersEntity;
}

const HeadButtons: FC<IProps> = observer(({ data, entity, isLoading }) => {
  const { t } = useTranslation();
  const selectedBankTransfer = entity.selected;

  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');

  const onChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const handleModal = (): void => {
    setApproveModal(prevState => !prevState);
  };

  const handleReject = useCallback(() => {
    if (!window.confirm(t('messages:Do you really want the bank transfer to be cancelled?'))) return;
    entity.updateRequest(selectedBankTransfer, BankTransferStatus.Cancelled);
  }, [entity, selectedBankTransfer, t]);

  const handleApprove = useCallback(() => {
    if (!amount) return;
    entity.updateRequest(selectedBankTransfer, BankTransferStatus.Done, Number(amount));
    setAmount('');
    setApproveModal(false);
  }, [amount, entity, selectedBankTransfer]);

  return (
    <>
      <div className={styles.wrap}>
        <Button
          variant="contained"
          color="primary"
          className={styles.button}
          disableElevation
          onClick={handleModal}
          disabled={isLoading || !selectedBankTransfer.length}
        >
          {t('buttons:Approve')}
        </Button>
        <Button
          variant="outlined"
          color="default"
          className={styles.button}
          onClick={handleReject}
          disabled={isLoading || !selectedBankTransfer.length}
        >
          {t('buttons:Reject')}
        </Button>
      </div>

      <Dialog open={approveModal} onClose={handleModal} aria-labelledby="form-dialog-title">
        <div className={styles.modal__wrap}>
          <div className={styles.modal__head}>
            <h2>{t('common:Factual amount')}</h2>
            <Button onClick={handleModal} className={styles.button__close}>
              <CloseIcon />
            </Button>
          </div>
          <TextField
            label={t('common:Amount')}
            variant="outlined"
            value={amount}
            onChange={onChangeAmount}
            type="number"
            autoFocus
            fullWidth
          />
          <div className={styles.modal__foot}>
            <Button onClick={handleApprove} variant="contained" color="primary">
              {t('buttons:Send')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
});

export { HeadButtons };
