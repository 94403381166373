import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';

import { ServiceRequestEntity } from '../ServiceRequestEntity';
import { CustomSelect } from '~/components/common/CustomSelect';
import useDebounce from '~/utils/hooks/useDebounce';
import useOnChange from '~/utils/hooks/useOnChange';

import styles from './styles.module.scss';

type IProps = IEntityFieldProps;

interface Option {
  value: number;
  label: string;
}

const SelectConcierge: FC<IProps> = observer(({ label, value, handler, isEditing, error, data, entity }) => {
  const {
    listConcierges,
    updateConcierge,
    isConsiergesListLoading,
    isUpdateConciergeLoading,
    fetchConcierges,
    fetchConciergesId,
  } = useEntity<ServiceRequestEntity>();
  const requestId = data?.id;
  const isCurrentItemChange = requestId === fetchConciergesId;

  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const { t } = useTranslation();

  const searchQueryThrottle: string = useDebounce(inputValue, 500);

  useEffect(() => {
    if (!isUpdateConciergeLoading) {
      setIsUpdateLoading(isUpdateConciergeLoading);
    }
  }, [isUpdateConciergeLoading]);

  const onSearchQueryThrottleChange = useCallback(() => {
    fetchConcierges(searchQueryThrottle, requestId);
  }, [fetchConcierges, requestId, searchQueryThrottle]);

  useOnChange(onSearchQueryThrottleChange, searchQueryThrottle);

  const onInputChange = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  const onBlur = useCallback(() => {
    setInputValue('');
  }, []);

  const currentConcierge = useMemo(() => {
    if (!!value) {
      return { value: value.id, label: `${value.firstName} ${value.lastName}` };
    }
  }, [value]);

  const options = listConcierges.map(concierge => ({
    value: concierge.id,
    label: `${concierge.firstName} ${concierge.lastName}`,
  }));

  const onSelectConcierge = useCallback(
    (option: Option) => {
      setIsUpdateLoading(true);
      updateConcierge(requestId, option.value, false);

      if (handler) {
        const currentConciergeData = listConcierges.find(concierge => concierge.id === option.value);
        handler(currentConciergeData);
      }
    },
    [requestId, updateConcierge, handler, listConcierges],
  );

  // Allow concierge to assign the request without an assignee to himself
  if (entity?.parent?.auth?.userRole === 'CONCIERGE') {
    if (!value) {
      const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        const { user } = entity?.parent?.auth!;
        onSelectConcierge({ value: user.id!, label: `${user.firstName} ${user.lastName}` });
      };
      return (
        <Button
          variant="outlined"
          onClick={onButtonClick}
          style={{ width: 180, height: 40 }}
          disabled={isUpdateLoading}
        >
          {t('buttons:Assign to me')}
        </Button>
      );
    }
  }

  const disabled = (entity?.parent?.auth?.userRole === 'CONCIERGE' && value) || isUpdateLoading;

  return (
    <>
      {isEditing ? (
        <CustomSelect
          options={options}
          filterOption={() => true}
          onBlur={onBlur}
          error={error}
          label={label}
          onInputChange={onInputChange}
        />
      ) : (
        <div className={styles.wrap}>
          <CustomSelect
            options={options}
            filterOption={() => true}
            value={currentConcierge}
            onChange={onSelectConcierge}
            onInputChange={onInputChange}
            onBlur={onBlur}
            error={error}
            height={40}
            width={120}
            isDisabled={disabled}
            isLoading={(isCurrentItemChange && isConsiergesListLoading) || isUpdateLoading}
          />
        </div>
      )}
    </>
  );
});

export { SelectConcierge };
