import { fetchExchangeRequests, updateExchangeRequest } from './api';
import { EXCHANGE_REQUEST_FIELDS } from './fields';
import { ExchangeRequestEntity } from './components/ExchangeRequestEntity';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';

export default new ExchangeRequestEntity({
  title: 'Exchange request',
  viewable: false,
  editable: false,
  exportable: false,
  creatable: false,
  selectable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.exchangeRequests, method: 'get' },
    update: { url: API_ROUTES.exchangeRequests, method: 'put' },
  },
  menu: {
    label: 'Exchange request',
    url: '/exchange-request',
    enabled: true,
  },
  fields: EXCHANGE_REQUEST_FIELDS,
  fetchItemsFn: fetchExchangeRequests,
  updateItemsFn: updateExchangeRequest,
  roles: [UserRole.Admin, UserRole.SuperAdmin],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin],
    update: [UserRole.Admin, UserRole.SuperAdmin],
  },
});
