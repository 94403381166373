import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import Select from 'react-select';
import styles from './styles.module.scss';
import { ServiceRequestEntity } from '../ServiceRequestEntity';

// TODO: Need fix component

type IProps = IEntityFieldProps;

const SelectUser: FC<IProps> = observer(({ label, value, handler, isEditing, error, data }) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: isEditing && '56px',
      border: error ? '1px solid #ef4444' : '',
      cursor: 'pointer',
    }),
    menu: (provided: any) => ({ ...provided, zIndex: '99' }),
    option: (provided: any) => ({ ...provided, cursor: 'pointer' }),
    placeholder: (provided: any) => ({ ...provided, paddingLeft: '4px' }),
    singleValue: (provided: any) => ({ ...provided, paddingLeft: '4px' }),
    input: (provided: any) => ({ ...provided, paddingLeft: '4px' }),
    indicatorSeparator: () => ({}),
  };

  const entity = useEntity<ServiceRequestEntity>();
  const [usersOptions, setUsersOptions] = useState<{ label: string; value: string }[]>([]);

  return (
    <div
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
      }}
      className={styles.wrap}
    >
      {isEditing ? (
        <>
          <label className={styles.label}>{label}</label>
          <Select
            options={usersOptions}
            styles={customStyles}
            onChange={handler}
            onInputChange={() => {}}
            isClearable
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#F7E7CE',
                primary: '#DC9B2D',
              },
            })}
          />
          {error && <p className={styles.error}>Required field</p>}
        </>
      ) : (
        <div>{`${value?.firstName} ${value?.lastName}`}</div>
      )}
    </div>
  );
});
export { SelectUser };
