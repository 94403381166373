import Axios from 'axios';
import { projectErrorInterceptor } from '~/utils/interceptor';
import qs from 'query-string';

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'none' });
  },
});

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

axios.interceptors.response.use(response => response, projectErrorInterceptor);
