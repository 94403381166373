import { IEntityField } from 'icerockdev-admin-toolkit';
import BusinessOwnerName from '~/pages/reports/components/BusinessOwnerField';

export const REPORT_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'shopName',
    label: 'Shop Name',
    required: false,
  },
  {
    type: 'number',
    name: 'countOfTransactions',
    label: 'Transactions',
    required: false,
  },
  {
    type: 'number',
    name: 'amountOfCP',
    label: 'Earned Concierge Points',
    required: false,
  },
  {
    type: 'string',
    name: 'businessOwner',
    label: 'Business Owner',
    required: false,
    component: BusinessOwnerName,
  },
];
