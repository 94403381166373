import { IEntityField } from 'icerockdev-admin-toolkit';

export const CATEGORY_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'Id',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    required: true,
  },
];
