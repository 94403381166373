import React, { FC, useCallback, useRef } from 'react';
import styles from './styles.module.scss';
import { useConfig } from 'icerockdev-admin-toolkit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBox from '@material-ui/icons/AccountBoxOutlined';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { USER_ROLE_NAMES } from '~/utils/constants/roles.constants';
import { useHistory } from 'react-router-dom';

interface IProps {
  isShowProfile: boolean;
}

const UserPanel: FC<IProps> = observer(({ isShowProfile }: IProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const config = useConfig();
  const { t } = useTranslation();
  const history = useHistory();

  const onLogout = useCallback(() => {
    if (!window.confirm(t('Do you really want to quit?'))) return;
    config.auth?.logout();
  }, [config.auth, t]);

  const userRoleTitle = config.auth?.userRoleTitle;

  return (
    <div className={styles.account} ref={ref}>
      <div className={styles.user__wrap}>
        <div className={styles.name}>{config.auth?.userName}</div>
        <div className={styles.role}>{userRoleTitle && t(`fields:${USER_ROLE_NAMES[userRoleTitle]}`)}</div>
      </div>
      <div className={styles.buttons__wrap}>
        {isShowProfile && (
          <div className={styles.button} onClick={() => history.push('/profile')}>
            <AccountBox />
            {t('buttons:Profile')}
          </div>
        )}

        <div className={styles.button} onClick={onLogout}>
          <ExitToAppIcon />
        </div>
      </div>
    </div>
  );
});

export { UserPanel };
