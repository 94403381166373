import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';

interface IProps {
  children: ReactNode;
}

const MiddleMainInfoContainer: FC<IProps> = observer(({ children }) => {
  return <div className={styles.container}>{children}</div>;
});

export { MiddleMainInfoContainer };
