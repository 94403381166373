export enum UserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export enum UserRole {
  User = 'USER',
  Member = 'MEMBER',
  Concierge = 'CONCIERGE',
  Admin = 'ADMIN',
  SuperAdmin = 'SUPERADMIN',
  Business = 'BUSINESS',
}

export enum ShopRole {
  Sales_Planner = 'SALES_PLANNER',
  Site_Planner_A = 'SITE_PLANNER_A',
  Site_Planner_B = 'SITE_PLANNER_B',
  Affiliate_Planner = 'AFFILIATE_PLANNER',
  Business_Owner = 'BUSINESS_OWNER',
}

export const USER_STATUS_NAMES = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Blocked]: 'Blocked',
};

export const USER_ROLE_NAMES = {
  [UserRole.User]: 'User',
  [UserRole.Member]: 'Member',
  [UserRole.Concierge]: 'Concierge',
  [UserRole.Admin]: 'Admin',
  [UserRole.SuperAdmin]: 'Superadmin',
  [UserRole.Business]: 'Business',
};

export const USER_ROLE_NAMES_WITHOUT_SUPERADMIN = {
  [UserRole.User]: 'User',
  [UserRole.Member]: 'Member',
  [UserRole.Concierge]: 'Concierge',
  [UserRole.Admin]: 'Admin',
  [UserRole.Business]: 'Business',
};
