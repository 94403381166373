import React, { FC, useEffect, useState } from 'react';
import { useEntity } from 'icerockdev-admin-toolkit';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { TextField } from '@material-ui/core';
import { QrCodesEntity } from '~/pages/qr-codes/components/QrCodesEntity';
import ja from 'date-fns/locale/ja';

interface IProps {
  label: string;
  isFiltering?: boolean;
}

const QrCodesFilterDateRange: FC<IProps> = ({ label, isFiltering }: IProps) => {
  const { t, i18n } = useTranslation();
  const { setFilters } = useEntity<QrCodesEntity>();

  const [{ dateStart, dateEnd }, setRangeDate] = useState<{ dateStart: Date | null; dateEnd: Date | null }>({
    dateStart: null,
    dateEnd: null,
  });

  useEffect(() => {
    if (dateStart && dateEnd) {
      const rangeDateFilters = [
        { name: 'dateStart', value: format(dateStart, 'yyyy-MM-dd') },
        { name: 'dateEnd', value: format(dateEnd, 'yyyy-MM-dd') },
      ];

      setFilters(rangeDateFilters);
    }
  }, [dateStart, dateEnd]);

  return isFiltering ? (
    <div style={{ zIndex: 9999 }}>
      <DatePicker
        customInput={<TextField variant="outlined" label={t(label)} style={{ width: 220 }} />}
        startDate={dateStart}
        endDate={dateEnd}
        onChange={([dateStart, dateEnd]) => setRangeDate({ dateStart, dateEnd })}
        locale={i18n.language === 'ja' ? ja : undefined}
        dateFormat="yyyy.MM.dd"
        selectsRange
      />
    </div>
  ) : null;
};

export { QrCodesFilterDateRange };
