import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  id: number;
  url: string;
}

const EditButton: FC<IProps> = ({ id, url }) => {
  const { t } = useTranslation();

  return (
    <Button
      to={`${url}/${id}/edit`}
      component={RouterLink}
      variant="contained"
      color="primary"
      type="button"
      style={{ height: '50px' }}
    >
      {t('buttons:Edit')}
    </Button>
  );
};

export { EditButton };
