import { IEntityGetFunction } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';
import {
  FieldsEnum,
  IEntityCreateItemProps,
  IEntityCreateItemResult,
  IEntityCreateShopProps,
  IEntityCreateShopResult,
  IEntityShopFetchFunction,
  IUploadFileFn,
} from '~/utils/types/shops.types';
import { finalShopData } from '~/pages/shops/helper';
import { IEntityGetFunctionProps } from '~/utils/types/category.types';

export const uploadFileFn: IUploadFileFn = async ({ url, file, token }) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data', authorization: token },
  });

  return response.data.data;
};

export const getItemsFn: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  });
  if (result?.data?.data?.nonPublic) {
    const nonPublicObj = { ...result?.data.data.nonPublic };
    // Changing fields from backend
    nonPublicObj[FieldsEnum.SHOP_REPRESENTATIVE_GENDER] = String(nonPublicObj[FieldsEnum.SHOP_REPRESENTATIVE_GENDER]);
    nonPublicObj[FieldsEnum.MULTIPLE_SHOPS] = nonPublicObj[FieldsEnum.MULTIPLE_SHOPS] ? '1' : '0';
    nonPublicObj[FieldsEnum.MULTIPLE_BRANCHES] = nonPublicObj[FieldsEnum.MULTIPLE_BRANCHES] ? '1' : '0';
    nonPublicObj[FieldsEnum.IS_PARTNER_ALLOWED] = nonPublicObj[FieldsEnum.IS_PARTNER_ALLOWED] ? '1' : '0';
    nonPublicObj[FieldsEnum.BUSINESS_CATEGORY] = String(nonPublicObj[FieldsEnum.BUSINESS_CATEGORY]);
    nonPublicObj[FieldsEnum.BANK_TYPE] = String(nonPublicObj[FieldsEnum.BANK_TYPE]);
    result.data.data.nonPublic = { ...nonPublicObj };
  }

  return {
    data: result?.data?.data || {},
    error: '',
  };
};

export const updateShopFn = async ({ url, token, data }: IEntityCreateShopProps): Promise<IEntityCreateShopResult> => {
  const finalData = finalShopData(data);
  const result = await axios.put(url, finalData, {
    headers: { authorization: token },
  });
  return {
    data: result.data,
  };
};

export const createShopFn = async ({ url, token, data }: IEntityCreateShopProps): Promise<IEntityCreateShopResult> => {
  const finalData = finalShopData(data);
  const result = await axios.post(url, finalData, {
    headers: { authorization: token },
  });
  return {
    data: result.data,
  };
};

export const deleteItemFn: IEntityGetFunction = async ({ url, token }) => {
  const result = await axios.delete(`${url}`, {
    headers: { authorization: token },
  });

  return result.data;
};

export const addItemFn = async ({ url, token, data }: IEntityCreateItemProps): Promise<IEntityCreateItemResult> => {
  const result = await axios.post(
    url,
    {
      shopId: data.shopId,
      imageKey: data.imageKey,
      name: data.name,
      description: data.description,
      isHide: data.checkbox,
    },
    {
      headers: { authorization: token },
    },
  );
  return result.data;
};

export const updateItemFn = async ({ url, token, data }: IEntityCreateItemProps): Promise<IEntityCreateItemResult> => {
  const result = await axios.put(
    url,
    {
      shopId: data.shopId,
      imageKey: data.imageKey,
      name: data.name,
      description: data.description,
      isHide: data.checkbox,
    },
    {
      headers: { authorization: token },
    },
  );
  return result.data;
};

export const getCategories = async ({ url, token, name }: IEntityGetFunctionProps) => {
  const params: { limit?: string; name?: string } = {};
  if (name !== '') {
    params.name = name;
  }
  const result = await axios.get(`${url}`, {
    headers: {
      authorization: token,
    },
    params,
  });

  return result.data.data.map(category => {
    return {
      value: category.id,
      label: category.name,
    };
  });
};

export const getCommitment = async ({ url, token, id }: IEntityGetFunctionProps) => {
  const result = await axios.get(`${url}?shopId=${id}`, {
    headers: {
      authorization: token,
    },
  });

  return result.data.data.map(item => {
    return {
      id: item?.id,
      description: item?.description,
      imageUrl: item?.imageData?.imageUrl,
      imageKey: item?.imageData?.imageKey,
      checkbox: item?.isHide,
      name: item?.name,
      imageBase64: '',
    };
  });
};

export const getProduct = async ({ url, token, id }: IEntityGetFunctionProps) => {
  const result = await axios.get(`${url}?shopId=${id}`, {
    headers: {
      authorization: token,
    },
  });

  return result.data.data.map(item => {
    return {
      id: item?.id,
      description: item?.description,
      imageUrl: item?.imageData?.imageUrl,
      imageKey: item?.imageData?.imageKey,
      checkbox: item?.isHide,
      name: item?.name,
      imageBase64: '',
    };
  });
};

export const fetchItemsFn: IEntityShopFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
  query,
}) => {
  const offset = page && count ? page * count : 0;

  const orderBy = sortDir?.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value?.trim() }), {}) || {};

  const response = await axios.get(url, {
    params: {
      query,
      offset,
      limit: count,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
    },
    headers: { authorization: token },
  });

  const list = response?.data?.data || [];

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {},
    error: '',
  };
};
