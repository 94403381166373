import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  value: string;
  statusNames: { [key: string]: string };
  statusNameColors: { [key: string]: string };
}

const ColoredStatus: FC<IProps> = ({ value, statusNames, statusNameColors }) => {
  const { t } = useTranslation();

  return <div style={{ color: statusNameColors[value] }}>{t(`fields:${statusNames[value]}`)}</div>;
};

export { ColoredStatus };
