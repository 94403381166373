import { Entity } from 'icerockdev-admin-toolkit';
import { action, computed, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { CustomEntityHead } from '~/components/entity/CustomEntityHead';
import { CustomEntityList } from '~/components/entity/CustomEntityList';
import { createItemsFn, fetchItemsFn } from '~/utils/api';
import { getErrorMessage } from '~/utils/axios';
import { ServiceRequestStatus, ServiceRequestTypeMessage } from '~/utils/constants/requests.constants';
import { UserRole, UserStatus } from '~/utils/constants/roles.constants';
import {
  IMessage,
  IServiceRequest,
  IServiceScreen,
  IServiceType,
  ITransferRequest,
  IUpdateServiceRequest,
} from '~/utils/types/requests.types';
import { IUser } from '~/utils/types/users.types';
import { getItems, updateRequest, uploadFile } from '../../api';
import { Chat } from '../Chat';
import { EntityCreator } from '../EntityCreator';
import { EntityViewer } from '../EntityViewer';
import { HeadButtons } from '../HeadButtons';

class ServiceRequestEntity extends Entity {
  @observable listConcierges: IUser[] = [];
  @observable isConsiergesListLoading: boolean = false;

  @observable listUsers: IUser[] = [];
  @observable isUsersListLoading: boolean = false;

  @observable listTypes: IServiceType[] = [];
  @observable isTypesListLoading: boolean = false;

  @observable listScreens: IServiceScreen[] = [];
  @observable isScreensListLoading: boolean = false;

  @observable listMessages: IMessage[] = [];
  @observable isMessagesListLoading: boolean = false;
  @observable newMyMessage: IMessage | null = null;
  @observable newUserMessage: IMessage | null = null;

  @observable listTransferRequests: ITransferRequest[] = [];
  @observable isTransferRequestsListLoading: boolean = false;

  @observable isUpdateConciergeLoading: boolean = false;
  @observable fetchConciergesId: number | null = null;

  @observable isEditing: boolean = false;

  @action
  onMount = () => {
    this.getFiltersFromHash();
    reaction(() => [this.filters, this.sortBy, this.sortDir, this.page, this.items], this.setFiltersWindowHash);
    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilter);
    reaction(() => this.page, this.fetchItems);
    reaction(() => this.editorData?.id, this.fetchTransferRequests);
    this.fetchItems();
    this.fetchConcierges();
  };

  @action
  updateConcierge = async (requestId: number, conciergeId: number, updateStatus = true) => {
    if (!this.parent?.auth?.withToken) return;

    this.isUpdateConciergeLoading = true;

    try {
      const result = await this.parent.auth.withToken(updateRequest, {
        url: `${this?.api?.update.url}/${requestId}`,
        data: {
          conciergeId,
          ...(updateStatus && { status: ServiceRequestStatus.ToDo }),
        },
      });

      const data = result?.data?.data;
      const newData = this.data.map(item =>
        item.id === requestId ? { ...data, memberId: data.author.clientId } : item,
      );
      this.data = newData;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isUpdateConciergeLoading = false;
  };

  @action
  fetchConcierges = async (query?: string, requestId?: number) => {
    if (!this.parent?.auth?.withToken) return;
    if (this.parent?.auth?.user.role === UserRole.Concierge) return;

    if (requestId) {
      this.fetchConciergesId = requestId;
    }

    this.isConsiergesListLoading = true;

    try {
      const result = await this.parent.auth.withToken(fetchItemsFn, {
        url: this?.api?.usersList.url,
        filter: [
          { name: 'role', value: UserRole.Concierge },
          { name: 'status', value: UserStatus.Active },
          { name: 'query', value: query },
        ],
        sortDir: 'asc',
        page: 0,
        count: 20,
      });

      this.listConcierges = result?.data?.list;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isConsiergesListLoading = false;
    this.fetchConciergesId = null;
  };

  @action
  fetchUsers = async (query?: string) => {
    if (!this.parent?.auth?.withToken) return;

    this.isUsersListLoading = true;

    try {
      const result = await this.parent.auth.withToken(fetchItemsFn, {
        url: `${this?.api?.usersList.url}?role=${UserRole.Member}&role=${UserRole.User}`,
        filter: [
          { name: 'status', value: UserStatus.Active },
          { name: 'query', value: query },
        ],
        sortDir: 'asc',
        page: 0,
        count: 20,
      });

      this.listUsers = result?.data?.list;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isUsersListLoading = false;
  };

  @action
  fetchTypes = async () => {
    if (!this.parent?.auth?.withToken) return;

    this.isTypesListLoading = true;

    try {
      const result = await this.parent.auth.withToken(getItems, {
        url: this?.api?.requestTypes.url,
      });

      this.listTypes = result?.data;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isTypesListLoading = false;
  };

  @action
  fetchScreens = async (id: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isScreensListLoading = true;

    try {
      const result = await this.parent.auth.withToken(getItems, {
        url: `${this?.api?.requestTypes.url}/${id}`,
      });

      this.listScreens = result?.data?.screens;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isScreensListLoading = false;
  };

  // TODO: Need fixed after added filter API

  @action
  fetchTransferRequests = async () => {
    if (!this.parent?.auth?.withToken) return;

    this.isTransferRequestsListLoading = true;

    try {
      const params = {
        url: `${this?.api?.transferRequests.url}`,
        sortDir: 'asc',
        page: 0,
        count: 1000,
      };

      if (this.editorData?.id) {
        params['filter'] = [{ name: 'serviceRequestId', value: this.editorData?.id }];
      }

      const result = await this.parent.auth.withToken(fetchItemsFn, params);

      this.listTransferRequests = result?.data?.list;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isTransferRequestsListLoading = false;
  };

  @action
  closeRequest = async (requestId: string) => {
    if (!this.parent?.auth?.withToken) return;

    try {
      await this.parent.auth.withToken(updateRequest, {
        url: `${this?.api?.update.url}/${requestId}`,
        data: {
          status: ServiceRequestStatus.Done,
        },
      });
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.fetchItems();
  };

  @action
  fetchMessages = async (requestId: number, page: number, isFirstLoading: boolean) => {
    if (!this.parent?.auth?.withToken) return;

    this.isMessagesListLoading = true;

    try {
      const result = await this.parent.auth.withToken(fetchItemsFn, {
        url: `${this?.api?.list.url}/${requestId}/message`,
        sortDir: 'asc',
        page,
        count: 10,
      });

      const messages: IMessage[] = result?.data?.list;

      const fetchSystemMessageStatus = async (id: number) => {
        if (!this.parent?.auth?.withToken) return;

        try {
          return await this.parent?.auth.withToken(getItems, {
            url: `${this?.api?.transferRequests.url}/${id}`,
          });
        } catch (err) {
          this.parent?.notifications.showError(getErrorMessage(err));
        }
      };

      await Promise.all(
        messages
          .filter(msg => msg.type === ServiceRequestTypeMessage.TransferRequest)
          .map(msg =>
            fetchSystemMessageStatus(msg.extraData.transferRequestId).then(
              message => (msg.extraData.status = message?.data?.status),
            ),
          ),
      );

      if (isFirstLoading) {
        this.listMessages = [...this.listMessages, ...messages];
      } else {
        messages.forEach(msg => {
          if (this.listMessages.every(m => m.id !== msg.id)) {
            this.listMessages.unshift(msg);

            this.newUserMessage = msg;
          }
        });
      }
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isMessagesListLoading = false;
  };

  @action
  sendMessage = async (requestId: number, messageText: string, attachmentKeys?: string[]) => {
    if (!this.parent?.auth?.withToken) return;

    this.isMessagesListLoading = true;

    try {
      const result = await this.parent.auth.withToken(createItemsFn, {
        url: `${this?.api?.update.url}/${requestId}/message`,
        data: {
          messageText: messageText.trim(),
          attachmentKeys,
        },
      });

      const newMessages: IMessage[] = result?.data?.data;

      if (!this.listMessages.length) {
        this.listMessages = newMessages;
      }

      if (!!this.listMessages.length) {
        newMessages.reverse().forEach(msg => {
          if (this.listMessages.every(m => m.id !== msg.id)) {
            this.listMessages.unshift(msg);

            this.newMyMessage = msg;
          }
        });
      }
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.isMessagesListLoading = false;
  };

  @action
  requestTransferPoints = async (requestId: number, amount: string) => {
    if (!this.parent?.auth?.withToken) return;

    try {
      await this.parent.auth.withToken(createItemsFn, {
        url: this?.api?.createTransferRequest.url,
        data: {
          requestId: requestId,
          amount: Number(amount),
        },
      });
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.getItem(requestId);
    this.fetchItems();
  };

  @action
  paidRequest = async (requestId: string) => {
    if (!this.parent?.auth?.withToken) return;

    try {
      await this.parent.auth.withToken(updateRequest, {
        url: `${this?.api?.update.url}/${requestId}`,
        data: {
          status: ServiceRequestStatus.Paid,
        },
      });
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.getItem(requestId);
    this.fetchItems();
  };

  @action
  declineRequest = async (requestId: string) => {
    if (!this.parent?.auth?.withToken) return;

    try {
      await this.parent.auth.withToken(updateRequest, {
        url: `${this?.api?.update.url}/${requestId}`,
        data: {
          status: ServiceRequestStatus.Declined,
        },
      });
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }

    this.getItem(requestId);
    this.fetchItems();
  };

  @action
  createNewRequest = async (data: IServiceRequest) => {
    if (!this.parent?.auth?.withToken) return;

    try {
      await this.parent.auth.withToken(createItemsFn, {
        url: this?.api?.create.url,
        data,
      });

      this.parent.history.push(this.menu.url);
      this.fetchItems();
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }
  };

  @action
  updateServiceRequest = async (data: IUpdateServiceRequest, id: string) => {
    if (!this.parent?.auth?.withToken) return;

    try {
      const response = await this.parent.auth.withToken(updateRequest, {
        url: `${this?.api?.update.url}/${id}`,
        data,
      });

      this.fetchItems();
      this.editorData = response?.data?.data;
    } catch (err) {
      this.parent?.notifications.showError(getErrorMessage(err));
    }
  };

  @action
  onUploadFileOnChat = async (file: File | Blob, requestId: number, messageText: string) => {
    try {
      const result = await this.parent?.auth?.withToken(uploadFile, {
        url: this.api?.uploadFile.url,
        file,
      });

      if (result?.success) {
        this.sendMessage(requestId, messageText, [result?.data]);
      }
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
    }
  };

  @action
  onUploadFile = async (file: File) => {
    try {
      const response = await this.parent?.auth?.withToken(uploadFile, {
        url: this.api?.uploadFile.url,
        file,
      });

      return response?.data;
    } catch (e) {
      this.parent?.notifications.showError(getErrorMessage(e));
    }
  };

  @computed
  get ListHead() {
    return observer(() => (
      <CustomEntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListBody() {
    return observer(() => {
      const allowedRoles = [UserRole.Admin, UserRole.SuperAdmin, UserRole.Concierge];
      const filteredFields = this.fields.filter(item => item.name !== 'concierge');
      const fields = allowedRoles.includes(this.parent?.auth?.userRole as UserRole) ? this.fields : filteredFields;

      return (
        <CustomEntityList
          fields={fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable && this.canView}
          canEdit={this.editable && this.canEdit}
          canSelect={this.selectable}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }

  @computed
  get ViewerBody() {
    return observer(({ id }: { id: string }) => {
      const allowedRoles = [UserRole.Admin, UserRole.SuperAdmin];
      const filteredFields = this.fields.filter(item => item.name !== 'concierge');
      const fields = allowedRoles.includes(this.parent?.auth?.userRole as UserRole) ? this.fields : filteredFields;

      if (!this.canView) return this.forbiddenPlaceholder;

      return (
        <div style={{ display: 'flex', gap: '1.5rem' }}>
          <EntityViewer
            id={id}
            fields={fields}
            url={this.menu.url}
            errors={this.editorFieldErrors}
            onSave={() => {}}
            onCancel={this.onEditCancel}
            onResetFieldError={this.resetFieldError}
            isEditing={this.isEditing}
            isLoading={this.isLoading}
            setEditorData={this.setEditorData}
            data={this.editorData}
            getItem={this.getItem}
            cancelGetItem={this.getItemsCancel}
            withToken={this.parent?.auth?.withToken}
            viewable={this.viewable}
            entity={this}
          />

          <Chat
            id={id}
            entity={this}
            data={this.listMessages}
            isLoading={this.isLoading}
            newMyMessage={this.newMyMessage}
            newUserMessage={this.newUserMessage}
            isMessagesLoading={this.isMessagesListLoading}
          />
        </div>
      );
    });
  }

  @computed
  get CreatorBody() {
    return observer(() => {
      if (!this.canView) return this.forbiddenPlaceholder;

      return (
        <EntityCreator url={this.menu.url} onCancel={this.onEditCancel} isLoading={this.isLoading} entity={this} />
      );
    });
  }

  @computed
  get ViewerHeadButtons() {
    const allowedRoles = [UserRole.Admin, UserRole.SuperAdmin];

    return observer(({ id }: { id: string }) => (
      <HeadButtons
        id={id}
        data={this.editorData}
        listTransferRequests={this.listTransferRequests}
        isLoading={this.isLoading}
        isAdmin={allowedRoles.includes(this.parent?.auth?.userRole as UserRole)}
      />
    ));
  }
}

export { ServiceRequestEntity };
