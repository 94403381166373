import i18next from 'i18next';

export function validatePhone(value: string) {
  const phoneCodes = ['7', '8', '9'];
  const isMobilePhone = phoneCodes.includes(value?.[1]);

  if (isMobilePhone) {
    if (value && value.length < 11) {
      return i18next.t('messages:Mobile phone must contain minimum 11 characters');
    }
  } else {
    if (value && value.length < 10) {
      return i18next.t('messages:Landing phone must contain minimum 10 characters');
    }
  }
  return '';
}
