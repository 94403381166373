import * as React from 'react';

interface PropTypes {
  size?: number;
}

const Component: React.FC<PropTypes> = ({ size = 24, ...props }: PropTypes) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.612 12.55l-6.116 6.191a4.18 4.18 0 01-2.917 1.072 4.185 4.185 0 01-2.85-1.244 4.273 4.273 0 01-1.23-2.88 4.278 4.278 0 011.06-2.95l7.917-8a2.529 2.529 0 011.751-.708c.652 0 1.28.253 1.752.707.46.472.719 1.108.719 1.77 0 .663-.259 1.3-.72 1.77l-6.827 6.892a.757.757 0 01-.818.197.756.756 0 01-.424-.398.77.77 0 01.133-.839l5.077-5.12a1.01 1.01 0 000-1.421.989.989 0 00-1.405 0l-5.077 5.14a2.743 2.743 0 00-.802 1.946 2.766 2.766 0 00.802 1.946c.519.5 1.208.778 1.925.778.716 0 1.406-.279 1.925-.778l6.818-6.902a4.528 4.528 0 001.194-3.148 4.524 4.524 0 00-1.303-3.103 4.43 4.43 0 00-3.07-1.317 4.427 4.427 0 00-3.115 1.207L5.094 11.36a6.29 6.29 0 00-1.591 4.37 6.283 6.283 0 001.838 4.269 6.153 6.153 0 004.25 1.8 6.149 6.149 0 004.3-1.668l6.126-6.181a1.003 1.003 0 00.291-.71 1.012 1.012 0 00-.613-.928.984.984 0 00-1.083.218v.02z"
      fill="#AEA69E"
    />
  </svg>
);

export default Component;
