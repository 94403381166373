import React, { FC } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import styles from './styles.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  id: string;
  imageBase64: string;
  description: string;
  name: string;
  checkbox: boolean;
  onEditClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  isViewPage?: boolean;
  imageUrl: string;
  onChangeCheckbox: (id: string) => void;
}

const TableRowItem: FC<IProps> = ({
  description,
  imageBase64,
  id,
  onEditClick,
  onDeleteClick,
  name,
  checkbox,
  isViewPage,
  imageUrl,
  onChangeCheckbox,
}) => {
  return (
    <tr className={styles.row}>
      <td>
        <img src={imageBase64 || imageUrl} className={styles.image} />
      </td>
      <td>{name}</td>
      <td>{description}</td>
      <td>
        <Checkbox
          style={{ width: '18px', height: '18px' }}
          checked={checkbox}
          color="primary"
          disabled={isViewPage}
          onChange={() => onChangeCheckbox(id)}
        />
      </td>
      <td className={styles.icons}>
        {!isViewPage && (
          <IconButton
            aria-label="delete"
            style={{ width: '30px', height: '30px', padding: '0px' }}
            onClick={() => onEditClick(id)}
          >
            <EditIcon />
          </IconButton>
        )}
      </td>
      <td>
        {!isViewPage && (
          <IconButton
            aria-label="delete"
            style={{ width: '30px', height: '30px', padding: '0px' }}
            onClick={() => onDeleteClick(id)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </td>
    </tr>
  );
};

export { TableRowItem };
