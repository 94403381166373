import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { USER_ROLE_NAMES, USER_STATUS_NAMES } from '~/utils/constants/roles.constants';
import { UserBlockButton } from '~/pages/users/components/UserBlockButton';
import { UserPasswordInput } from './components/UserPasswordInput';
import { validatePassword } from '~/utils/validators/validate-password';
import { UserWalletField } from './components/UserWalletField';
import { TypePoints } from '~/utils/constants/transactions.constants';
import { UserPhoneInput } from './components/UserPhoneInput';
import { validatePhone } from '~/utils/validators/validate-phone';

export const USER_FIELDS: IEntityField[] = [
  {
    type: 'custom',
    name: 'user',
    label: 'User',
    required: true,
    hideInEdit: true,
    hideInCreate: true,
    component: props => <>{`${props.data.firstName} ${props.data.lastName}`}</>,
  },
  {
    type: 'string',
    name: 'lastName',
    label: 'Last Name',
    required: true,
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'string',
    name: 'firstName',
    label: 'First Name',
    required: true,
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'string',
    name: 'clientId',
    label: 'Member ID',
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
    required: true,
  },
  {
    type: 'custom',
    name: 'spWallet',
    label: 'CP Wallet',
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
    component: props => <UserWalletField {...props} typePoints={TypePoints.CP} />,
  },
  {
    type: 'custom',
    name: 'cpWallet',
    label: 'SP Wallet',
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
    component: props => <UserWalletField {...props} typePoints={TypePoints.SP} />,
  },
  {
    type: 'string',
    name: 'seiFurigana',
    label: 'Sei_Furigana',
    hideInList: true,
    required: true,
  },
  {
    type: 'string',
    name: 'meiFurigana',
    label: 'Mei_Furigana',
    hideInList: true,
    required: true,
  },
  {
    type: 'custom',
    name: 'phone',
    label: 'Phone number',
    required: true,
    component: UserPhoneInput,
    validator: validatePhone,
  },
  {
    type: 'string',
    name: 'email',
    label: 'Email',
    required: true,
  },
  {
    type: 'string',
    name: 'password',
    label: 'Password',
    hideInList: true,
    hideInEdit: true,
    hideInView: true,
    required: true,
    component: UserPasswordInput,
    validator: validatePassword,
  },
  {
    type: 'string',
    name: 'postCode',
    label: 'Post code',
    hideInList: true,
    required: true,
  },
  {
    type: 'string',
    name: 'prefectures',
    label: 'Prefecture',
    hideInList: true,
    required: true,
  },
  {
    type: 'string',
    name: 'municipalities',
    label: 'Municipalities',
    hideInList: true,
    required: true,
  },
  {
    type: 'string',
    name: 'streetAddress',
    label: 'Address',
    hideInList: true,
    required: true,
  },
  {
    type: 'string',
    name: 'buildingName',
    label: 'Building name',
    hideInList: true,
    required: false,
  },
  {
    type: 'string',
    name: 'companyName',
    label: 'Company name',
    hideInList: true,
    required: false,
  },
  {
    type: 'select',
    name: 'role',
    label: 'Role',
    options: USER_ROLE_NAMES,
    filterable: true,
    sortable: true,
    required: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: USER_STATUS_NAMES,
    hideInList: true,
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: ' ',
    label: 'Block account',
    hideInEdit: true,
    hideInCreate: true,
    hideInList: true,
    component: UserBlockButton,
  },
];
