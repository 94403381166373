import { IEntityField } from 'icerockdev-admin-toolkit';
import { SelectCountry } from './components/SelectCountry';

export const PROFILE_FIELDS: IEntityField[] = [
  {
    type: 'string',
    name: 'firstName',
    label: 'First Name',
    required: true,
  },
  {
    type: 'string',
    name: 'lastName',
    label: 'Last Name',
    required: true,
  },
  {
    type: 'phone',
    name: 'phone',
    label: 'Phone number',
    required: true,
  },
  {
    type: 'string',
    name: 'email',
    label: 'Email',
    required: true,
  },
  {
    type: 'string',
    name: 'seiFurigana',
    label: 'Sei_Furigana',
    required: true,
  },
  {
    type: 'string',
    name: 'meiFurigana',
    label: 'Mei_Furigana',
    required: true,
  },
  {
    type: 'custom',
    name: 'country',
    label: 'Country',
    component: SelectCountry,
    required: true,
  },
  {
    type: 'string',
    name: 'city',
    label: 'City',
    required: true,
  },
  {
    type: 'string',
    name: 'prefectures',
    label: 'Prefectures',
    required: true,
  },
  {
    type: 'string',
    name: 'municipalities',
    label: 'Municipalities',
    required: true,
  },
  {
    type: 'string',
    name: 'streetAddress',
    label: 'Street address',
    required: true,
  },
  {
    type: 'string',
    name: 'buildingName',
    label: 'Building name',
    required: true,
  },
  {
    type: 'string',
    name: 'companyName',
    label: 'Company name',
    required: true,
  },
  {
    type: 'string',
    name: 'postCode',
    label: 'Post code',
    required: true,
  },
  {
    type: 'string',
    name: 'bankName',
    label: 'Bank name',
    required: true,
  },
  {
    type: 'string',
    name: 'bankCode',
    label: 'Bank code',
    required: true,
  },
  {
    type: 'string',
    name: 'branchName',
    label: 'Branch name',
    required: true,
  },
  {
    type: 'string',
    name: 'branchCode',
    label: 'Branch code',
    required: true,
  },
  {
    type: 'string',
    name: 'accountName',
    label: 'Account name',
    required: true,
  },
  {
    type: 'string',
    name: 'accountNumber',
    label: 'Account number',
    required: true,
  },
];
