import { fetchItemsFn, getItemsFn } from '~/utils/api';
import { REPORT_FIELDS } from './fields';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';
import { ReportEntity } from '~/pages/reports/components/Entity';

export default new ReportEntity({
  title: 'Reports',
  viewable: false,
  editable: false,
  creatable: false,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.reports, method: 'get' },
    get: { url: API_ROUTES.reports, method: 'get' },
  },
  menu: {
    label: 'Reports',
    url: '/reports',
    enabled: true,
  },
  fields: REPORT_FIELDS,
  fetchItemsFn,
  getItemsFn,
  roles: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business],
  },
});
