import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import React, { FC } from 'react';
import ReviewEntity from '~/pages/review/Entity';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

interface IProps extends IEntityFieldProps {
  entity: ReviewEntity;
}

const ApprovedButton: FC<IProps> = ({ value, data }) => {
  const entity = useEntity<ReviewEntity>();
  const location = window.location.href;
  const { t } = useTranslation();

  const onClick = (approved: boolean) => {
    entity.updateReview(data?.id, approved);
  };

  const formattedLocation = location.endsWith('/') ? location.slice(0, -1) : location;
  const isDetailPage = formattedLocation.endsWith(data?.id);

  if (isDetailPage) {
    return (
      <div className={styles.container}>
        {!value && (
          <Button type="button" variant="outlined" className={styles.publishButton} onClick={() => onClick(true)}>
            {t('buttons:Publish review')}
          </Button>
        )}
        <Button type="button" variant="outlined" className={styles.deleteButton} onClick={() => onClick(false)}>
          {t('buttons:Delete review')}
        </Button>
      </div>
    );
  }

  return (
    <span style={{ color: value ? '#1E6AB2' : '#DC9B2D' }}>
      {value ? t('fields:Published') : t('fields:Pending approval')}
    </span>
  );
};

export { ApprovedButton };
