import React, { FC, useEffect } from 'react';
import styles from './styles.module.scss';
import { useConfig } from 'icerockdev-admin-toolkit';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ReloadIcon from '~/components/icons/ReloadIcon';
import cn from 'classnames';
import { ProfileEntity } from '~/pages/profile/components/ProfileEntity';
import { CircularProgress } from '@material-ui/core';
import localizeAmount from '~/utils/localization/amount';

const ConciergePointsBalance: FC = observer(() => {
  const config = useConfig();
  const { t } = useTranslation();

  // TODO: Maybe need optimization
  const profile = config.pages.find(page => page instanceof ProfileEntity) as ProfileEntity;
  const isLoading = profile.isProfileLoading;
  const onUpdate = profile.fetchProfile;
  const wallet = profile.profileData.wallets?.find(item => item.currency === 'CP');

  useEffect(() => {
    onUpdate();
  }, []);

  return (
    <div className={styles.balance}>
      <div className={styles.head__wrap}>
        <div className={styles.name}>{t('Concierge points')}</div>
        <button onClick={onUpdate} className={styles.button}>
          <ReloadIcon className={cn(isLoading && styles.icon__rotate)} />
        </button>
      </div>
      <div className={styles.body__wrap}>
        <div className={styles.amount}>
          {isLoading ? (
            <span>
              <CircularProgress size={24} />
            </span>
          ) : (
            <>
              <span>{localizeAmount(wallet?.amount || 0)}</span>&nbsp;CP
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export { ConciergePointsBalance };
