import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  table: {
    '& td': {
      padding: '10px 4px',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: '500',
    },
    '& th': {
      padding: '10px 4px',
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: '500',
      textTransform: 'uppercase',
      color: '#8F8880',
    },
    '& tr': {
      padding: '0 32px',
      height: '56px',
      '& td:first-child': {
        paddingLeft: '32px',
      },
      '& th:first-child': {
        paddingLeft: '32px',
      },
    },
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  button: {
    boxSizing: 'border-box' as 'border-box',
    borderLeft: `1px solid rgba(0, 0, 0, 0.23)`,
    padding: '10px !important',
    width: 56,

    '& a': {
      color: theme.palette.primary.main,
      minWidth: 'auto',
    },
  },
  button_active: {
    '& a': {
      background: theme.palette.primary.main,
      color: 'white',
      borderRadius: '4px',
      padding: '6px',

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  paper: {
    background: 'blue',
    maxHeight: '100%',
  },
});
