import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';

interface IProps {
  onButtonClick: () => void;
  isEdit?: boolean;
}

const HeadButton: FC<IProps> = observer(({ onButtonClick, isEdit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Button variant="contained" color="primary" className={styles.button} disableElevation onClick={onButtonClick}>
        {t(`buttons:${isEdit ? 'Update' : 'Create'}`)}
      </Button>
    </div>
  );
});

export { HeadButton };
