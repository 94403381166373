import { IEntityField } from 'icerockdev-admin-toolkit';
import { FormattedDate } from '~/components/common/FormattedDate';
import { QrDownloadButton } from './components/QrDownloadButton';
import { QrCodesFilterDateRange } from './components/QrCodesFilterDateRange';

export const QR_CODES_FIELDS: IEntityField[] = [
  {
    type: 'custom',
    name: 'dateStart',
    label: 'Created date',
    filterable: true,
    hideInCreate: true,
    hideInEdit: true,
    hideInList: true,
    hideInView: true,
    component: QrCodesFilterDateRange,
  },
  {
    type: 'custom',
    name: 'createdAt',
    label: 'Created date',
    sortable: true,
    hideInCreate: true,
    hideInEdit: true,
    component: FormattedDate,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Name',
    required: true,
  },
  {
    type: 'number',
    name: 'shopId',
    label: 'Shop Id',
    required: true,
  },
  {
    type: 'number',
    name: 'businessId',
    label: 'Business Id',
    required: true,
    hideInCreate: true,
    hideInList: true,
    hideInEdit: true,
    hideInView: true,
  },
  {
    type: 'custom',
    name: 'download',
    label: ' ',
    hideInCreate: true,
    hideInEdit: true,
    component: QrDownloadButton,
  },
];
