import { JWTAuthProvider } from 'icerockdev-admin-toolkit';
import { authRequestFn, tokenRefreshFn } from './api';
import { AuthLayout } from '~/layouts';

export default new JWTAuthProvider({
  persist: true, // TODO: Need add to authorization form
  authRequestFn: authRequestFn,
  tokenRefreshFn: tokenRefreshFn,
  loginLabel: 'Login (Member ID)',
  layout: AuthLayout,
});
