import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import '@fontsource/noto-sans';
import '@fontsource/noto-sans/500.css';
import '@fontsource/noto-sans/600.css';
import '@fontsource/noto-sans/700.css';

export const DEFAULT_THEME: ThemeOptions = {
  palette: {
    primary: {
      main: '#DC9B2D',
    },
  },
  typography: {
    fontFamily: '"Noto Sans", "sans-serif"',
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 4,
      },
    },
    MuiTableContainer: {
      root: {
        overflowX: 'visible',
      },
    },
    MuiButtonBase: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
      },
      contained: {
        '&:hover': {
          boxShadow: 'none',
        },
        boxShadow: 'none',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#DC9B2D',
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: '#DC9B2D',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        backgroundColor: 'white',
        color: '#2c2c2c',
        boxShadow: '0px 5px 10px 2px rgba(34, 60, 80, 0.2)',
        padding: '1rem',
      },
      arrow: {
        color: 'white',
      },
    },
    MuiOutlinedInput: {
      multiline: {
        display: 'flex',
        alignItems: 'flex-start',
        minHeight: '100px',
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: '0px 5px 10px 2px rgba(34, 60, 80, 0.2)',
      },
    },
  },
};
