import { TRANSACTIONS_FIELDS } from './fields';
import { TransactionsEntity } from './components/TransactionsEntity';
import { UserRole } from '~/utils/constants/roles.constants';
import { API_ROUTES } from '~/utils/constants/api.constants';
import { fetchItemsFn } from '~/utils/api';

export default new TransactionsEntity({
  title: 'Transactions',
  viewable: false,
  editable: false,
  creatable: false,
  exportable: false,
  items: 50,
  api: {
    list: { url: API_ROUTES.transactions, method: 'get' },
  },
  menu: {
    label: 'Transactions',
    url: '/transactions',
    enabled: true,
  },
  fields: TRANSACTIONS_FIELDS,
  fetchItemsFn,
  roles: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business],
  permissions: {
    list: [UserRole.Admin, UserRole.SuperAdmin, UserRole.Business],
  },
});
