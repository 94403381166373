export enum TransactionStatus {
  Transfer = 'TRANSFER',
  Withdraw = 'WITHDRAW',
  Exchange = 'EXCHANGE',
  Deposit = 'DEPOSIT',
}

export const TRANSACTION_STATUS_NAMES = {
  [TransactionStatus.Transfer]: 'Transfer',
  [TransactionStatus.Withdraw]: 'Withdraw',
  [TransactionStatus.Exchange]: 'Exchange',
  [TransactionStatus.Deposit]: 'Deposit',
};

export const TRANSACTION_STATUS_NAME_COLORS = {
  [TransactionStatus.Transfer]: '#1E6AB2',
  [TransactionStatus.Withdraw]: '#B8481F',
  [TransactionStatus.Exchange]: '#2A8EED',
  [TransactionStatus.Deposit]: '#3A9900',
};

export enum TypePoints {
  CP = 'CP',
  SP = 'SP',
}
