import React, { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { CustomInput } from '~/components/common/CustomInput';
import { IShopThumbnail } from '~/utils/types/shops.types';

interface IProps extends IShopThumbnail {
  id: string;
  onChangeValue: (id: string, str: string) => void;
  isViewPage?: boolean;
}

const ThumbnailBlock: FC<IProps> = ({ value, id, children, onChangeValue, limit, isViewPage }) => {
  const { t } = useTranslation();

  const changeValue = str => {
    if (str.length > limit) {
      const editedString = str.slice(0, limit);
      return onChangeValue(id, editedString);
    }
    onChangeValue(id, str);
  };

  return (
    <div className={styles.container}>
      {children}
      <div className={styles.textArea}>
        <CustomInput
          labelSeparate={t('fields:Description')}
          isMultiline={true}
          minTextareaHeight={'60px'}
          rows={2}
          disabled={isViewPage ? true : false}
          value={value}
          onChange={e => changeValue(e.target.value)}
          maxLength={limit}
        />
      </div>
    </div>
  );
};

export { ThumbnailBlock };
