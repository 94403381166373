import React, { FC } from 'react';
import styles from './styles.module.scss';
import { CustomInput } from '~/components/common/CustomInput';
import { observer } from 'mobx-react';
import { Button } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

interface IProps {
  onChangeFieldValue: (value: string) => void;
  value: string;
  placeholder: string;
  onOpenModalButton: () => void;
}

const ShopListInteractionBlock: FC<IProps> = observer(
  ({ onChangeFieldValue, value, placeholder, onOpenModalButton }) => {
    return (
      <div className={styles.container}>
        <div className={styles.textarea}>
          <CustomInput
            style={{ background: 'white', borderRadius: '4px' }}
            placeholder={placeholder}
            value={value}
            onChange={e => onChangeFieldValue(e.target.value)}
          />
        </div>
        <Button onClick={onOpenModalButton}>
          <SettingsIcon style={{ color: '#DC9B2D' }} />
        </Button>
      </div>
    );
  },
);

export { ShopListInteractionBlock };
