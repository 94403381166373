import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import styles from './styles.module.scss';
import localizeAmount from '~/utils/localization/amount';
import { TypePoints } from '~/utils/constants/transactions.constants';

interface IEntityFieldPropsWithTypePoints extends IEntityFieldProps {
  typePoints: TypePoints;
}

type IProps = IEntityFieldPropsWithTypePoints;

const UserWalletField: FC<IProps> = observer(({ label, value, data, handler, isEditing, error, typePoints }) => {
  const cpWallet = data?.wallets?.find(item => item.pointType === TypePoints.CP);
  const cpWalletValue = localizeAmount(cpWallet?.amount || 0);

  const spWallet = data?.wallets?.find(item => item.pointType === TypePoints.SP);
  const spWalletValue = localizeAmount(spWallet?.amount || 0);

  return (
    <div className={styles.wrap}>
      {typePoints === TypePoints.CP ? (
        <>
          <span>{cpWalletValue}</span>&nbsp;<span>{TypePoints.CP}</span>
        </>
      ) : (
        <>
          <span>{spWalletValue}</span>&nbsp;<span>{TypePoints.SP}</span>
        </>
      )}
    </div>
  );
});
export { UserWalletField };
